import { InjectionToken } from '@angular/core';

export const LOGIN_CONFIG = new InjectionToken<ILoginConfig>('zetui.login');
export interface ILoginConfig {
  loginProviders: { id: LoginType; component?: any; [key: string]: any }[];
  background?: { [key: string]: any };
  title?: { text: string; subText: string };
  expiresIn?: string;
  logo?: string;
}

export interface IAppsEnabledEmitted {
  id: string;
  name: string;
  _id: string;
}

export interface IRolesEmitted {
  id: string;
  name: string;
  _id: string;
}

export interface IAuthApiData {
  appsEnabledDetails: IAppsEnabledEmitted[];
  companies: {
    companyDetails: any;
    companyId: string;
    slug: string;
    _id: string;
  }[];
  id: string;
  firstName: string;
  email: string;
  lastName: string;
  name: string;
  roles: IRolesEmitted[];
}

export enum LoginType {
  GOOGLE = 'GOOGLE_LOGIN_PROVIDER',
  FB = 'FB_LOGIN_PROVIDER',
  EMAIL_OTP = 'EMAIL_OTP_PROVIDER'
}
