// eslint-disable-next-line max-classes-per-file
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export interface IFileUploadConfig {
  acceptedFileTypes: string[];
  maxSize: string;
  maxFiles: number;
  registerFileEndpoint: string;
  signedUrlEndpoint: string;
}

export interface IFileUploadGroup {
  actionBtn?: { label: string; multiple: boolean; btnClass?: string | string[] };
  id: string;
  title?: string;
  userBtn?: { label: string; btnClass?: string | string[] };
}

export interface IS3SignedConfig {
  key?: string;
  location?: string;
  name?: string;
  signedUrl?: string;
}

export interface IUploadFile {
  description?: string;
  groupId?: string;
  id?: string;
  name: string;
  fileSize?: number;
  path: string;
  status?: FileUploadStatus;
  fileType?: MimeType | string;
  [key: string]: unknown;
}

export enum UploadFileType {
  SINGLE_UPLOAD,
  MULTIPLE_UPLOAD,
  GROUP_UPLOAD
}

export enum FileUploadStatus {
  INPROGRESS,
  COMPLETED,
  ERROR
}

export enum UploadFileTypeError {
  INVALID_FILE_TYPE,
  FILE_SIZE_EXCEEDED,
  FAILED_TO_GET_S3URL,
  NO_ERROR,
  UPLOAD_FAIL
}

export enum MimeType {
  'application/pdf' = 'pdf',
  'application/vnd.ms-excel' = 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'xlsx',
  'image/png' = 'png',
  'image/jpg' = 'jpg',
  'image/jpeg' = 'jpeg',
  'image/tiff' = 'tif',
  'image/svg+xml' = 'svg',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'docx',
  'application/msword' = 'doc',
  'application/x-rar-compressed, application/octet-stream application/vnd.rar' = 'rar',
  'application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip' = 'zip',
  'application/acad, image/vnd.dwg, image/x-dwg' = 'dwg',
  'application/dxf' = 'dxf',
  'application/pro_eng' = 'prt',
  'application/iges' = 'iges',
  'application/STEP' = 'stp',
  'application/sla' = 'stl'
}

export enum FileType {
  'pdf' = 'application/pdf',
  'xls' = 'application/vnd.ms-excel',
  'xlsx' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'png' = 'image/png',
  'jpg' = 'image/jpg',
  'jpeg' = 'image/jpeg',
  'tif' = 'image/tiff',
  'svg' = 'image/svg+xml',
  'docx' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'doc' = 'application/msword',
  'rar' = 'application/x-rar-compressed, application/octet-stream application/vnd.rar',
  'zip' = 'application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip',
  'dwg' = 'application/acad, image/vnd.dwg, image/x-dwg',
  'dxf' = 'application/dxf',
  'prt' = 'application/pro_eng',
  'iges' = 'application/iges',
  'stp' = 'application/STEP',
  'step' = 'application/STEP',
  'stl' = 'application/sla'
}

const getFileExtension = (fileName: string): string => {
  if (!fileName || fileName === '') {
    return '';
  }
  const fileExtension = fileName.split('.').slice(-1)[0]?.toLowerCase();
  return fileExtension;
};

export class UploadFileError {
  type: UploadFileTypeError;

  message: string;

  constructor(type: UploadFileTypeError, message: string) {
    this.type = type;
    this.message = message;
  }
}
export class UploadFile {
  id: string;

  groupId: string;

  description: string;

  name: string;

  file: File;

  type: MimeType | string;

  status: FileUploadStatus;

  loaded: number;

  total: number;

  s3SignedConfig: IS3SignedConfig;

  fileObj: any;

  src: string;

  cancel: Subject<boolean>;

  webkitRelativePath: string;

  error!: UploadFileError;

  constructor({
    groupId,
    file,
    status,
    name,
    type,
    size,
    src,
    description,
    fileObj
  }: {
    description?: string;
    file?: File;
    groupId: string;
    name?: string;
    size?: number;
    src?: string;
    status?: FileUploadStatus;
    type?: MimeType | string;
    fileObj?: unknown;
  }) {
    this.id = uuidv4();
    this.groupId = groupId || '';
    this.description = description || '';
    this.cancel = new Subject<boolean>();
    this.s3SignedConfig = {};
    this.src = src || '';
    this.file = file || null;
    this.status = status || FileUploadStatus.INPROGRESS;
    this.name = name || file.name;
    this.webkitRelativePath = (file as any)?.webkitRelativePath || '';
    this.type =
      MimeType[type] || MimeType[file?.type] || MimeType[getFileExtension(file?.name)] || getFileExtension(file?.name) || type || '';
    this.total = size || file?.size;
    this.loaded = 0;
    this.fileObj = fileObj;
  }

  setS3SignedConfig(s3SignedConfig: IS3SignedConfig): void {
    this.s3SignedConfig = s3SignedConfig;
  }

  setStatus(status: FileUploadStatus): void {
    this.status = status;
  }

  setLoaded(loaded: number): void {
    this.loaded = loaded;
  }

  setError(error: UploadFileError): void {
    this.error = error;
    this.status = FileUploadStatus.ERROR;
  }

  setFileObj(fileObj: unknown): void {
    this.fileObj = fileObj;
  }
}
