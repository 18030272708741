/* eslint-disable no-param-reassign */
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService) {}

  activeRequests = 0;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.headers.has('Content-Type') && !request.url.endsWith(`/api/v1/file`) && !request.url.includes(`excelUpload`)) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          const errorMessage =
            'Either you are unauthorized to access or Your auth token has been expired, Please try login again. If the problem still presist, contact Platform Team.';
          this.toastr.error(errorMessage);
        } else if (error.status === 0) {
          this.toastr.error('Please remove any URL/ Website links from General scope text box and try again.');
        } else {
          this.toastr.error(`Status: ${error.statusText}(${error.status})\nMessage: ${error.message}`);
        }
        return throwError(error);
      })
    );
  }
}
