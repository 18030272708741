import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { App } from '../../browserEnvConfig';
import { AgendaJob, IServerFile, IUser, JobStatus, JobStatusRefreshType } from '../models/agenda-job';

export interface AgendaJobState {
  app: App;
  taskId: string;
  jobName: string;
  mode: JobStatusRefreshType;
  uploadedFile: IServerFile;
  user: IUser;
  job: Partial<AgendaJob>;
}

export function createInitialState(): Partial<AgendaJobState> {
  return {
    job: {
      status: JobStatus.Started
    }
  };
}

@Injectable()
@StoreConfig({ name: 'agenda.jobs' })
export class AgendaJobStore extends Store<AgendaJobState> {
  constructor() {
    super(createInitialState());
  }
}
