import { Pipe, PipeTransform } from '@angular/core';
import { PaginationService } from '../services/pagination.service';
import { PaginationQuery } from '../states/pagination.query';
import { RowModelType, IPaginationInstance } from '../model/pagination';

const LARGE_NUMBER = Number.MAX_SAFE_INTEGER;

export type Collection<T> = T[];

// TODO change serverSide to paginationMode
export interface PaginatePipeArgs {
  id?: string;
  rowModelType?: RowModelType;
  itemsPerPage?: string | number;
  currentPage?: string | number;
  totalItems?: string | number;
}

@Pipe({
  name: 'zetPaginate',
  pure: false
})
export class PaginatePipe implements PipeTransform {
  constructor(private service: PaginationService, private query: PaginationQuery) {}

  public transform<T, U extends Collection<T>>(collection: U, args: PaginatePipeArgs): U {
    let pageInfo = this.query.pageInfo;

    if (!(collection instanceof Array)) {
      if (pageInfo.slice.length > 0) {
        return pageInfo.slice as U;
      }
      return collection;
    }

    let serverSideMode = args.rowModelType || RowModelType.CLIENT_SIDE;
    let instance = this.createInstance(collection, args);
    let start;
    let end;
    let perPage = instance.itemsPerPage;

    let emitChange = this.service.register(instance);

    if (serverSideMode !== RowModelType.SERVER_SIDE && collection instanceof Array) {
      perPage = +perPage || LARGE_NUMBER;
      start = (instance.currentPage - 1) * perPage;
      end = start + perPage;

      let isIdentical = this.stateIsIdentical(collection, start, end);
      if (isIdentical) {
        return pageInfo.slice as U;
      }
      let slice = collection.slice(start, end);
      this.saveState(collection, slice, start, end);
      this.service.change.emit();
      return slice as U;
    }
    if (emitChange) {
      this.service.change.emit();
    }

    this.saveState(collection, collection, start, end);

    return collection;
  }

  private createInstance<T>(collection: Collection<T>, config: PaginatePipeArgs): IPaginationInstance {
    this.checkConfig(config);

    return {
      itemsPerPage: +config.itemsPerPage || 0,
      currentPage: +config.currentPage || 1,
      totalItems: +config.totalItems || collection.length
    };
  }

  private checkConfig(config: PaginatePipeArgs): void {
    const required = ['itemsPerPage', 'currentPage'];

    const missing = required.filter(prop => !(prop in config));
    if (missing.length > 0) {
      throw new Error(`PaginatePipe: Argument is missing the following required properties: ${missing.join(', ')}`);
    }
  }

  private saveState(collection: any[], slice: any[], start: number, end: number) {
    this.service.setPageInfo({
      collection,
      size: collection.length,
      slice,
      start,
      end
    });
  }

  private stateIsIdentical<T>(collection: Collection<T>, start: number, end: number): boolean {
    let pageInfo = this.query.pageInfo;
    let slice = pageInfo.slice;
    if (slice.length > 0) {
      return false;
    }
    let isMetaDataIdentical = pageInfo.size === collection.length && pageInfo.start === start && pageInfo.end === end;

    if (!isMetaDataIdentical) {
      return false;
    }

    return slice.every((element, index) => element === collection[start + index]);
  }
}
