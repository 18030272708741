/* 
  https://docs.google.com/spreadsheets/d/1z-KW3zH0GWB_UEYvEPDVTJjGDKnwZON2vBHC6eo7LWo/edit#gid=647236811
  
  Items
  HSN/SAC
  HSN/SAC Tax Rates
  Categories
  UOM
  UOM conversion
  Site
  Warehouse
  Currency_Exchange,
  Currency
*/

import { CreateFlowStepState } from '../../../stepper';
import { App } from '../../browserEnvConfig';

export enum MasterUploadType {
  ITEMS = 'ÍTEMS',
  HSN_SAC = 'HSN_SAC',
  TAX_RATE_HSN = 'TAX_RATE_HSN',
  TAX_RATE_SAC = 'TAX_RATE_SAC',
  CATEGORIES = 'CATEGORIES',
  UOM = 'UOM',
  UOM_CONVERSION = 'UOM_CONVERSION',
  SITE = 'SITE',
  WAREHOUSE = 'WAREHOUSE',
  CURRENCY_EXCHANGE = 'CURRENCY_EXCHANGE',
  CURRENCY = 'CURRENCY',
  COUNTRY = 'COUNTRY',
  STATE = 'STATE',
  CITY = 'CITY',
  HSN = 'HSN',
  SAC = 'SAC'
}

export interface IMasterUploadConfig {
  app: App;
  title: string;
  downloadJobName?: string;
  signedUrlEndpoint: string;
  registerFileEndpoint: string;
  isCompanyRequired: boolean;
  selectMultipleCompany?: boolean;
  postEndPoint: string;
  downloadEndpoint?: string;
  sampleTemplate?: string;
}

export const MasterUploadConfig: { [app in MasterUploadType]: IMasterUploadConfig } = {
  [MasterUploadType.ITEMS]: {
    app: App.COMMON_MASTER,
    title: 'Items Master',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: true,
    postEndPoint: 'items/excel-upload',
    selectMultipleCompany: true,
    sampleTemplate: 'https://zet-common-masters.s3.ap-south-1.amazonaws.com/sample-templates/Item+Master_VP_Template_V3+Final.xlsx',
    downloadEndpoint: 'items/excel-download'
  },
  [MasterUploadType.HSN_SAC]: {
    app: App.COMMON_MASTER,
    title: 'Tax Codes Master',
    downloadJobName: 'tax-code-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: false,
    postEndPoint: 'tax-codes/excel-upload',
    downloadEndpoint: 'tax-codes/excel-download',
    selectMultipleCompany: true,
    sampleTemplate: 'https://zet-common-masters.s3.ap-south-1.amazonaws.com/sample-templates/HSN_SAC_Master_1.xlsx'
  },
  [MasterUploadType.CATEGORIES]: {
    app: App.COMMON_MASTER,
    title: 'Categories Master',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: true,
    postEndPoint: 'category/excel-upload',
    selectMultipleCompany: true,
    sampleTemplate: 'https://zet-common-masters.s3.ap-south-1.amazonaws.com/sample-templates/Category+Master_VP_Template_V3+Final.xlsx'
  },
  [MasterUploadType.UOM]: {
    app: App.COMMON_MASTER,
    title: 'UOM Master',
    downloadJobName: 'uom-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: false,
    postEndPoint: 'uom/excel-upload',
    downloadEndpoint: 'uom/excel-download',
    selectMultipleCompany: true,
    sampleTemplate: 'https://zet-common-masters.s3.ap-south-1.amazonaws.com/sample-templates/Unit+of+measure-ZW.xlsx'
  },
  [MasterUploadType.UOM_CONVERSION]: {
    app: App.COMMON_MASTER,
    title: 'UOM Conversion Master',
    downloadJobName: 'uom-conversion-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: false,
    postEndPoint: 'uomConversion/excel-upload',
    downloadEndpoint: 'uomConversion/excel-download',
    selectMultipleCompany: true,
    sampleTemplate: 'https://zet-common-masters.s3.ap-south-1.amazonaws.com/sample-templates/Unit%2BConversion-ZW-1.xlsx'
  },
  [MasterUploadType.SITE]: {
    app: App.ZETAUTH,
    title: 'Site Master',
    downloadJobName: 'site-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: true,
    postEndPoint: 'site/excel-upload',
    selectMultipleCompany: false,
    downloadEndpoint: 'site/excel-download',
    sampleTemplate: 'https://zet-common-masters.s3.ap-south-1.amazonaws.com/sample-templates/Sample+Sites.xlsx'
  },
  [MasterUploadType.WAREHOUSE]: {
    app: App.ZETAUTH,
    title: 'Warehouse Master',
    downloadJobName: 'warehouse-erp-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: true,
    selectMultipleCompany: false,
    postEndPoint: 'warehouse-erp/excel-upload',
    downloadEndpoint: 'warehouse-erp/excel-download',
    sampleTemplate: 'https://zet-common-masters.s3.ap-south-1.amazonaws.com/sample-templates/Warehouse-Template.xlsx'
  },
  [MasterUploadType.CURRENCY_EXCHANGE]: {
    app: App.COMMON_MASTER,
    title: 'Currency Exchange Master',
    downloadJobName: 'currency-exchange-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: false,
    selectMultipleCompany: true,
    postEndPoint: 'currency-exchange/excel-upload',
    downloadEndpoint: 'currency-exchange/excel-download',
    sampleTemplate: 'https://zet-common-masters.s3.ap-south-1.amazonaws.com/sample-templates/currency_exchange_master.xlsx'
  },
  [MasterUploadType.CURRENCY]: {
    app: App.COMMON_MASTER,
    title: 'Currency Master',
    downloadJobName: 'currency-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: false,
    selectMultipleCompany: true,
    postEndPoint: 'currency/excel-upload',
    downloadEndpoint: 'currency/excel-download',
    sampleTemplate: 'https://zet-common-masters.s3.ap-south-1.amazonaws.com/sample-templates/new_currency_master.xlsx'
  },
  [MasterUploadType.COUNTRY]: {
    app: App.COMMON_MASTER,
    title: 'Country Master',
    downloadJobName: 'country-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: false,
    postEndPoint: '',
    downloadEndpoint: 'geo/country/excel-download'
  },
  [MasterUploadType.STATE]: {
    app: App.COMMON_MASTER,
    title: 'State Master',
    downloadJobName: 'state-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: false,
    postEndPoint: '',
    downloadEndpoint: 'geo/state/excel-download'
  },
  [MasterUploadType.CITY]: {
    app: App.COMMON_MASTER,
    title: 'City Master',
    downloadJobName: 'city-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: false,
    postEndPoint: '',
    downloadEndpoint: 'geo/city/excel-download'
  },
  [MasterUploadType.HSN]: {
    app: App.COMMON_MASTER,
    title: 'HSN Master',
    downloadJobName: 'hsn-code-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: false,
    postEndPoint: '',
    downloadEndpoint: 'tax-codes/hsn-excel-download'
  },
  [MasterUploadType.SAC]: {
    app: App.COMMON_MASTER,
    title: 'SAC Master',
    downloadJobName: 'sac-code-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: false,
    postEndPoint: '',
    downloadEndpoint: 'tax-codes/sac-excel-download'
  },
  [MasterUploadType.TAX_RATE_HSN]: {
    app: App.COMMON_MASTER,
    title: 'Tax Rate HSN',
    downloadJobName: 'tax-rate-hsn-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: false,
    postEndPoint: '',
    downloadEndpoint: 'tax-rates/tax-rate-hsn-download'
  },
  [MasterUploadType.TAX_RATE_SAC]: {
    app: App.COMMON_MASTER,
    title: 'Tax Rate SAC',
    downloadJobName: 'tax-rate-sac-excel-download',
    signedUrlEndpoint: 'files/signed-upload-urls',
    registerFileEndpoint: 'files/upload-files',
    isCompanyRequired: false,
    postEndPoint: '',
    downloadEndpoint: 'tax-rates/tax-rate-sac-download'
  }
};
