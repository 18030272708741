import { Injectable } from '@angular/core';
import { CreateFlowStepState, CreateFlowType } from '../model/create-flow';
import { StepperQuery } from '../state/stepper.query';
import { StepperStore, IStepState } from '../state/stepper.store';
import { StepperService } from './stepper.service';

/* TODO refactor this code. Code is not clean and clear */
@Injectable()
export class StepperItemService {
  constructor(private store: StepperStore, private query: StepperQuery, private stepper: StepperService) {}

  changeAccordianState(id: string): void {
    let stepState = this.query.stepState;
    const steps = this.query.steps;

    const currentIndex = steps.findIndex(step => step.id === id);
    const targetIndex = steps.findIndex(step => step.state === CreateFlowStepState.CURRENT);

    if (currentIndex === steps.length - 1) {
      stepState[id].accordionState = true;
    } else {
      stepState[id].accordionState = !stepState[id].accordionState;

      for (let i = 0; i < steps.length; i += 1) {
        if (steps[i].state === CreateFlowStepState.UNVISITED) {
          stepState[steps[i].id].accordionState = false;
        }
      }

      if (targetIndex >= 0) {
        stepState[steps[targetIndex].id].accordionState = true;
      }
    }

    this.setStepItemState(stepState);
  }

  initStepState(): void {
    let stepState: IStepState = {};
    const steps = this.query.steps;
    for (let i = 0; i < steps.length; i += 1) {
      let step = steps[i];
      if (step.state === CreateFlowStepState.CURRENT) {
        stepState[step.id] = { id: step.id, accordionState: true };
      } else {
        stepState[step.id] = { id: step.id, accordionState: false };
      }
    }
    this.setStepItemState(stepState);
  }

  setStepItemState(stepState: IStepState): void {
    this.store.updateStepItemState(stepState);
  }

  toggle(id: string): void {
    if (this.stepper.jumpIsValid(id)) {
      let stepItemState = this.query.stepState;
      const type = this.query.type;
      const jump = this.query.jump;

      // TODO we can improve the logic
      if (type === CreateFlowType.NON_LINEAR) {
        const steps = this.query.steps;
        for (let i = 0; i < steps.length; i += 1) {
          if (steps[i].state === CreateFlowStepState.UNVISITED && !jump) {
            stepItemState[steps[i].id].accordionState = false;
          }
        }
      }

      stepItemState[id].accordionState = !stepItemState[id].accordionState;
      this.setStepItemState(stepItemState);
    }
  }
}
