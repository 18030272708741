<article zetFileDrop (fileDropped)="onFileDrop($event)" class="dragdrop">
  <h1>Drag & drop files here or</h1>
  <section class="upload--select-btns">
    <label for="fileUpload" class="upload--select-btn zbtn-primary">Select file</label>
    <input
      type="file"
      id="fileUpload"
      [multiple]="allowMultiple"
      [formControl]="inputFiles"
      [accept]="fileUploadConfig?.acceptedFileTypes"
      [attr.disabled]="disabled ? '' : null"
      zetFileInput
    />

    <ng-container *ngIf="acceptFolder">
      <label for="folderUpload" class="upload--select-btn zbtn-primary">Select Folder</label>
      <input
        type="file"
        id="folderUpload"
        webkitdirectory
        directory
        [multiple]="allowMultiple"
        [formControl]="inputFiles"
        [accept]="fileUploadConfig?.acceptedFileTypes"
        [attr.disabled]="disabled ? '' : null"
        zetFileInput
      />
    </ng-container>
  </section>
  <p class="dragdrop--select-info">
    Supports {{ fileUploadConfig?.acceptedFileTypes.join(', ') }} file types upto {{ fileUploadConfig?.maxSize }}
  </p>
</article>
