/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, Output, EventEmitter, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'zet-address-dropdown',
  templateUrl: './address-dropdown.component.html',
  styleUrls: ['./address-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressDropdownComponent),
      multi: true
    }
  ]
})
export class AddressDropdownComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() bindLabel: string;

  @Input() bindValue: string;

  @Input() items: any[];

  @Input() placeholder: string;

  @Input() isError = false;

  @Input() typeahead = false;

  @Input() multiple = false;

  @Input() clearable = false;

  @Input() checkbox = false;

  @Input() closeOnSelect = true;

  @Input() disabled = false;

  @Input() appendTo = '';

  @Input() selectAll = false;

  @Input() itemToDisable: any[];

  @Input() addTag = false;

  @Output() changeData: EventEmitter<any> = new EventEmitter();

  @Output() scrollToEnd: EventEmitter<any> = new EventEmitter();

  @Output() search: EventEmitter<any> = new EventEmitter();

  public search$: Subject<string> = new Subject();

  formControl: FormControl = new FormControl('');

  onChange = (value: any) => {};

  onTouched = () => {};

  ngOnInit(): void {
    if (this.itemToDisable) {
      this.itemToDisable.forEach(item => {
        const index = this.items.indexOf(item);
        this.items.splice(index, 1);
      });
    }

    this.formControl.valueChanges.subscribe(value => {
      this.onChange(value);
    });

    this.search$.pipe(distinctUntilChanged(), debounceTime(600)).subscribe(value => {
      this.search.emit({ searchText: value });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.disabled?.previousValue !== changes?.disabled?.currentValue) {
      if (changes?.disabled?.currentValue) {
        this.formControl.disable();
      } else if (changes?.disabled?.currentValue !== null) {
        this.formControl.enable();
        this.formControl.markAsUntouched();
      }
    }
  }

  close(): void {
    this.onTouched();
  }

  writeValue(value: any): void {
    this.formControl.patchValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (data: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onSelectAll(): void {
    const checkbox = document.getElementsByName('selectAll-checkbox') as unknown as HTMLInputElement;
    if (this.formControl.value && this.formControl.value.length) {
      this.formControl.patchValue([]);
      checkbox[0].checked = false;
    } else {
      let values = this.items;
      if (this.bindValue) {
        values = this.items.map(item => item[this.bindValue]);
      }
      this.formControl.patchValue(values);
      checkbox[0].checked = true;
    }
  }
}
