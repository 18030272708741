import { TemplateRef } from '@angular/core';

export interface ICreateFlowStep {
  index?: number;
  id: string;
  string?: string;
  header?: string;
  body?: string | TemplateRef<unknown>;
  state: CreateFlowStepState | { (...args): CreateFlowStepState };
}

export enum CreateFlowStepState {
  UNVISITED,
  LOADING,
  CURRENT,
  VISITED,
  DISABLED
}

export enum CreateFlowType {
  LINEAR,
  NON_LINEAR
}

export interface CreateFlowConfig {
  orientation: 'horizontal' | 'vertical';
  theme?: 'default' | 'dark';
  mode: 'stepper' | 'wizard';
  type: CreateFlowType;
  getState?: (...args) => CreateFlowStepState;
}

export abstract class CreateFlow {
  steps: ICreateFlowStep[];

  protected initialSteps: ICreateFlowStep[];

  get currentStep(): ICreateFlowStep {
    return this.steps.find(step => step.state === CreateFlowStepState.CURRENT);
  }

  constructor(steps: ICreateFlowStep[]) {
    this.initialSteps = JSON.parse(JSON.stringify(steps));
    this.steps = steps;
  }

  abstract next(id: string): void;

  abstract jumpIsValid(id: string): boolean;

  prev(): void {
    const currentIndex = this.steps.findIndex(({ id }) => id === this.currentStep.id);
    if (currentIndex === 0) {
      this.currentStep.state = CreateFlowStepState.VISITED;
      return;
    }

    this.currentStep.state = CreateFlowStepState.VISITED;
    this.steps[currentIndex - 1].state = CreateFlowStepState.CURRENT;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goto(id: string): void {
    throw new Error('Method not implemented.');
  }

  reset(): void {
    this.steps = JSON.parse(JSON.stringify(this.initialSteps));
  }
}
