/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ContentChildren,
  QueryList,
  TemplateRef,
  ContentChild,
  SimpleChanges
} from '@angular/core';
import { Observable } from 'rxjs';
import { FileUploadStatus, IFileUploadGroup, IUploadFile, UploadFile, UploadFileType } from '../../model/file-upload';
import { FileUploadService } from '../../services/file-upload.service';
import { FileValidatorService } from '../../services/file-validator.service';
import { FileUploadQuery } from '../../states/file-upload.query';
import { FileUploadStore } from '../../states/file-upload.store';
import { convertFileToUploadFile } from '../../utils/file-upload.util';
import { FileUploadGroupComponent } from '../file-upload-group/file-upload-group.component';

@Component({
  selector: 'zet-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [FileUploadStore, FileUploadQuery, FileUploadService, FileValidatorService]
})
export class FileUploadComponent implements OnChanges {
  @Input() acceptedFileTypes: string[];

  @Input() maxSize: string;

  @Input() maxFiles: string;

  @Input() type: UploadFileType;

  @Input() groups: IFileUploadGroup[] = [{ id: '' }];

  @Input() context: any;

  @Input() customClass = '';

  @Input() disabled = false;

  @Input() showDragnDrop = true;

  @Input() reportIn: string;

  @Input() acceptFolder = false;

  @Input() files: IUploadFile[] = [];

  @Output() done = new EventEmitter();

  @Output() view = new EventEmitter();

  @ContentChildren(FileUploadGroupComponent) groupComponents: QueryList<FileUploadGroupComponent>;

  @ContentChild('attachmentCard') attachmentCard: TemplateRef<unknown>;

  files$: Observable<(UploadFile | IUploadFile)[]>;

  constructor(private fileUpload: FileUploadService, private query: FileUploadQuery) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.files$ = this.query.files$;

    if (this.context) {
      this.fileUpload.updateStore({ key: 'context', value: this.context });
    }

    if (changes?.files?.currentValue !== changes?.files?.previousValue) {
      let files = this.files.map(uploadFile => {
        let file = new UploadFile({
          groupId: this.groups[0].id,
          name: uploadFile?.name,
          size: uploadFile?.fileSize,
          src: uploadFile?.path,
          type: uploadFile?.fileType,
          fileObj: uploadFile,
          status: FileUploadStatus.COMPLETED
        });
        return file;
      });
      this.fileUpload.init(files);
    }
  }

  fileDropped({ fileList, groupId = '' }: { fileList: FileList; groupId: '' }): void {
    const uploadFiles = convertFileToUploadFile({ fileList, groupId });
    this.fileUpload.addFiles(uploadFiles);
    this.uploadFiles(uploadFiles);
  }

  getGroupComponent(index: number): FileUploadGroupComponent {
    return this.groupComponents?.toArray()[index];
  }

  uploadFiles(uploadFiles: UploadFile[]): void {
    this.fileUpload.uploadFiles(uploadFiles).subscribe(files => {
      this.emitFiles({ groupId: files[0]?.groupId });
      // files.reduce((acc, file) => {
      //   if (!acc[file.groupId]) {
      //     acc[file.groupId] = [file];
      //   } else {
      //     acc[file.groupId].push(file);
      //   }
      //   return acc;
      // }, {});
    });

    // return files.map((file) => file.fileObj);
  }

  retry(file: UploadFile): void {
    this.fileUpload.retry(file);
  }

  cancel(file: UploadFile): void {
    file.cancel.next();
    this.fileUpload.delete(file);
  }

  onView(file: UploadFile): void {
    this.view.emit(file);
  }

  delete(file: UploadFile): void {
    this.fileUpload.delete(file);
    this.emitFiles({ groupId: file?.groupId });
  }

  emitFiles({ groupId }: { groupId: string }): void {
    const files = this.query.files;
    const allFilesObj = files.map(file => file.fileObj || file);
    if (this.type === UploadFileType.GROUP_UPLOAD && groupId) {
      const groupIndex = this.groups.findIndex(grp => grp.id === groupId);
      const groupFiles = files.filter(file => file.groupId === groupId);
      const groupFilesObj = groupFiles.map(file => file.fileObj || file);
      this.getGroupComponent(groupIndex)?.done.emit(groupFilesObj);
    }
    this.done.emit(allFilesObj);
  }
}
