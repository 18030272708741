import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { ShowCaseComponent } from './pages/showcase/showcase.component';
import { SharedModule } from './@shared/shared.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ContributionsComponent } from './pages/contributions/contributions.component';

import { AngularFireModule } from '@angular/fire';

import { AngularFirestoreModule } from '@angular/fire/firestore';

import { environment } from '../environments/environment';
import { IconLibraryComponent } from './pages/icon-library/icon-library.component';
import { IZetAppCoreModule, ZetAppCoreModule } from 'projects/zetui/src/zet-app-core.module';
import { IHttpConfig, ILoginConfig, LoginType } from 'projects/zetui/src/public-api';

export const loginConfig: ILoginConfig = {
  loginProviders: [{ id: LoginType.GOOGLE, clientId: environment.googleClientId }]
};

const httpConfig: IHttpConfig = {
  baseUrl: environment.baseEndPoint
};

export const zetAppModuleConfig: IZetAppCoreModule = {
  appName: 'zetui-demo',
  featuresEnabled: {
    login: true,
    loader: false,
    auth: true,
    deployment: true,
    http: false
  },
  login: {
    config: loginConfig
  },
  http: {
    config: httpConfig,
    errorInterceptor: true
  },
  loader: {
    config: { component: null },
    loaderInterceptor: true
  },
  auth: {
    config: { authUrl: environment.authApiEndPoint },
    authInterceptor: true
  }
};

@NgModule({
  declarations: [AppComponent, DashboardComponent, ShowCaseComponent, ContributionsComponent, IconLibraryComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFirestoreModule,
    ZetAppCoreModule.forRoot(zetAppModuleConfig)
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
