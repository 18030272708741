<section class="form-container" *ngIf="fieldToShowConfig">
  <form class="widget-form" #financialDimensionForm="ngForm" (ngSubmit)="save(financialDimensionForm)">
    <div class="widget-form--group" *ngIf="fieldToShowConfig?.segment">
      <label class="widget-form--label" for="segment">Segment<span class="required"> * </span></label>
      <zet-address-dropdown
        id="segment"
        #segment="ngModel"
        required
        [items]="segments"
        name="segmentId"
        placeholder="Select Segment"
        [bindLabel]="'name'"
        [bindValue]="item"
        [disabled]="fieldToDisable?.segment"
        [isError]="segment?.invalid && (segment?.dirty || segment?.touched)"
        [(ngModel)]="financialDimension.segment"
        (changeData)="onDataChange($event, 'SEGMENT')"
      ></zet-address-dropdown>
      <zet-address-validation-message
        *ngIf="segment?.invalid && (segment?.dirty || segment?.touched)"
        [errorMessage]="validationMsg.segment"
        [control]="segment"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group" *ngIf="fieldToShowConfig?.businessUnit && businessUnits?.length">
      <label class="widget-form--label" for="business-unit">Business Unit<span class="required"> * </span></label>
      <zet-address-dropdown
        id="businessUnit"
        name="business-unit"
        placeholder="Select BU"
        [required]="businessUnits.length > 0"
        #businessUnit="ngModel"
        [items]="businessUnits"
        [bindLabel]="'name'"
        [bindValue]="item"
        [isError]="businessUnit?.invalid && (businessUnit?.dirty || businessUnit?.touched)"
        [(ngModel)]="financialDimension.businessUnit"
        [disabled]="fieldToDisable?.businessUnit"
        (changeData)="onDataChange($event, 'BUSINESS_UNIT')"
      ></zet-address-dropdown>
      <zet-address-validation-message
        *ngIf="businessUnit?.invalid && (businessUnit?.dirty || businessUnit?.touched)"
        [errorMessage]="validationMsg.businessUnit"
        [control]="businessUnit"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group" *ngIf="fieldToShowConfig?.subBusinessUnit && subBusinessUnits?.length">
      <label class="widget-form--label" for="sub-business-unit">Sub Business Unit<span class="required"> * </span></label>
      <zet-address-dropdown
        id="subBusinessUnit"
        [required]="subBusinessUnits.length > 0"
        #subBusinessUnit="ngModel"
        [items]="subBusinessUnits"
        [bindLabel]="'name'"
        name="sub-business-unit"
        placeholder="Select Sub BU"
        [bindValue]="item"
        [isError]="subBusinessUnit?.invalid && (subBusinessUnit?.dirty || subBusinessUnit?.touched)"
        [(ngModel)]="financialDimension.subBusinessUnit"
        [disabled]="fieldToDisable?.subBusinessUnit"
        (changeData)="onDataChange($event, 'SUB_BUSINESS_UNIT')"
      ></zet-address-dropdown>
      <zet-address-validation-message
        *ngIf="subBusinessUnit?.invalid && (subBusinessUnit?.dirty || subBusinessUnit?.touched)"
        [errorMessage]="validationMsg.subBusinessUnit"
        [control]="subBusinessUnit"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group" *ngIf="fieldToShowConfig?.factory && factories?.length">
      <label class="widget-form--label" for="factory">Factory</label>
      <zet-address-dropdown
        id="factories"
        [items]="factories"
        name="factory"
        placeholder="Select Factory"
        [bindLabel]="'name'"
        [bindValue]="item"
        [(ngModel)]="financialDimension.factory"
        [disabled]="fieldToDisable?.factory"
        [clearable]="true"
      ></zet-address-dropdown>
    </div>

    <div class="widget-form--group" *ngIf="fieldToShowConfig?.region && regions?.length">
      <label class="widget-form--label" for="region">Region</label>
      <zet-address-dropdown
        id="regions"
        name="region"
        placeholder="Select Region"
        [items]="regions"
        [bindLabel]="'name'"
        [bindValue]="item"
        [(ngModel)]="financialDimension.region"
        [disabled]="fieldToDisable?.region"
        [clearable]="true"
      ></zet-address-dropdown>
    </div>

    <div class="form-container--buttons">
      <button type="button" class="zbtn-secondary" (click)="onCancel()">Cancel</button>
      <button type="submit" class="zbtn-primary">Save</button>
    </div>
  </form>
</section>
