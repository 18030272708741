<section class="file-viewer">
  <ng-container [ngSwitch]="getFileType(activeFile$ | async)">
    <ng-container *ngSwitchCase="'pdf'">
      <zet-pdf-viewer
        [pdfOptions]="pdfOptions"
        [name]="(activeFile$ | async).name"
        [src]="(activeFile$ | async).src"
        (loaded)="loaded.emit($event)"
        data-testid="pdf-viewer"
      ></zet-pdf-viewer>
    </ng-container>
    <ng-container *ngSwitchCase="'image'">
      <zet-images-viewer
        [imgOption]="imgOption"
        [name]="(activeFile$ | async).name"
        [src]="(activeFile$ | async).src"
        (loaded)="loaded.emit($event)"
        data-testid="image-viewer"
      ></zet-images-viewer>
    </ng-container>
    <ng-container *ngSwitchCase="'3d'">
      <zet-threed-viewer
        [name]="(activeFile$ | async).name"
        [src]="(activeFile$ | async).src"
        [type]="(activeFile$ | async)?.name?.split('.')?.slice(-1)[0]?.toLowerCase()"
        (loaded)="loaded.emit($event)"
        data-testid="3d-viewer"
      ></zet-threed-viewer>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <section class="file-viewer--nopreview" data-testid="no-viewer">
        <div>
          <img src="/assets/zetui/svg/files/default.svg" />
          <p>File preview not available</p>
          <button type="button" (click)="download()">
            <span class="material-icons">download</span>
            Download
          </button>
        </div>
      </section>
    </ng-container>
  </ng-container>
  <zet-file-viewer-control (loaded)="loaded.emit($event)" (deleted)="deleted.emit($event)">
    <ng-content select="fileControlContent" ngProjectAs="fileControlContent"></ng-content>
  </zet-file-viewer-control>
</section>
