import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { IAddressWidgetData, IAddressWidgetEventData } from './address';
import { IFinancialDimensionWidgetData } from './financial-dimension/components/financial-dimension/model';
import { ItemMasterContext, ItemMasterEventData } from './item-master';
import { IWarehouseWidgetData, IWarehouseEventData } from './warehouse/components/warehouse-widget/model';
import { UploadMasterContext } from './upload-master/models/context';
import { UploadMasterEventData } from './upload-master/models/event';
import { IBankAccountMaster, IBankMasterContext } from './bank-master/models/bank-master';

export enum WidgetType {
  FINANCIAL_DIMENSION = 'FINANCIAL_DIMENSION',
  ADDRESS = 'ADDRESS',
  ITEM_MASTER = 'ITEM_MASTER',
  WAREHOUSE = 'WAREHOUSE',
  UPLOAD_MASTER = 'UPLOAD_MASTER',
  BANK_ACCOUNT_MASTER = 'BANK_ACCOUNT_MASTER'
}

export enum WidgetMode {
  MODAL = 'MODAL',
  FORM = 'FORM'
}

export enum WidgetEventType {
  CLICK = 'CLICK',
  CANCEL = 'CANCEL',
  DONE = 'DONE',
  CHANGE = 'CHANGE'
}

export type WidgetData =
  | ItemMasterContext
  | UploadMasterContext
  | IAddressWidgetData
  | IFinancialDimensionWidgetData
  | IWarehouseWidgetData
  | IBankMasterContext;

export interface WidgetModalOptions extends Omit<ModalOptions, 'initialState'> {
  initialState: WidgetData;
}

export type WidgetEventData = ItemMasterEventData &
  IAddressWidgetEventData &
  UploadMasterEventData &
  IWarehouseEventData &
  IBankAccountMaster;

export type WidgetEvent = {
  type: WidgetEventType;
  data: WidgetEventData;
  modalRef?: BsModalRef;
};
