import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ISpyLink } from '../model/spy-scroll';

export interface SpyScrollState {
  links: ISpyLink[];
  scrollContainer: Element | HTMLElement;
}

export function createInitialState(): SpyScrollState {
  return {
    links: [],
    scrollContainer: null
  };
}

@Injectable()
@StoreConfig({ name: 'zetui.spy-scroll' })
export class SpyScrollStore extends Store<SpyScrollState> {
  constructor() {
    super(createInitialState());
  }
}
