<nav class="sidemenu" [ngClass]="(isOpen$ | async) !== true ? 'sidemenu-is-close' : 'sidemenu-is-open'">
  <article class="sidemenu--header">
    <ng-container [ngTemplateOutlet]="headerTemplate || defaultHeaderTemplate" [ngTemplateOutletContext]="{ close: _close }"></ng-container>
  </article>
  <article class="sidemenu--info">
    <!-- TODO replace this with icon from assets -->
    <button *ngIf="(isOpen$ | async) != true && trigger != 'hover'" (click)="open()" class="btn-hamburger">
      <span class="material-icons">menu</span>
    </button>
    <ng-container #headerInfoTemplate></ng-container>
  </article>
  <article class="sidemenu--nav">
    <ul>
      <li
        *ngFor="let item of items$ | async; let index = index"
        class="sidemenu--item"
        [class.item-is-selected]="item.selected"
        (click)="onMenuClick(index)"
      >
        <zet-sidemenu-item [isOpen]="isOpen$ | async" [item]="item"></zet-sidemenu-item>
      </li>
    </ul>
  </article>
  <article class="sidemenu--info">
    <ng-container #footerInfoTemplate></ng-container>
  </article>
  <article class="sidemenu--footer">
    <ng-container
      [ngTemplateOutlet]="footerTemplate || defaultFooterTemplate"
      [ngTemplateOutletContext]="{ logout: _logout}"
    ></ng-container>
  </article>
</nav>

<ng-template #defaultHeaderTemplate let-close="close">
  <div class="brand-zetwerk"></div>
  <button type="button" class="sidemenu--close" (click)="close()">x</button>
</ng-template>
<ng-template #defaultFooterTemplate let-logout="logout">logout</ng-template>
