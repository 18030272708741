<div *ngIf="mode == 'MODAL'" class="modal-header">
  <h4 class="modal-title">{{ header }}</h4>
  <button type="button" class="zbtn-close" aria-label="Close" (click)="cancel.next()">
    <span class="material-icons">close</span>
  </button>
</div>

<section class="form-container modal-body">
  <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  <form class="widget-form g-22" [class]="customClass" [formGroup]="bankMasterForm">
    <div class="widget-form--group span-full">
      <label class="widget-form--label" for="contactName">Payee Name<span class="required"> * </span></label>
      <input
        class="widget-form--control widget-form--input"
        [class.widget-form--control-error]="contactNameControl.invalid && (contactNameControl.dirty || contactNameControl.touched)"
        id="contactName"
        type="text"
        placeholder="Payee Name"
        formControlName="contactName"
        [readonly]="fieldToDisable?.contactName"
      />
      <div class="input-group-append">
        <span *ngIf="isIFSCCodeLoading" class="loader"></span>
      </div>
      <zet-address-validation-message
        *ngIf="contactNameControl.invalid && (contactNameControl.dirty || contactNameControl.touched)"
        [errorMessage]="validationMessage?.contactName"
        [control]="contactNameControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group span-full">
      <label class="widget-form--label" for="accountNo">Bank Account Number<span class="required"> * </span></label>
      <input
        class="widget-form--control widget-form--input"
        [class.widget-form--control-error]="accountNoControl.invalid && (accountNoControl.dirty || accountNoControl.touched)"
        id="accountNo"
        type="password"
        placeholder="Account Number"
        formControlName="accountNo"
        [readonly]="fieldToDisable?.accountNo"
      />
      <div class="input-group-append">
        <span
          *ngIf="accountNoControl.invalid && (accountNoControl.dirty || accountNoControl.touched)"
          class="input-group-error material-icons"
          >error_outline</span
        >

        <span
          *ngIf="reAccountNoControl.touched && !reAccountNoControl?.errors && !accountNoControl?.errors"
          class="input-group-success material-icons"
          >check_circle</span
        >
      </div>
      <zet-address-validation-message
        *ngIf="accountNoControl.invalid && (accountNoControl.dirty || accountNoControl.touched)"
        [errorMessage]="validationMessage.accountNo"
        [control]="accountNoControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group span-full">
      <label class="widget-form--label" for="reAccountNo">Re-enter Bank Account Number<span class="required"> * </span></label>
      <input
        id="reAccountNo"
        class="widget-form--control widget-form--input"
        [class.widget-form--control-error]="reAccountNoControl.invalid && (reAccountNoControl.dirty || reAccountNoControl.touched)"
        type="text"
        placeholder="Account Number"
        formControlName="reAccountNo"
        [readonly]="fieldToDisable?.reAccountNo"
      />
      <div class="input-group-append">
        <span
          *ngIf="reAccountNoControl.invalid && (reAccountNoControl.dirty || reAccountNoControl.touched)"
          class="input-group-error material-icons"
          >error_outline</span
        >

        <span
          *ngIf="accountNoControl.valid && reAccountNoControl.touched && !reAccountNoControl?.errors"
          class="input-group-success material-icons"
          >check_circle</span
        >
      </div>
      <div *ngIf="accountNoControl.valid && reAccountNoControl.touched && !reAccountNoControl?.errors" class="widget-form--input-success">
        <span class="material-icons">download_done</span>
        <span class="widget-form--input-loading-text">Account Number Match Successfully</span>
      </div>
      <zet-address-validation-message
        *ngIf="reAccountNoControl.invalid && (reAccountNoControl.dirty || reAccountNoControl.touched)"
        [errorMessage]="validationMessage.reAccountNo"
        [control]="reAccountNoControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group">
      <label class="widget-form--label" for="ifscCode">IFSC<span *ngIf="accountScope === 'DOMESTIC'" class="required"> * </span></label>
      <input
        id="ifscCode"
        class="widget-form--control widget-form--input"
        [class.widget-form--control-error]="ifscCodeControl.invalid && (ifscCodeControl.dirty || ifscCodeControl.touched)"
        type="text"
        placeholder="IFSC"
        [readonly]="accountScope === 'INTERNATIONAL' || fieldToDisable?.ifscCode"
        formControlName="ifscCode"
      />
      <div *ngIf="isIFSCCodeLoading" class="widget-form--input-loading">
        <span class="loader"></span>
        <span class="widget-form--input-loading-text">Processing</span>
      </div>
      <zet-address-validation-message
        *ngIf="ifscCodeControl.invalid && (ifscCodeControl.dirty || ifscCodeControl.touched)"
        [errorMessage]="validationMessage.ifscCode"
        [control]="ifscCodeControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group">
      <label class="widget-form--label" for="bankName">Bank Name<span class="required"> * </span></label>
      <input
        id="bankName"
        class="widget-form--control widget-form--input"
        [class.widget-form--control-error]="bankNameControl.invalid && (bankNameControl.dirty || bankNameControl.touched)"
        type="text"
        placeholder="Bank Name"
        [readonly]="accountScope === 'DOMESTIC' || fieldToDisable?.bankName"
        formControlName="bankName"
      />
      <div class="input-group-append">
        <span *ngIf="isIFSCCodeLoading" class="loader"></span>
      </div>
      <zet-address-validation-message
        *ngIf="bankNameControl.invalid && (bankNameControl.dirty || bankNameControl.touched)"
        [errorMessage]="validationMessage.bankName"
        [control]="bankNameControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group">
      <label class="widget-form--label" for="swiftCode"
        >Swift Code<span *ngIf="accountScope === 'INTERNATIONAL'" class="required"> * </span></label
      >
      <input
        class="widget-form--control widget-form--input"
        [class.widget-form--control-error]="swiftCodeControl.invalid && (swiftCodeControl.dirty || swiftCodeControl.touched)"
        id="swiftCode"
        type="text"
        placeholder="Swift Code"
        [readonly]="accountScope === 'DOMESTIC' || fieldToDisable?.swiftCode"
        formControlName="swiftCode"
      />
      <zet-address-validation-message
        *ngIf="swiftCodeControl.invalid && (swiftCodeControl.dirty || swiftCodeControl.touched)"
        [errorMessage]="validationMessage.swiftCode"
        [control]="swiftCodeControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group">
      <label class="widget-form--label" for="currencyCode">Current Currency Code<span class="required"> * </span></label>
      <zet-address-dropdown
        id="currencyCode"
        placeholder="Select Currency"
        [items]="currencyCodes"
        [isError]="currencyCodeControl.invalid && (currencyCodeControl.dirty || currencyCodeControl.touched)"
        [bindLabel]="'currencyCode'"
        [bindValue]="'currencyCode'"
        [disabled]="formMode === 'view' || fieldToDisable?.currencyCode"
        [bindValue]=""
        formControlName="currencyCode"
      ></zet-address-dropdown>
      <zet-address-validation-message
        *ngIf="currencyCodeControl.invalid && (currencyCodeControl.dirty || currencyCodeControl.touched)"
        [errorMessage]="validationMessage.currencyCode"
        [control]="currencyCodeControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group">
      <label class="widget-form--label" for="accountType">Account Type<span class="required"> * </span></label>
      <zet-address-dropdown
        [items]="accountType"
        [clearable]="false"
        placeholder="Select Account Type"
        formControlName="accountType"
        id="accountType"
        [isError]="accountTypeControl.invalid && (accountTypeControl.dirty || accountTypeControl.touched)"
        [disabled]="formMode === 'view' || fieldToDisable?.accountType"
      ></zet-address-dropdown>
      <zet-address-validation-message
        *ngIf="accountTypeControl.invalid && (accountTypeControl.dirty || accountTypeControl.touched)"
        [errorMessage]="validationMessage.accountType"
        [control]="accountTypeControl"
      ></zet-address-validation-message>
    </div>

    <div *ngIf="mode == 'MODAL'" class="modal-footer span-full">
      <div class="form-container--buttons">
        <button type="button" class="zbtn-secondary" (click)="cancel.next()">Cancel</button>
        <button type="button" class="zbtn-primary" (click)="save()">Save</button>
      </div>
    </div>
  </form>
</section>
