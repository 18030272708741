import { TemplateRef } from '@angular/core';

export enum AccountScope {
  DOMESTIC = 'DOMESTIC',
  INTERNATIONAL = 'INTERNATIONAL'
}

export interface IBankAccountMaster {
  bankAccountId: string;
  contactName?: string;
  accountNo?: string;
  reAccountNo?: string;
  ifscCode?: string;
  bankName?: string;
  swiftCode?: string;
  currencyCode?: { currencyCode: string; name: string };
  accountVerified?: boolean;
  accountScope?: AccountScope;
  accountType?: string;
}

export interface IBankMasterContext {
  bank: IBankAccountMaster;
  mode?: 'MODAL' | 'FORM';
  headerTemplate: TemplateRef<any>;
  accountScope: AccountScope;
  context: any;
  header: string;
  customClass: string;
}
export interface IBankAccountFieldToDisable {
  contactName?: boolean;
  accountNo?: boolean;
  reAccountNo?: boolean;
  ifscCode?: boolean;
  bankName?: boolean;
  currencyCode?: boolean;
  swiftCode?: boolean;
  accountType?: boolean;
}

export const validationMessage = {
  contactName: {
    required: 'Payee Name is required'
  },
  accountNo: {
    required: 'Account number is required',
    accountNoNotExist: 'Account number does not exist'
  },
  reAccountNo: {
    required: 'Account number is required',
    accountNotMatch: 'Account number mismatch'
  },
  ifscCode: {
    required: 'IFSC code is required',
    pattern: 'IFSC code is invalid',
    ifscCodeNotExist: 'IFSC code does not exist'
  },
  bankName: {
    required: 'Bank name is required'
  },
  swiftCode: {
    required: 'Swift code number is required',
    pattern: 'Swift code is invalid'
  },
  currencyCode: {
    required: 'Currency code is required'
  },
  accountType: {
    required: 'Account type is required'
  }
};

export enum FormMode {
  EDIT = 'edit',
  VIEW = 'view',
  ADD = 'add'
}
