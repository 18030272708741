import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MimeType } from '../../file-upload/model/file-upload';
import { IFileViewerFile } from '../model/file-viewer';
import { FileViewerState, FileViewerStore } from './file-viewer.store';

@Injectable({ providedIn: 'root' })
export class FileViewerQuery extends Query<FileViewerState> {
  zoom$ = this.select('zoom');

  files$ = this.select('files');

  activeFile$ = this.select(state => state.files.find(file => file.selected));

  page$ = this.select('page');

  src$ = this.select('src');

  type$ = this.select('type');

  name$ = this.select('name');

  totalPage$ = this.select('totalPage');

  constructor(protected store: FileViewerStore) {
    super(store);
  }

  get files(): IFileViewerFile[] {
    return this.getValue().files;
  }

  get totalPage(): number {
    return this.getValue().totalPage;
  }

  get name(): string {
    return this.getValue().name;
  }

  get src(): string {
    return this.getValue().src;
  }

  get type(): MimeType {
    return this.getValue().type;
  }

  get page(): number {
    return this.getValue().page;
  }

  get zoom(): number {
    return this.getValue().zoom;
  }

  get activeFile(): IFileViewerFile {
    return this.getValue().files?.find(file => file.selected);
  }
}
