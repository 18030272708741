import { Injectable } from '@angular/core';
import { TimelineStore } from './state/timeline.store';
import { ITimelineStep } from './model/timeline';

@Injectable({ providedIn: 'root' })
export class TimelineService {
  constructor(private timelineStore: TimelineStore) {}

  updateTimelineSteps(timelineSteps: ITimelineStep[]): void {
    this.timelineStore.update({ timelineSteps });
  }
}
