import { Component, Input, OnInit, Inject, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { IWarehouseEventData, IWarehouseWidgetData, validationMessages, IWarehouseWidgetFilter } from './model';
import { Widget } from '../../../widget';
import { browserEnvProviders, BrowserEnvToken, ServerConfig } from '../../../browserEnvConfig';
import { RestClientService } from '../../../../../features/http/rest-client.service';

interface Response {
  success: boolean;
  data: any;
}

@Component({
  selector: 'zet-warehouse-widget',
  templateUrl: './warehouse-widget.component.html',
  styleUrls: ['./warehouse-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...browserEnvProviders]
})
export class WarehouseWidgetComponent extends Widget<Partial<WarehouseWidgetComponent>, IWarehouseEventData> implements OnInit {
  @Input() companyId: string;

  @Input() warehouse: IWarehouseEventData;

  @Input() isSiteDisabled: boolean;

  @Input() isWarehouseDisabled: boolean;

  @Input() filters: IWarehouseWidgetFilter;

  sites = [];

  warehouses = [];

  selectedSite: any;

  selectedWarehouse: any;

  validationMessages = validationMessages;

  warehouseForm: FormGroup;

  constructor(
    @Inject(BrowserEnvToken) public apis: ServerConfig[],
    public bsModalRef: BsModalRef,
    private http: HttpClient,
    private fb: FormBuilder,
    private _restClientService: RestClientService
  ) {
    super();
    this.warehouseForm = this.initWarehouseForm();
  }

  initWarehouseForm(): FormGroup {
    return this.fb.group({
      site: ['', Validators.compose([Validators.required])],
      warehouse: ['', Validators.compose([Validators.required])]
    });
  }

  ngOnInit(): void {
    this.getSites();

    const site = this.warehouse?.site || null;
    if (site) {
      this.getWarehouses(site);

      this.patchWarehouse();
    }
  }

  patchWarehouse(): void {
    this.selectedSite = this.warehouse?.site || '';

    this.selectedWarehouse = this.warehouse?.warehouse || '';
  }

  getSites(): void {
    if (this.companyId) {
      const urlParams = {
        companyId: this.companyId,
        siteName: this.filters?.siteName,
        legalEntityCode: this.filters?.legalEntityCode
      };

      this._restClientService.get(`${this.apis[1].baseUrl}site`, { params: urlParams }).subscribe((response: Response) => {
        if (response.success) {
          this.sites = response.data?.allDocuments?.map((result: any) => {
            return {
              // eslint-disable-next-line no-underscore-dangle
              id: result?._id,
              siteId: result?.siteId,
              name: result?.siteName,
              uniqueCode: result?.uniqueCode
            };
          });
        }
      });
    }
  }

  // FIXME
  getAddress(value): string {
    const address = value?.address;
    let addressString = '';
    if (address) {
      addressString = `${address?.name || ''}, ${address?.street || ''}, ${address?.city?.name || ''}, ${address?.state?.name || ''}, ${
        address?.country?.name
      }, ${address?.pincode || ''}`;
    }
    return addressString;
  }

  // FIXME
  getAddressHTMLString(address): string {
    let addressString = '';
    if (address && address.name) {
      addressString = `<p>${address?.name || ''} <br>${address?.street || ''}, ${address?.city?.name || ''}, ${
        address?.state?.name || ''
      } - ${address?.pincode || ''}, ${address?.country?.name}.<p>`;
    }
    return addressString;
  }

  getWarehouses(site): void {
    if (site && site.id) {
      const urlParams = {
        companyId: this.companyId,
        siteId: site.id,
        category: this.filters?.category,
        warehouseName: this.filters?.warehouseName,
        warehouseIds: this.filters?.warehouseIds
      };

      this._restClientService.get(`${this.apis[1].baseUrl}warehouse-erp`, { params: urlParams }).subscribe((response: Response) => {
        if (response.success) {
          this.warehouses = response.data?.allDocuments?.map((result: any) => {
            return {
              // eslint-disable-next-line no-underscore-dangle
              id: result?._id,
              warehouseCode: result?.warehouseCode,
              name: result?.name,
              siteId: result?.siteId,
              type: result?.type,
              uniqueCode: result?.uniqueCode,
              subText: this.getAddressHTMLString(result?.address),
              company: result?.company,
              address: result?.address,
              vendorCode: result?.vendorCode
            };
          });
        }
      });
    }
  }

  onSiteChange(event: any): void {
    this.warehouseForm.get('warehouse').reset('');
    this.getWarehouses(this.selectedSite);
  }

  public save(): void {
    if (this.warehouseForm.valid) {
      this.done.next(this.warehouseForm.getRawValue());
    } else {
      Object.keys(this.warehouseForm.controls).forEach(field => {
        const control = this.warehouseForm.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({ onlySelf: true });
        }
      });
    }
  }

  public onCancel(): void {
    this.cancel.next();
  }

  get siteControl(): AbstractControl {
    return this.warehouseForm.controls.site;
  }

  get warehouseControl(): AbstractControl {
    return this.warehouseForm.controls.warehouse;
  }
}
