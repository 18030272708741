import { Injectable } from '@angular/core';
import { CreateFlow, CreateFlowType, ICreateFlowStep } from '../model/create-flow';
import { createFlowFactory } from '../model/create-flow-factory';
import { StepperQuery } from '../state/stepper.query';
import { StepperStore } from '../state/stepper.store';

@Injectable()
export class StepperService {
  createFlow: CreateFlow;

  constructor(private store: StepperStore, private query: StepperQuery) {}

  init({ steps, type, jump }: { steps: ICreateFlowStep[]; type: CreateFlowType; jump: boolean }): void {
    this.createFlow = createFlowFactory({ steps, type });
    this.store.update({ steps: this.createFlow.steps, type, jump });
  }

  next(id: string): void {
    this.createFlow.next(id);
    this.store.update({ steps: this.createFlow.steps });
  }

  prev(): void {
    this.createFlow.prev();
    this.store.update({ steps: this.createFlow.steps });
  }

  reset(): void {
    this.createFlow.reset();
    this.store.update({ steps: this.createFlow.steps });
  }

  goto(id: string): void {
    if (!this.query.jump) {
      this.createFlow.goto(id);
    }
    this.store.update({ steps: this.createFlow.steps });
  }

  jumpIsValid(id: string): boolean {
    return this.createFlow.jumpIsValid(id);
  }
}
