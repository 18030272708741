import { Component, Input, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'zet-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnChanges {
  @Input() current = 0;

  @Input() total = 0;

  progressCounter = 0;

  previousProgressCount = 0;

  dashOffset = 0;

  transitionTime: number;

  changeCounter = -1;

  progressEventTime: number[] = [];

  constructor(private cdk: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.progressEventTime.push(new Date().getTime());
    const previousCurrentCount = changes.current.previousValue ? changes.current.previousValue : 0;
    const currentCount = changes.current.currentValue;

    const previousPercentage = this.getPercentage(previousCurrentCount);
    const currentPercentage = this.getPercentage(currentCount);

    if (currentPercentage === 100) {
      this.transitionTime = this.progressEventTime.pop();
      this.dashOffset = this.returnStroke(currentPercentage);
    } else if (currentPercentage !== previousPercentage) {
      this.changeCounter += 1;
      this.transitionTime = this.getTransitionTime();
      this.dashOffset = this.returnStroke(previousPercentage);
    }
  }

  getTransitionTime(): number {
    return this.progressEventTime.length > 1
      ? Math.abs(this.progressEventTime[this.changeCounter] - this.progressEventTime[this.changeCounter - 1])
      : this.progressEventTime[0];
  }

  getPercentage(current: number): number {
    return Math.round((current * 100) / this.total);
  }

  returnTransition(): string {
    return `stroke-dashoffset ${this.transitionTime}ms linear`;
  }

  returnStroke(progress: number): number {
    let currentProgress = progress;
    const c = Math.PI * (9 * 2);
    if (progress < 0) {
      currentProgress = 0;
    }
    if (progress > 100) {
      currentProgress = 100;
    }
    return ((100 - currentProgress) / 100) * c;
  }
}
