<!--TODO Remove this after dynamic component-->
<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="paginationControlType.DEFAULT">
    <zet-default-pagination-controls
      [maxSize]="maxSize"
      (pageChange)="pageChange.emit($event)"
      (pageBoundsCorrection)="pageBoundsCorrection.emit($event)"
    ></zet-default-pagination-controls>
  </ng-container>
  <ng-container *ngSwitchCase="paginationControlType.DROPDOWN">
    <zet-dropdown-pagination-controls
      [maxSize]="maxSize"
      (pageChange)="pageChange.emit($event)"
      (pageBoundsCorrection)="pageBoundsCorrection.emit($event)"
    ></zet-dropdown-pagination-controls>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <zet-default-pagination-controls
      [maxSize]="maxSize"
      (pageChange)="pageChange.emit($event)"
      (pageBoundsCorrection)="pageBoundsCorrection.emit($event)"
    ></zet-default-pagination-controls>
  </ng-container>
</ng-container>
