import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AgendaJobState, AgendaJobStore } from './agenda-job.store';
import { AgendaJob, IServerFile } from '../models/agenda-job';

@Injectable()
export class AgendaJobQuery extends Query<AgendaJobState> {
  job$ = this.select('job');

  constructor(protected store: AgendaJobStore) {
    super(store);
  }

  get job(): Partial<AgendaJob> {
    return this.getValue().job;
  }

  get uploadedFile(): IServerFile {
    return this.getValue().uploadedFile;
  }
}
