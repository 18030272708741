import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zet-icon-library',
  templateUrl: './icon-library.component.html',
  styleUrls: ['./icon-library.component.scss']
})
export class IconLibraryComponent implements OnInit {
  url = 'https://marella.me/material-icons/demo/';

  constructor() {}

  ngOnInit() {}
}
