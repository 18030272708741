import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BankAccountMasterWidgetComponent } from './components/bank-account-master-widget.component';
import { AddressModule } from '../address';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, AddressModule],
  declarations: [BankAccountMasterWidgetComponent],
  exports: [BankAccountMasterWidgetComponent]
})
export class BankMasterModule {}
