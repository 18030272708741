import { Injectable, TemplateRef } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ISidemenuItem } from '../models/sidemenu';
import { SidemenuState, SidemenuStore } from './sidemenu.store';

@Injectable({ providedIn: 'root' })
export class SidemenuQuery extends Query<SidemenuState> {
  items$: Observable<ISidemenuItem[]> = this.select('items');

  isOpen$: Observable<boolean> = this.select(state => state.ui.isOpen);

  headerInfoTemplate$: Observable<TemplateRef<unknown>> = this.select('headerInfoTemplate');

  footerInfoTemplate$: Observable<TemplateRef<unknown>> = this.select('footerInfoTemplate');

  constructor(protected store: SidemenuStore) {
    super(store);
  }

  get items(): ISidemenuItem[] {
    return this.getValue().items;
  }

  get activeItemIndex(): number {
    return this.getValue().activeItemIndex;
  }

  get activeItem(): ISidemenuItem {
    return this.getValue().activeItem;
  }

  get isOpen(): boolean {
    return this.getValue().ui.isOpen;
  }
}
