/* eslint-disable no-underscore-dangle */
import { ITab } from './tabs';

export abstract class AbstractTabs {
  activeTab: ITab;

  _tabs: ITab[];

  selectTab(tab: ITab): void {
    const index = this._tabs.findIndex(t => t.link === tab.link);

    const preIndexTabs = this._tabs.slice(0, index).map(t => {
      return { ...t, selected: false };
    });

    this.activeTab = { ...tab, selected: true };

    const postIndexTabs = this._tabs.slice(index + 1).map(t => {
      return { ...t, selected: false };
    });

    this._tabs = [...preIndexTabs, this.activeTab, ...postIndexTabs];
  }
}
