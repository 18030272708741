import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IPage, IPageInfo, IPaginationInstance } from '../model/pagination';

export interface PaginationState {
  pageInfo: IPageInfo;
  pageInstance: IPaginationInstance;
  pages: IPage[];
  allPages: IPage[];
}

export function createInitialState(): PaginationState {
  return {
    pageInfo: {
      collection: [],
      end: 0,
      size: 0,
      start: 0,
      slice: []
    },
    pageInstance: {
      currentPage: 1,
      itemsPerPage: 0,
      totalItems: 0
    },
    pages: [],
    allPages: []
  };
}

@Injectable()
@StoreConfig({ name: 'zetui.pagination' })
export class PaginationStore extends Store<PaginationState> {
  constructor() {
    super(createInitialState());
  }
}
