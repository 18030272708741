import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zet-email-otp-login',
  templateUrl: './email-otp-login.component.html'
})
export class EmailOtpLoginComponent implements OnInit {
  currentActiveSrceen!: string;

  constructor() {}

  ngOnInit() {
    this.setCurrentActiveSrceen('login-enter-email');
  }

  setCurrentActiveSrceen(screen: string): void {
    this.currentActiveSrceen = screen;
  }
}
