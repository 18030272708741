/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import { ItemMasterQuery } from './item-master.query';
import { ItemMasterState, ItemMasterStore } from './item-master.store';
import { ITEM_GROUP, PRODUCT_TYPE } from '@zetwerk/zet-erp-constants';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ItemMasterListModel } from '../models/list';
import { RestClientService } from './rest-client.service';
import { BrowserEnvToken, ServerConfig } from '../../browserEnvConfig';

type S = string | number | string[] | boolean;

type Assignable<Obj, Item> = {
  [Key in keyof Obj]: Obj[Key] extends Item ? Key : never;
}[keyof Obj];

type ItemMasterStateKeys = Assignable<ItemMasterState, S>; // "foo" | "baz"

@Injectable()
export class ItemMasterService {
  private _removeItem = new Subject();

  public removeItem = this._removeItem.asObservable();

  constructor(
    private http: RestClientService,
    private store: ItemMasterStore,
    private query: ItemMasterQuery,
    @Inject(BrowserEnvToken) private apis: ServerConfig[]
  ) {}

  update(key: ItemMasterStateKeys, value: any): void {
    this.store.update({ [key as string]: value });
  }

  getCategoryTree(): Observable<any> {
    const categoryType = this.query.getValue().categoryType;
    const companyId = this.query.getValue().companyId;

    this.update('isCategoryLoading', true);
    return this.http
      .get(`${this.apis[0].baseUrl}category/tree`, {
        params: { type: categoryType, companyId }
      })
      .pipe(
        tap((res: any) => {
          this.update('isCategoryLoading', false);
          if (res?.data.length > 0) {
            res.data = res.data.map(node => {
              node.parent = 'All';
              return node;
            });
          }

          const data = [
            {
              _id: 'All',
              parent: null,
              children: res.data || [],
              name: 'All'
            }
          ];

          this.update('categoryLabel', data[0].name);

          res.data = data;
        }),
        catchError(err => {
          this.update('isCategoryLoading', false);
          return err;
        })
      );
  }

  getItems({ context }): Observable<any> {
    if (context.parentCategory === 'All') {
      delete context.parentCategory;
    }
    setTimeout(() => {
      this.update('isListLoading', true);
    }, 0);
    return this.http
      .get(`${this.apis[0].baseUrl}items`, {
        params: context
      })
      .pipe(
        tap((res: any) => {
          this.update('isListLoading', false);

          const { meta } = res.data || { meta: {} };
          this.update('emptyList', false);
          this.update('noResults', false);
          if (meta?.total === 0 && Object.keys(context).length > 0) {
            this.update('emptyList', true);
          } else if (meta?.total === 0) {
            this.update('noResults', true);
          }
        })
      );
  }

  getSelectedItems(): ItemMasterListModel['selectedItems'] {
    return this.query.selectedItems;
  }

  getUnselectSelectedItems(): ItemMasterListModel['unselectSelectedItems'][] {
    return this.query.unselectSelectedItems;
  }

  getUomSymbols(): Observable<any> {
    return this.http.get(`${this.apis[0].baseUrl}uom`).pipe(
      tap((res: any) => {
        this.update('isListLoading', false);

        const { meta } = res.data || { meta: {} };
        this.update('emptyList', false);
        this.update('noResults', false);
        if (meta?.total === 0) {
          this.update('emptyList', true);
        } else if (meta?.total === 0) {
          this.update('noResults', true);
        }
      })
    );
  }

  getProductTypes(): Observable<any> {
    return of(PRODUCT_TYPE);
  }

  clearAllFilters(): void {
    this.store.update({
      parentCategory: '',
      uom: [],
      productType: '',
      searchText: ''
    });
  }

  removeItemsFromSelectedList(items: any[]): void {
    let selectedItems = this.query.selectedItems;

    selectedItems = selectedItems.filter(item => !items.find(i => i._id === item._id));

    this.update('selectedItems', [...selectedItems]);

    this._removeItem.next(items);
  }

  clear() {
    this.store.reset();
  }
}
