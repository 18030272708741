import { Component, ContentChild, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FileUploadStatus, IUploadFile, UploadFile } from '../../model/file-upload';
import { FileUploadService } from '../../services/file-upload.service';

@Component({
  selector: 'zet-file-upload-group',
  template: ''
})
export class FileUploadGroupComponent implements OnChanges {
  @Input() files: IUploadFile[] = [];

  @Input() groupId = '';

  @Output() done = new EventEmitter();

  @ContentChild('fileGroupHeader') fileGroupHeader: TemplateRef<unknown>;

  constructor(private fileUpload: FileUploadService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.files?.currentValue !== changes?.files?.previousValue) {
      let files = this.files.map(uploadFile => {
        let file = new UploadFile({
          groupId: this.groupId,
          name: uploadFile?.name,
          size: uploadFile?.fileSize,
          src: uploadFile?.path,
          type: uploadFile?.fileType,
          fileObj: uploadFile,
          status: FileUploadStatus.COMPLETED
        });
        return file;
      });
      this.fileUpload.init(files);
    }
  }
}
