<cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <cdk-nested-tree-node *cdkTreeNodeDef="let node">
    <section class="tree">
      <div class="tree-node" [class.node-is-selected]="isSelectedNode(node)" (click)="selectNode(node)">
        <div *ngFor="let item of [].constructor(getLevel(dataSource, node))" class="tree-node--level"></div>
        <button class="zbtn zbtn-transparent tree-node--toggle">
          <span class="material-icons"> arrow_right </span>
        </button>
        <div class="tree-node-item" (click)="selectNode(node)">{{ node.name }}</div>
      </div>
    </section>
  </cdk-nested-tree-node>

  <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild">
    <section class="tree" [class.node-is-parent]="node.parent === null">
      <div class="tree-node" [class.node-is-selected]="isSelectedNode(node)" (click)="selectNode(node)">
        <div *ngFor="let item of [].constructor(getLevel(dataSource, node))" class="tree-node--level"></div>
        <button class="zbtn zbtn-transparent tree-node--toggle">
          <span *ngIf="treeControl.isExpanded(node)" class="material-icons"> arrow_drop_down </span>
          <span *ngIf="!treeControl.isExpanded(node)" class="material-icons"> arrow_right </span>
        </button>
        <div class="tree-node-item">{{ node.name }}</div>
      </div>
      <div [class.tree-is-invisible]="!treeControl.isExpanded(node)">
        <ng-container cdkTreeNodeOutlet></ng-container>
      </div>
    </section>
  </cdk-nested-tree-node>
</cdk-tree>
