import { DOCUMENT } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, NgZone, AfterViewInit, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import { SpyScrollService } from '../../services/spy-scroll.service';
import { SpyScrollQuery } from '../../states/spy-scroll.query';
import { SpyScrollStore } from '../../states/spy-scroll.store';

@Component({
  selector: 'zet-spyscroll',
  templateUrl: './spy-scroll.component.html',
  styleUrls: ['./spy-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SpyScrollService, SpyScrollStore, SpyScrollQuery]
})
export class SpyScrollComponent implements OnChanges, AfterViewInit {
  @Input() offsetTop = 0;

  @Input() container: string;

  destroyScrollEvent$ = new Subject();

  constructor(@Inject(DOCUMENT) private doc: Document, private zone: NgZone, private service: SpyScrollService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.container) {
      let scrollContainer = this.doc.querySelector(this.container);
      this.service.setScrollContainer(scrollContainer);
    }
  }

  ngAfterViewInit(): void {
    this.registerScrollEvent();
  }

  registerScrollEvent(): void {
    this.destroyScrollEvent$.next();

    this.zone.runOutsideAngular(() => {
      fromEvent(this.service.getContainer(), 'scroll')
        .pipe(throttleTime(50), takeUntil(this.destroyScrollEvent$))
        .subscribe(() => {
          this.service.handleScrollEvent();
        });
    });
  }
}
