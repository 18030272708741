import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '../auth/auth.service';

import { LoginService } from './login.service';

// TODO Refactor code
@Component({
  selector: 'zet-login-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent {
  @Output() changeScreen: EventEmitter<string> = new EventEmitter();

  public email = '';

  public timer = 0;

  private timerInterval: any = {};

  constructor(private loginService: LoginService, private authService: AuthService) {}

  otp = '';

  public error = false;

  @ViewChild('ngOtpInput') ngOtpInput: any;

  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '70px',
      height: '32px',
      'font-size': '14px',
      background: 'rgb(255, 255, 255)',
      'border-radius': '4px',
      border: '1px solid rbga(0,0,0,.2)',
      color: 'rgb(37, 42, 51)',
      outline: 'none'
    },
    containerStyles: {
      display: 'flex',
      'justify-content': 'space-between'
    }
  };

  onOtpChange(otp: any) {
    this.otp = otp;
    this.error = false;
  }

  ngOnInit() {
    this.email = this.loginService.getLoginInfo('email');
  }

  goBack() {
    this.changeScreen.emit('login-enter-email');
  }

  /**
   * Initialize the count down timer
   */
  initResendOTP() {
    if (this.timer === 0) {
      this.requestOTP();
    }
    this.timer = 30;
    this.timerInterval = setInterval(() => this.countDownTimer(), 1000);
  }

  /**
   * Set value for timer
   */
  countDownTimer() {
    this.timer !== 0 ? (this.timer -= 1) : clearInterval(this.timerInterval);
  }

  requestOTP() {
    this.error = false;
    this.ngOtpInput.setValue('');
    this.loginService.requestOTP(this.email).subscribe((res: any) => {});
  }

  login() {
    if (this.otp.length !== 4) {
      return;
    }
    this.loginService
      .OTPLogin({
        otp: this.otp,
        email: this.email
      })
      .subscribe(
        (res: any) => {
          if (res.success) {
            this.error = false;
            /** Process the login */
            this.authService.setAuthData(res);
          } else {
            this.error = true;
          }
        },
        (e: any) => {
          this.error = true;
        }
      );
  }
}
