<div class="login-otp-container">
  <div class="email">{{ email }}</div>
  <div class="back" (click)="goBack()">Change</div>
  <div class="header">
    <div class="heading">Enter OTP</div>
    <div *ngIf="timer === 0" class="resend" (click)="initResendOTP()">Resend</div>
    <div *ngIf="timer > 0" class="resend-timer">You can resend OTP in <span class="bold-timer">{{ timer }}</span> seconds</div>
  </div>
  <form (keyup.enter)="login()">
    <div class="otp">
      <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
    </div>
    <div class="error-wrapper" *ngIf="error">
      <img src="/assets/images/error-icon.svg" alt="" class="icon" />
      <div class="error">Wrong OTP, Please enter correct OTP</div>
    </div>
    <button [disabled]="!otp || otp?.length !== 4" class="login-black-button" (click)="login()">Log In</button>
  </form>

  <div class="error-message"></div>
  <div class="message-wrapper">
    <div class="message">
      <span class="success-text">Success: </span>
      <span class="message-text"></span>One-Time Password (OTP) has been sent to the above mentioned email ID.
    </div>
  </div>
</div>
