import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CreateFlowType, ICreateFlowStep } from '../model/create-flow';

export interface IStepItemState {
  id: string;
  accordionState: boolean;
}

export interface IStepState {
  [id: string]: IStepItemState;
}

export interface CreateFlowState {
  ui: {
    stepState: IStepState;
  };
  steps: ICreateFlowStep[];
  type: CreateFlowType;
  jump: boolean;
}

export function createInitialState(): CreateFlowState {
  return {
    ui: {
      stepState: {}
    },
    steps: [],
    type: CreateFlowType.LINEAR,
    jump: false
  };
}

@Injectable()
@StoreConfig({ name: 'zetui.create-flow' })
export class StepperStore extends Store<CreateFlowState> {
  constructor() {
    super(createInitialState());
  }

  updateStepItemState(stepState: IStepState): void {
    this.update({ ui: { stepState } });
  }
}
