import { Injectable } from '@angular/core';
import { IFileViewerFile, IFileViewerControls } from '../model/file-viewer';
import { FileViewerQuery } from '../state/file-viewer.query';
import { FileViewerStore } from '../state/file-viewer.store';

@Injectable({ providedIn: 'root' })
export class FileViewerService implements IFileViewerControls {
  constructor(private store: FileViewerStore, private query: FileViewerQuery) {}

  prev(): void {
    const page = this.query.page;
    if (page <= 1) {
      return;
    }
    const currentPage = page - 1;
    this.store.update({ page: currentPage });
  }

  next(): void {
    const page = this.query.page;
    const totalPages = this.query.totalPage;
    if (page >= totalPages) {
      return;
    }
    const currentPage = page + 1;
    this.store.update({ page: currentPage });
  }

  zoomIn(): void {
    const zoom = this.query.zoom;
    const newZoom = zoom + 0.25;
    this.store.update({ zoom: newZoom });
  }

  zoomOut(): void {
    const zoom = this.query.zoom;
    const newZoom = zoom - 0.25;
    this.store.update({ zoom: newZoom });
  }

  zoomReset(): void {
    this.store.update({ zoom: 1 });
  }

  init({ files, src, type, name }: any): void {
    this.store.update({ files, src, type, name });
  }

  selectFile(file: IFileViewerFile): void {
    let files = this.query.files;
    const index = files.findIndex(f => f.name === file.name);

    const preIndexFiles = files.slice(0, index).map(f => {
      return { ...f, selected: false };
    });

    const activeFile = { ...file, selected: true };

    const postIndexFiles = files.slice(index + 1).map(f => {
      return { ...f, selected: false };
    });

    files = [...preIndexFiles, activeFile, ...postIndexFiles];
    this.store.update({ files, page: 1 });
  }

  goto(): void {}

  reset(): void {
    this.store.update({ page: 1, totalPage: 0, zoom: 1 });
  }

  delete(): void {}

  download(): void {
    const { src, name } = this.query.activeFile;
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = src;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  updateStore({ key, value }: { key: string; value: any }): void {
    this.store.update({ [key]: value });
  }
}
