import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ConfigState {
  [key: string]: { [key: string]: any };
}

export function createInitialState(): ConfigState {
  return {};
}

@StoreConfig({ name: 'config' })
@Injectable({ providedIn: 'root' })
export class ConfigStore extends Store<ConfigState> {
  constructor() {
    super(createInitialState());
  }
}
