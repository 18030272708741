import { CreateFlow, CreateFlowType, ICreateFlowStep } from './create-flow';
import { LinearCreateFlow } from './linear-create-flow';
import { NonLinearCreateFlow } from './non-linear-create-flow';

export function createFlowFactory({ steps, type }: { steps: ICreateFlowStep[]; type: CreateFlowType }): CreateFlow {
  switch (type) {
    case CreateFlowType.LINEAR:
      return new LinearCreateFlow(steps);
    case CreateFlowType.NON_LINEAR:
      return new NonLinearCreateFlow(steps);
    default:
      throw new Error('Invalid type');
  }
}
