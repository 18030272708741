<article class="widget widget-upload">
  <header class="widget--header">
    <h2>Upload {{title$ | async}}</h2>
  </header>
  <section class="widget--body">
    <zet-stepper
      class="widget-upload--stepper"
      [steps]="steps$ | async"
      [type]="(isCompanyRequired$ | async) ? createFlowType.LINEAR : createFlowType.NON_LINEAR"
    >
      <ng-template [zetStepperItem] let-step="step" let-next="next">
        <article class="widget-upload--step">
          <zet-address-dropdown
            [items]="allCompanies$ | async"
            [formControl]="companyControl"
            [multiple]="query.selectMultipleCompany$ | async"
            placeholder="Select Company"
            [clearable]="true"
            [checkbox]="true"
            [closeOnSelect]="false"
            [appendTo]="'body'"
            bindLabel="name"
            id="company"
            name="company"
            (search)="searchCompany($event)"
            [selectAll]="true"
          ></zet-address-dropdown>
          <button *ngIf="isCompanyRequired$ | async" [disabled]="!companyControl.value" type="button" class="btn btn-link" (click)="next()">
            Next
          </button>
          <button *ngIf="(isCompanyRequired$ | async) === false" type="button" class="btn btn-link" (click)="next()">Skip</button>
        </article>
      </ng-template>

      <ng-template [zetStepperItem] let-step="step" let-next="next">
        <div class="widget-upload--sample">
          <span>Sample template</span>
          <button type="button" (click)="downloadSampleTemplate()"><span class="material-icons">file_download</span></button>
        </div>

        <article class="widget-upload--step upload">
          <ng-container #fileUploadVC> </ng-container>
        </article>

        <zet-agenda-job-status
          *ngIf="jobTaskId$ | async"
          [app]="app$ | async"
          [name]="title$ | async"
          [taskId]="jobTaskId$ | async"
          [type]="'Upload'"
          [mode]="'refresh'"
          [uploadedFile]="uploadedFile$ | async"
          (done)="onJobDone($event)"
        >
          <ng-template #jobRunningTemplate>
            <article class="agenda-job-card--status">
              {{ title$ | async }} Upload: File {{ (uploadedFile$ | async)?.name }} is being processed. Please refresh to know the status.
            </article>
          </ng-template>

          <ng-template #jobSuccessTemplate let-job="job">
            <article class="agenda-job-card--status">
              <ng-template [ngIf]="!job?.jobStats?.errorReportUrl">
                {{ title$ | async }} Upload: File {{ (uploadedFile$ | async)?.name }} processed successfully.
              </ng-template>
              <ng-template [ngIf]="job?.jobStats?.errorReportUrl">
                File {{ (uploadedFile$ | async)?.name }} update failed - Download the error report here.
                <a target="_blank" href="{{ job?.jobStats?.errorReportUrl }}">
                  <span class="material-icons">open_in_new</span>
                </a>
              </ng-template>
            </article>
          </ng-template>

          <ng-template #jobErrorTemplate let-job="job">
            <article *ngIf="job?.failReason">
              <p class="text-danger">{{job?.failReason}}</p>
            </article>
            <article class="agenda-job-card--status" *ngIf="job?.jobStats?.errorReportUrl">
              File {{ (uploadedFile$ | async)?.name }} update failed - Download the error report here.
              <a target="_blank" href="{{ job?.jobStats?.errorReportUrl }}">
                <span class="material-icons">open_in_new</span>
              </a>
            </article>
          </ng-template>
        </zet-agenda-job-status>
      </ng-template>
    </zet-stepper>
  </section>
  <footer class="widget--footer">
    <button type="button" (click)="onCancel()" class="btn btn-secondary">Cancel</button>
    <button
      type="button"
      [disabled]="(uploadedFile$ | async) === false || (uploadedFile$ | async) === null || (uploadedFile$ | async) === undefined"
      (click)="onDone()"
      class="btn btn-primary"
    >
      {{ (jobTaskId$ | async) ? 'Done' : 'Submit' }}
    </button>
  </footer>
</article>
