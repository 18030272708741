/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { LOCATION_ROLES, EPZ_CODES } from '@zetwerk/zet-erp-constants';
import { Component, Input, OnInit, OnChanges, Inject, forwardRef, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { IAddress, IDropdownItemToDisable, IFieldToDisable, validationMessages } from '../../models/address-widget';
import { browserEnvProviders, BrowserEnvToken, ServerConfig } from '../../../browserEnvConfig';

@Component({
  selector: 'zet-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true
    },
    ...browserEnvProviders
  ]
})
export class AddressFormComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() title: string;

  @Input() address: IAddress;

  @Input() fieldToDisable: IFieldToDisable;

  @Input() dropdownItemToDisable: IDropdownItemToDisable;

  @Input() syncAddressFromGST = false;

  @Input() customClass = 'g-22';

  countryItems: any[] = [];

  stateItems: any[] = [];

  cityItems: any[] = [];

  pincodeItems: any[] = [];

  locationRoleItems: string[] = [...LOCATION_ROLES];

  epzCodes: string[] = [...EPZ_CODES];

  validationMessages = validationMessages;

  public addressForm: FormGroup;

  public onChange: (data: any) => void;

  public onTouch: () => void;

  constructor(@Inject(BrowserEnvToken) public apis: ServerConfig[], private fb: FormBuilder, private http: HttpClient) {
    this.addressForm = this.initAddressForm();
  }

  ngOnInit(): void {
    this.getCountries();

    this.addressForm.valueChanges.subscribe(async () => {
      const address: IAddress = await this.convertFormToAddress();
      if (this.onChange) {
        this.onChange(address);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.address?.previousValue !== changes.address?.currentValue && changes.address?.currentValue) {
      const gstNumber = this.address?.gstNumber || this.gstNumberControl?.value;
      if (gstNumber && this.syncAddressFromGST) {
        this.getGSTDetail(gstNumber);
      }

      this.fetchCountryStateCity();
      this.patchAddress();
    }
  }

  private fetchCountryStateCity(): void {
    const countryId = this.address?.country?._id || this.countryIdControl?.value;
    const stateId = this.address?.state?._id || this.stateIdControl?.value;

    if (countryId) {
      this.getStates({ countryId });
    }

    if (stateId) {
      this.getCities({ stateId });
    }
  }

  get nameControl(): AbstractControl {
    return this.addressForm.controls.name;
  }

  get cityIdControl(): AbstractControl {
    return this.addressForm.controls.cityId;
  }

  get stateIdControl(): AbstractControl {
    return this.addressForm.controls.stateId;
  }

  get countryIdControl(): AbstractControl {
    return this.addressForm.controls.countryId;
  }

  get pincodeControl(): AbstractControl {
    return this.addressForm.controls.pincode;
  }

  get streetControl(): AbstractControl {
    return this.addressForm.controls.street;
  }

  get isPrimaryControl(): AbstractControl {
    return this.addressForm.controls.isPrimary;
  }

  get locationRolesControl(): AbstractControl {
    return this.addressForm.controls.locationRoles;
  }

  get gstNumberControl(): AbstractControl {
    return this.addressForm.controls.gstNumber;
  }

  get epzCodeControl(): AbstractControl {
    return this.addressForm.controls.epzCode;
  }

  public initAddressForm(): FormGroup {
    return this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(60), this.noWhitespaceValidator()])],
      cityId: ['', Validators.compose([Validators.required])],
      stateId: ['', Validators.compose([Validators.required])],
      countryId: ['', Validators.compose([Validators.required])],
      pincode: ['', Validators.compose([Validators.required, this.pincodeValidate()])],
      locationRoles: ['', Validators.compose([Validators.required])],
      epzCode: ['None', Validators.compose([Validators.required])],
      street: ['', Validators.compose([Validators.required, Validators.maxLength(250), this.noWhitespaceValidator()])],
      gstNumber: ['', Validators.compose([Validators.maxLength(15), Validators.minLength(15), Validators.pattern('^[0-9]{2}.*')])],
      isPrimary: [false]
    });
  }

  public patchAddress(payload?: IAddress): void {
    this.addressForm.patchValue({
      name: this.address?.name || payload?.name || '',
      cityId: this.address?.city?._id || payload?.city?._id || '',
      stateId: this.address?.state?._id || payload?.state?._id || '',
      countryId: this.address?.country?._id || payload?.country?._id || '',
      pincode: this.address?.pincode || payload?.pincode || '',
      street: this.address?.street || payload?.street || '',
      isPrimary: this.address?.isPrimary || payload?.isPrimary || false,
      locationRoles: this.address?.locationRoles || payload?.locationRoles || '',
      epzCode: this.address?.epzCode || payload?.epzCode || 'None',
      gstNumber: this.address?.gstNumber || payload?.gstNumber || ''
    });
  }

  public onCountryChange(country: any): void {
    const id = country._id;
    this.addressForm.controls.stateId.reset('');
    this.addressForm.controls.cityId.reset('');
    this.addressForm.controls.pincode.reset('');
    this.getStates({ countryId: id });
  }

  public onStateChange(state: any): void {
    const id = state._id;
    this.addressForm.controls.cityId.reset('');
    this.addressForm.controls.pincode.reset('');
    this.getCities({ stateId: id });
  }

  public getCountries(): void {
    this.http.get(`${this.apis[0].baseUrl}geo/country`).subscribe((response: any) => {
      if (response.success) {
        this.countryItems = response.data;
      }
    });
  }

  public getStates({ countryId }: { countryId: string }): void {
    this.http.get(`${this.apis[0].baseUrl}geo/state`, { params: { countryId } }).subscribe((response: any) => {
      if (response.success) {
        this.stateItems = response.data;
        if (this.stateItems?.length === 1) {
          this.stateIdControl.setValue(this.stateItems[0]._id);
          this.getCities({ stateId: this.stateItems[0]._id });
        }
      }
    });
  }

  public getCities({ stateId, pageNumber = '1', searchText = '' }: { stateId?: string; pageNumber?: string; searchText?: string }): void {
    const selectedStateId = stateId || this.addressForm.controls.stateId.value;
    const searchKeyword = searchText || '';
    this.http
      .get(`${this.apis[0].baseUrl}geo/city`, {
        params: { stateId: selectedStateId, pageNumber, searchText: searchKeyword, recordsPerPage: '700' }
      })
      .subscribe((response: any) => {
        if (response.success) {
          this.cityItems = response.data.allDocuments;
          if (this.cityItems?.length === 1) {
            this.cityIdControl.setValue(this.cityItems[0]._id);
          }
        }
      });
  }

  public async getCitiesById({ cityId }: { cityId: string }): Promise<any> {
    const res: any = await this.http.get(`${this.apis[0].baseUrl}geo/city/${cityId}`).toPromise();
    if (res?.data && res.data?._id) {
      this.cityItems.push(res.data);
      return res.data;
    }

    return null;
  }

  public getPincodes({ cityId, pageNumber = '1', searchText = '' }: { cityId?: string; pageNumber?: string; searchText?: string }): void {
    const selectedCityId = cityId || this.addressForm.controls.cityId.value;
    this.http
      .get(`${this.apis[0].baseUrl}geo/pincode`, {
        params: { cityId: selectedCityId, pageNumber, searchText, recordsPerPage: '100' }
      })
      .subscribe((response: any) => {
        if (response.success) {
          this.pincodeItems = response.data.allDocuments;
        }
      });
  }

  public getGSTDetail(gst: string): void {
    if (gst && gst.length > 0) {
      this.http.get(`${this.apis[0].baseUrl}geo/gst-detail/${gst}`).subscribe((response: any) => {
        if (response.success) {
          const gstDetail = response.data;
          this.addressForm.patchValue({
            cityId: gstDetail?.city?._id || this.address?.city?._id,
            stateId: gstDetail?.state?._id || '',
            countryId: gstDetail?.country?._id || '',
            pincode: gstDetail?.pincode || '',
            street: gstDetail?.street || ''
          });

          if (this.stateItems?.length === 0 || this.countryIdControl.value !== gstDetail?.country?._id) {
            this.fetchCountryStateCity();
          }
        }
      });
    }
  }

  public writeValue(obj: IAddress): void {
    this.patchAddress(obj);
  }

  public registerOnChange(fn: (data: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public isValidForm(): boolean {
    if (this.addressForm.valid) {
      return true;
    }

    Object.keys(this.addressForm.controls).forEach(field => {
      const control = this.addressForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    return false;
  }

  private async convertFormToAddress(): Promise<IAddress> {
    let address = this.addressForm.getRawValue();
    const country = this.countryItems.find(item => item._id === address.countryId);
    const state = this.stateItems.find(item => item._id === address.stateId);
    let city = this.cityItems.find(item => item._id === address.cityId);

    if (!city && address?.cityId) {
      city = await this.getCitiesById({ cityId: address?.cityId });
    }

    if (this.address?.addressId) {
      address.addressId = this.address.addressId;
    }

    address.country = {
      _id: address?.countryId,
      name: country?.name || this.address?.country?.name || '',
      code: country?.iso3 || country?.numeric_code || this.address?.country?.code || '',
      phone_code: country?.phone_code || this.address?.country?.phone_code || ''
    };
    address.state = {
      _id: address?.stateId,
      name: state?.name || this.address?.state?.name || '',
      code: state?.state_code || this.address?.state?.code || ''
    };
    address.city = {
      _id: address?.cityId || '',
      name: city?.name || this.address?.city?.name || ''
    };

    return address;
  }

  public pincodeValidate(): ValidatorFn {
    return (c: AbstractControl) => {
      const regex = new RegExp('^[0-9]{1,6}$');

      const country = this.countryItems.find(value => {
        return value._id === this.countryIdControl.value;
      });
      if (country && country.name === 'India') {
        return regex.test(c.value)
          ? null
          : {
              pincodeIndInvalid: true
            };
      }
      return null;
    };
  }

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      return isWhitespace ? { required: true } : null;
    };
  }
}
