import { NgModule } from '@angular/core';
import { DepolymentModule } from './features/depolyment';
import { LoaderModule } from './features/loader';
import { LoginModule } from './features/login';
import { HttpModule } from './features/http';
import { AuthModule } from './features/auth';
import { ZetEventAnalyticsModule } from './features/event-analytics';

@NgModule({
  imports: [AuthModule, ZetEventAnalyticsModule, HttpModule, LoginModule, DepolymentModule, LoaderModule],
  declarations: [],
  exports: [AuthModule, ZetEventAnalyticsModule, HttpModule, LoginModule, DepolymentModule, LoaderModule],
  providers: []
})
export class ZetAppSharedModule {}
