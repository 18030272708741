import { Injectable, TemplateRef } from '@angular/core';
import { SidemenuStore } from './sidemenu.store';
import { ISidemenuItem } from '../models/sidemenu';

// FIXME can we remove providedIn: 'root'?
@Injectable({ providedIn: 'root' })
export class SidemenuStoreService {
  constructor(private sidemenuStore: SidemenuStore) {}

  setActiveItemIndex(activeItemIndex: number): void {
    this.sidemenuStore.update({ activeItemIndex });
  }

  setActiveItem(activeItem: ISidemenuItem): void {
    this.sidemenuStore.update({ activeItem });
  }

  setIsOpen(isOpen: boolean): void {
    this.sidemenuStore.update({ ui: { isOpen } });
  }

  setItems(items: ISidemenuItem[]): void {
    this.sidemenuStore.update({ items });
  }

  setHeaderInfoTemplate(headerInfoTemplate: TemplateRef<unknown>): void {
    this.sidemenuStore.update({ headerInfoTemplate });
  }

  setFooterInfoTemplate(footerInfoTemplate: TemplateRef<unknown>): void {
    this.sidemenuStore.update({ footerInfoTemplate });
  }
}
