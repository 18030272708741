import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DragDropComponent } from './components/dragdrop/dragdrop.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FileProgressCardComponent } from './components/file-progress-card/file-progress-card.component';
import { FileUploadListComponent } from './components/file-upload-list/file-upload-list.component';
import { FileUploadGroupComponent } from './components/file-upload-group/file-upload-group.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';

import { FileDropDirective } from './directives/file-drop.directive';
import { FileInputDirective } from './directives/file-input.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormatBytesPipe } from './utils/format-byte.pipe';
import { FilterOnKeyPipe } from './utils/filter-on-key.pipe';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [
    FileUploadComponent,
    DragDropComponent,
    FileProgressCardComponent,
    FileUploadGroupComponent,
    FileUploadListComponent,
    ProgressSpinnerComponent,
    FileDropDirective,
    FileInputDirective,
    FilterOnKeyPipe,
    FormatBytesPipe
  ],
  exports: [
    FileUploadComponent,
    DragDropComponent,
    FileProgressCardComponent,
    FileUploadGroupComponent,
    FileUploadListComponent,
    ProgressSpinnerComponent,
    FileDropDirective,
    FileInputDirective,
    FilterOnKeyPipe,
    FormatBytesPipe
  ]
})
export class FileUploadModule {}
