import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { IFileViewerFile, IFileViewerControls } from '../../model/file-viewer';
import { FileViewerService } from '../../service/file-viewer.service';
import { FileViewerQuery } from '../../state/file-viewer.query';

@Component({
  selector: 'zet-file-viewer-control',
  templateUrl: './file-viewer-control.component.html',
  styleUrls: ['./file-viewer-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileViewerControlComponent implements OnInit, IFileViewerControls {
  @Output() loaded = new EventEmitter<unknown>();

  @Output() deleted = new EventEmitter<unknown>();

  activeFile: IFileViewerFile;

  zoom$: Observable<number>;

  files$: Observable<IFileViewerFile[]>;

  activeFile$: Observable<IFileViewerFile>;

  page$: Observable<number>;

  totalPage$: Observable<number>;

  name$: Observable<string>;

  fileControl: FormControl = new FormControl('');

  constructor(private fileViewer: FileViewerService, private query: FileViewerQuery) {}

  ngOnInit(): void {
    this.zoom$ = this.query.zoom$;
    this.files$ = this.query.files$;
    this.page$ = this.query.page$;
    this.name$ = this.query.name$;
    this.totalPage$ = this.query.totalPage$;

    this.activeFile$ = this.query.activeFile$;

    const activeFile = this.query?.activeFile;

    this.fileControl.setValue(activeFile);

    this.fileControl.valueChanges.subscribe((value: IFileViewerFile) => {
      this.fileViewer.selectFile(value);
      this.loaded?.emit(value);
    });
  }

  zoomIn(): void {
    this.fileViewer.zoomIn();
  }

  zoomOut(): void {
    this.fileViewer.zoomOut();
  }

  next(): void {
    this.fileViewer.next();
  }

  prev(): void {
    this.fileViewer.prev();
  }

  goto(): void {}

  reset(): void {
    this.fileViewer.reset();
  }

  delete(): void {
    this.deleted.emit(this.query.activeFile);
  }

  download(): void {
    this.fileViewer.download();
  }
}
