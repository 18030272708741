/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { GoogleLoginProvider, SocialAuthServiceConfig } from './features/social-login';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider, APP_INITIALIZER } from '@angular/core';

import { AuthInterceptor } from './features/auth/auth.interceptor';
import { AUTH_CONFIG, IAuthConfig } from './features/auth/auth.model';
import { AuthService } from './features/auth/auth.service';

import { APP_NAME, DepolymentModule } from './features/depolyment';
import { VersionCheckService } from './features/depolyment/version-check.service';
import { HttpErrorInterceptor } from './features/http/http-error.interceptor';
import { RestClientService } from './features/http/rest-client.service';
import { LoaderInterceptor } from './features/loader/loader.interceptor';
import { ILoaderConfig, LOADER_CONFIG } from './features/loader/loader.model';
import { LoaderService } from './features/loader/loader.service';
import { ILoginConfig, LoginType, LOGIN_CONFIG } from './features/login/login.model';
import { LoginService } from './features/login/login.service';
import { HTTP_CONFIG, IHttpConfig } from './features/http/http.model';
import { persistState } from '@datorama/akita';

export interface IZetAppCoreModule {
  appName: string;
  featuresEnabled: { [key: string]: boolean };
  auth?: { config: IAuthConfig; authInterceptor?: boolean };
  http?: {
    config: IHttpConfig;
    errorInterceptor?: boolean;
    restClient?: boolean;
  };
  loader?: { config: ILoaderConfig; loaderInterceptor?: boolean };
  login?: { config: ILoginConfig };
}

// FIXME Add interface for the service
export function initializeApp(service: any) {
  return (): void => {
    return service.init();
  };
}

@NgModule({
  imports: [DepolymentModule],
  declarations: [],
  exports: [],
  providers: []
})
export class ZetAppCoreModule {
  static forRoot(config: IZetAppCoreModule): ModuleWithProviders<ZetAppCoreModule> {
    const providers: Provider[] = [];

    providers.push({ provide: APP_NAME, useValue: config.appName });

    for (let key in config.featuresEnabled) {
      const featureConfig = config[key];
      switch (key) {
        case 'auth':
          if (config.featuresEnabled.auth) {
            providers.push(
              {
                provide: AUTH_CONFIG,
                useValue: featureConfig.config
              },
              AuthService
            );
            if (featureConfig.authInterceptor || featureConfig.authInterceptor === undefined) {
              providers.push({
                provide: HTTP_INTERCEPTORS,
                useClass: AuthInterceptor,
                multi: true
              });
            }
          }
          break;
        case 'http':
          if (config.featuresEnabled.http) {
            providers.push({
              provide: HTTP_CONFIG,
              useValue: featureConfig.config
            });
            if (featureConfig.restClient || featureConfig.restClient === undefined) {
              providers.push(RestClientService);
            }
            if (featureConfig.errorInterceptor || featureConfig.errorInterceptor === undefined) {
              providers.push({
                provide: HTTP_INTERCEPTORS,
                useClass: HttpErrorInterceptor,
                multi: true
              });
            }
          }
          break;
        case 'deployment':
          if (config.featuresEnabled.deployment) {
            providers.push(
              { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [VersionCheckService], multi: true },
              VersionCheckService
            );
          }
          break;
        case 'loader':
          if (config.featuresEnabled.loader) {
            providers.push(
              {
                provide: LOADER_CONFIG,
                useValue: featureConfig.config
              },
              LoaderService
            );
            if (featureConfig.loaderInterceptor || featureConfig.loaderInterceptor === undefined) {
              providers.push({
                provide: HTTP_INTERCEPTORS,
                useClass: LoaderInterceptor,
                multi: true
              });
            }
          }
          break;
        case 'login':
          if (config.featuresEnabled.login) {
            providers.push({ provide: LOGIN_CONFIG, useValue: featureConfig.config }, LoginService);
            const googleProvider = featureConfig.config.loginProviders.find((value: any) => value.id === LoginType.GOOGLE);
            if (googleProvider) {
              providers.push({
                provide: 'SocialAuthServiceConfig',
                useValue: {
                  autoLogin: false,
                  providers: [
                    {
                      id: GoogleLoginProvider.PROVIDER_ID,
                      provider: new GoogleLoginProvider(googleProvider?.clientId)
                    }
                  ],
                  onError: err => {
                    console.error('Error in Google Login Provider:', err);
                  }
                } as SocialAuthServiceConfig
              });
            }
          }
          break;
        default:
          break;
      }
    }
    /* Commenting for now */
    // if (!isDevMode()) {
    //   enableAkitaProdMode();
    // }

    const persistStorage = persistState({
      include: ['auth', 'session.isSideBarExpanded'],
      key: 'store'
    });
    providers.push({
      provide: 'persistStorage',
      useValue: persistStorage
    });

    return {
      ngModule: ZetAppCoreModule,
      providers
    };
  }
}
