export const getOffset = (
  target: Element,
  container: Element | Document | Window | HTMLElement = window
): { top: number; left: number } => {
  let offset = {
    top: 0,
    left: 0
  };

  if (!target) {
    return offset;
  }

  const rect = target.getBoundingClientRect();

  if (container === window) {
    offset.top = rect.top;
    offset.left = rect.left;
  } else {
    const containerRect = (container as HTMLElement).getBoundingClientRect();
    offset.top = rect.top - containerRect.top;
    offset.left = rect.left - containerRect.left;
  }

  return offset;
};

export const getScrollYPosition = (target: Element | Document | Window | HTMLElement | null = window): number => {
  if (typeof window === undefined) {
    return 0;
  }

  let result = 0;

  if (target === window) {
    result = window.scrollY;
  } else if (target instanceof HTMLElement) {
    result = (target as HTMLElement).scrollTop;
  }

  return result;
};

export const easeInOutCubic = (t: number, b: number, c: number, d: number): number => {
  const cc = c - b;
  let tt = t / (d / 2);
  if (tt < 1) {
    return (cc / 2) * tt * tt * tt + b;
  }
  const final = (cc / 2) * ((tt -= 2) * tt * tt + 2) + b;
  return final;
};

export const getRequestAnimationFrame = (): typeof requestAnimationFrame => {
  if (typeof window === 'undefined') {
    return () => 0;
  }
  // https://github.com/vuejs/vue/issues/4465
  return window.requestAnimationFrame.bind(window);
};

export const reqAnimFrame = getRequestAnimationFrame();
