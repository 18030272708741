<div class="container">
  <div>
    <h3>Components</h3>
    <table cdk-table class="table table-bordered" [dataSource]="templateComponentItems">
      <!-- Position Column -->
      <ng-container cdkColumnDef="0">
        <th cdk-header-cell *cdkHeaderCellDef></th>
        <td cdk-cell *cdkCellDef="let element">
          <ng-container [ngIf]="element[0]" *ngTemplateOutlet="cellTempplate; context: { data: element[0]}"></ng-container>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container cdkColumnDef="1">
        <th cdk-header-cell *cdkHeaderCellDef></th>
        <td cdk-cell *cdkCellDef="let element">
          <ng-container [ngIf]="element[1]" *ngTemplateOutlet="cellTempplate; context: { data: element[1]}"></ng-container>
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container cdkColumnDef="2">
        <th cdk-header-cell *cdkHeaderCellDef></th>
        <td cdk-cell *cdkCellDef="let element">
          <ng-container [ngIf]="element[2]" *ngTemplateOutlet="cellTempplate; context: { data: element[2]}"></ng-container>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container cdkColumnDef="3">
        <th cdk-header-cell *cdkHeaderCellDef></th>
        <td cdk-cell *cdkCellDef="let element">
          <ng-container [ngIf]="element[3]" *ngTemplateOutlet="cellTempplate; context: { data: element[3]}"></ng-container>
        </td>
      </ng-container>

      <!-- <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr> -->
      <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div>
    <h3>Basics</h3>
    <table cdk-table class="table table-bordered" [dataSource]="designSystemItems">
      <!-- Position Column -->
      <ng-container cdkColumnDef="0">
        <th cdk-header-cell *cdkHeaderCellDef></th>
        <td cdk-cell *cdkCellDef="let element" [context]="{ item: element[0] }">
          <ng-container *ngTemplateOutlet="cellTempplate; context: { data: element[0]}"></ng-container>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container cdkColumnDef="1">
        <th cdk-header-cell *cdkHeaderCellDef></th>
        <td cdk-cell *cdkCellDef="let element" [context]="{ item: element[1] }">
          <ng-container *ngTemplateOutlet="cellTempplate; context: { data: element[1]}"></ng-container>
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container cdkColumnDef="2">
        <th cdk-header-cell *cdkHeaderCellDef></th>
        <td cdk-cell *cdkCellDef="let element" [context]="{ item: element[2] }">
          <ng-container *ngTemplateOutlet="cellTempplate; context: { data: element[2]}"></ng-container>
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container cdkColumnDef="3">
        <th cdk-header-cell *cdkHeaderCellDef></th>
        <td cdk-cell *cdkCellDef="let element" [context]="{ item: element[3] }">
          <ng-container *ngTemplateOutlet="cellTempplate; context: { data: element[3]}"></ng-container>
        </td>
      </ng-container>

      <!-- <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr> -->
      <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>

<ng-template #cellTempplate let-data="data">
  <div *ngIf="data && data.images.length" [ngClass]="data.status">
    {{ data.title }}
    <span
      zetComponentHover
      [overlayWidth]="400"
      [overlayHeight]="400"
      [content]="hoverData"
      [context]="{ item: data }"
      class="material-icons"
      >visibility</span
    >
  </div>

  <div *ngIf="data && !data?.images.length" [ngClass]="data.status">{{ data.title }}</div>
</ng-template>

<ng-template #hoverData let-item="item">
  <section class="feature">
    <div class="feature-images">
      <h3 class="margin-bottom: 100px">
        <a (click)="navigate(item)">{{ item.title }}</a>

        <span class="feature-feedback">
          <ng-template [ngIf]="item.status !== featureStatus.PRODUCTION">
            <p>Do you need this feature?</p>
            <span class="feature-feedback-thumbup material-icons" (click)="submitFeedback(item)">thumb_up</span>
          </ng-template>
          <ng-template [ngIf]="item.status === featureStatus.PRODUCTION">
            <p>Is the implementation helpful?</p>
            <span class="feature-feedback-thumbdown material-icons" (click)="submitFeedback(item)">thumb_down</span>
            <span class="feature-feedback-thumbup material-icons" (click)="submitFeedback(item)">thumb_up</span>
          </ng-template>
        </span>
      </h3>

      <div *ngFor="let img of item?.images; let index = index">
        <h6>{{img.title}}</h6>

        <img [src]="img.src" />
      </div>
    </div>
  </section>
</ng-template>
