import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ITimelineStep } from '../model/timeline';

export interface TimelineState {
  timelineSteps: ITimelineStep[];
}

export function createInitialState(): TimelineState {
  return {
    timelineSteps: []
  };
}

@Injectable()
@StoreConfig({ name: 'zetui.timeline' })
export class TimelineStore extends Store<TimelineState> {
  constructor() {
    super(createInitialState());
  }
}
