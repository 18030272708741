import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimeLineComponent } from './components/timeline.component';

import { TimelineStepDirective } from './directives/timeline-step.directive';
import { TimelineStepItemDirective } from './directives/timeline-step-item.directive';
import { TimelineService } from './timeline.service';

@NgModule({
  declarations: [TimeLineComponent, TimelineStepDirective, TimelineStepItemDirective],
  imports: [CommonModule],
  exports: [TimeLineComponent, TimelineStepDirective, TimelineStepItemDirective],
  providers: [TimelineService]
})
export class TimelineModule {}
