export type IAddressWidgetEventData = IAddress;

export interface IAddressWidgetData {
  address?: IAddress;
  fieldToDisable?: IFieldToDisable;
  dropdownItemToDisable?: IDropdownItemToDisable;
  syncAddressFromGST?: boolean;
  customClass?: string;
}

export interface IAddress {
  addressId?: string;
  name?: string;
  city?: { _id: string; name: string };
  state?: { _id: string; name: string; code: string };
  country?: { _id: string; name: string; code: string; phone_code: string };
  pincode?: string;
  street?: string;
  isPrimary?: boolean;
  gstNumber?: string;
  locationRoles?: [string];
  epzCode?: string;
}

export interface IFieldToDisable {
  name?: boolean;
  city?: boolean;
  street?: boolean;
  state?: boolean;
  country?: boolean;
  pincode?: boolean;
  isPrimary?: boolean;
  gstNumber?: boolean;
  locationRoles?: boolean;
  epzCode?: boolean;
}

export interface IDropdownItemToDisable {
  locationRoles?: string[];
  epzCode?: string[];
}

export const validationMessages = {
  name: {
    required: 'Address name is required',
    maxlength: 'Address name can be max 60 characters long.'
  },
  country: {
    required: 'Country is required'
  },
  state: {
    required: 'State is required'
  },
  city: {
    required: 'City is required'
  },
  pincode: {
    required: 'Pincode is required',
    pincodeIndInvalid: 'Invalid pincode'
  },
  street: {
    required: 'Address is required',
    maxlength: 'Address can be max 250 characters long.'
  },
  isPrimary: {},
  locationRoles: {
    required: 'Address purpose is required'
  },
  gstNumber: {
    pattern: 'Invalid GST Number',
    minlength: 'GST Number should be 15 digits',
    maxlength: 'GST Number should be 15 digits'
  }
};
