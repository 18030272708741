import { Directive, HostListener, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'zetFileInput, [zetFileInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputDirective),
      multi: true
    }
  ]
})
export class FileInputDirective implements ControlValueAccessor {
  private onTouch: (value: Array<File>) => void;

  onChange: (files: FileList) => void;

  @HostListener('change', ['$event.target.files']) onFileSubmit(event: FileList): void {
    this.onChange(event);
  }

  writeValue(): void {}

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }
}
