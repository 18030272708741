import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ItemMasterState, ItemMasterStore } from './item-master.store';
import { ItemMasterFilterModel } from '../models/filter';
import { ItemMasterListModel } from '../models/list';

@Injectable()
export class ItemMasterQuery extends Query<ItemMasterState> {
  constructor(protected store: ItemMasterStore) {
    super(store);
  }

  parentCategory$: Observable<ItemMasterState['parentCategory']> = this.select('parentCategory');

  companyId$: Observable<ItemMasterState['companyId']> = this.select('companyId');

  categoryType$: Observable<ItemMasterState['categoryType']> = this.select('categoryType');

  productType$: Observable<ItemMasterState['productType']> = this.select('productType');

  itemGroup$: Observable<ItemMasterState['itemGroup']> = this.select('itemGroup');

  searchText$: Observable<ItemMasterState['searchText']> = this.select('searchText');

  noResults$: Observable<ItemMasterState['noResults']> = this.select('noResults');

  emptyList$: Observable<ItemMasterState['emptyList']> = this.select('emptyList');

  selectedItems$: Observable<ItemMasterState['selectedItems']> = this.select('selectedItems');

  unselectSelectedItems$: Observable<ItemMasterState['unselectSelectedItems']> = this.select('unselectSelectedItems');

  categories$: Observable<ItemMasterState['categories']> = this.select('categories');

  itemGroups$: Observable<ItemMasterState['itemGroups']> = this.select('itemGroups');

  productTypes$: Observable<ItemMasterState['productTypes']> = this.select('productTypes');

  isListLoading$: Observable<ItemMasterState['isListLoading']> = this.select('isListLoading');

  isCategoryLoading$: Observable<ItemMasterState['isCategoryLoading']> = this.select('isCategoryLoading');

  transactionName$: Observable<ItemMasterState['transactionName']> = this.select('transactionName');

  categoryLabel$: Observable<ItemMasterState['categoryLabel']> = this.select('categoryLabel');

  uoms$: Observable<ItemMasterState['uoms']> = this.select('uoms');

  uom$: Observable<ItemMasterState['uom']> = this.select('uom');

  get filter(): ItemMasterFilterModel {
    return this.getValue();
  }

  get selectedItems(): ItemMasterListModel['selectedItems'] {
    return this.getValue().selectedItems || [];
  }

  get unselectSelectedItems(): ItemMasterListModel['unselectSelectedItems'] {
    return this.getValue().unselectSelectedItems || [];
  }
}
