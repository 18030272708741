<ul class="timeline" [ngClass]="[orientation === 'vertical' ? 'timeline--vertical' : 'timeline--horizontal', customClass]">
  <li
    *ngFor="let step of timelineSteps$ | async; let index = index"
    class="timeline--step"
    [ngClass]="[mode === 'stick' ? 'timeline--step-default' : 'timeline--step-'+ step.type]"
  >
    <div class="timeline--icon"></div>
    <div class="timeline--body">
      <ng-template [ngIf]="timelineRef">
        <ng-container *ngTemplateOutlet="timelineRef.temRef; context:{$implicit: step}"></ng-container>
      </ng-template>
      <ng-template [ngIf]="timelineItemsRef.length > 0">
        <ng-container *ngTemplateOutlet="getRef(index).temRef; context:{$implicit: step}"></ng-container>
      </ng-template>
    </div>
  </li>
</ul>
