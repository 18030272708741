import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { ISpyLink } from '../model/spy-scroll';
import { SpyScrollQuery } from '../states/spy-scroll.query';
import { SpyScrollStore } from '../states/spy-scroll.store';
import { easeInOutCubic, getOffset, getScrollYPosition, reqAnimFrame } from '../utils/spy-scroll.util';

@Injectable()
export class SpyScrollService {
  isScrolling: boolean;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private query: SpyScrollQuery,
    private store: SpyScrollStore,
    private zone: NgZone
  ) {}

  handleScrollEvent(): void {
    if (this.isScrolling) {
      return;
    }

    const links = this.query.links;
    // TODO calculate scope
    const scope = 0;
    let linkOffsetTop = [];

    links.forEach(link => {
      const el = this.doc.querySelector(link.id);

      if (el) {
        const elOffset = getOffset(el, this.getContainer());

        if (elOffset.top < scope) {
          linkOffsetTop.push({
            top: elOffset.top,
            link
          });
        }
      }
    });

    if (linkOffsetTop.length > 0) {
      const maxTop = linkOffsetTop.reduce((prev, curr) => (curr.top > prev.top ? curr : prev));

      this.zone.run(() => {
        this.setActiveLink(maxTop.link.id);
      });
    } else {
      this.zone.run(() => {
        this.unsetActiveLink();
      });
    }
  }

  handleScrollTo(id: string): void {
    const el = this.doc.querySelector(id);
    const container = this.getContainer();
    const containerOffsetY = getScrollYPosition(container);
    const elOffset = getOffset(el, container);
    // TODO add offset (provided inside scroll component)

    const targetTop = containerOffsetY + elOffset.top;
    const startTime = Date.now();
    const duration = 450;

    this.isScrolling = true;

    // TODO Support container
    // TODO should handle outside of angular zone
    const srcollFn = (): void => {
      const currentTime = Date.now();
      const time = currentTime - startTime;
      const y = easeInOutCubic(time > duration ? duration : time, containerOffsetY, targetTop, duration);
      if (container instanceof Element || container instanceof HTMLElement) {
        (container as HTMLElement).scrollTop = y;
      } else {
        window.scrollTo(window.scrollX, y);
      }

      if (time < duration) {
        reqAnimFrame(srcollFn);
      } else {
        this.zone.run(() => {
          if (container instanceof Element || container instanceof HTMLElement) {
            this.setActiveLink(id);
            this.isScrolling = false;
          } else {
            setTimeout(() => {
              this.setActiveLink(id);
              this.isScrolling = false;
            }, 800);
          }
        });
      }
    };

    this.zone.runOutsideAngular(() => reqAnimFrame(srcollFn));
  }

  registerLink(link: ISpyLink): void {
    const oldLinks = this.query.links;

    let newLinks = [...oldLinks, link];

    this.store.update({ links: newLinks });
  }

  getContainer(): Element | HTMLElement | Window {
    const container = this.query.scrollContainer;
    return container || window;
  }

  setActiveLink(id: string): void {
    let links = this.query.links;

    let link = links.find(l => l.id === id);
    const index = links.findIndex(l => l.id === id);

    const preIndexLinks = links.slice(0, index).map(l => {
      return { ...l, isActive: false };
    });

    const activeLink = { ...link, isActive: true };

    const postIndexLinks = links.slice(index + 1).map(l => {
      return { ...l, isActive: false };
    });

    links = [...preIndexLinks, activeLink, ...postIndexLinks];

    this.store.update({ links });
  }

  // TODO
  unsetActiveLink(): void {
    let links = this.query.links;

    const newLinks = links.map(l => {
      return { ...l, isActive: false };
    });

    this.store.update({ links: newLinks });
  }

  setScrollContainer(scrollContainer: HTMLElement | Element): void {
    this.store.update({ scrollContainer });
  }
}
