import { Component, Input } from '@angular/core';
import { ISidemenuItem } from '../../models/sidemenu';

@Component({
  selector: 'zet-sidemenu-item',
  templateUrl: './sidemenu-item.component.html',
  styleUrls: ['./sidemenu-item.component.scss']
})
export class SidemenuItemComponent {
  @Input() item: ISidemenuItem;

  @Input() isOpen: boolean;
}
