import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import * as converter from 'number-to-words';
import { DIGITS_INFO, lessThanTwenty, tenth, suffix } from './currency-pipe';

@Pipe({
  name: 'zetCurrency'
})
export class CurrencyPipe implements PipeTransform {
  constructor(
    @Inject(DEFAULT_CURRENCY_CODE) private currencyCode: string = 'INR',
    @Inject(DIGITS_INFO) private digitsInfo: string = '0.0-2',
    @Inject(LOCALE_ID) private locale: string = 'en-IN'
  ) {}

  transform(value: number, displayType: 'n' | 'w' = 'n', digitsInfo?: string, locale?: string): string | null {
    if (displayType === 'n') {
      return formatCurrency(
        value,
        locale || this.locale,
        getCurrencySymbol(this.currencyCode || 'INR', 'wide'),
        this.currencyCode || 'INR',
        digitsInfo || this.digitsInfo
      );
    }
    return this.numberToWord(value);
  }

  numberToWord(value: number): string {
    if (this.isZero(value)) {
      return 'Zero Only';
    }
    let valueStr = value.toString().split('.')[0];
    if (this.isOverflow(valueStr)) {
      return 'Overflow';
    }
    let word = '';
    if (this.currencyCode === 'INR') {
      word = this.indianCurrencyToWords(valueStr);
    } else {
      word = converter.toWords(value);
      word = `${word.split(',').join('')} Only`;
    }
    word = `${word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()} Only `;
    return word;
  }

  indianCurrencyToWords(value: string): string {
    const valueArr = this.splitStringInPattern(value);
    if (!valueArr) {
      return '';
    }
    let word = '';
    for (let i = 1; i <= 6; i += 1) {
      let s = this.partIntoWord(valueArr, i);
      word += s;
    }
    return word;
  }

  isOverflow(value: string): boolean {
    if (value.length > 10) {
      return true;
    }
    return false;
  }

  isZero(value: any): boolean {
    if (Number.isNaN(value) || !value) {
      return true;
    }
    return false;
  }

  splitStringInPattern(value: any): string[] {
    let str = `0000000000${value}`.substr(-10).match(/^(\d{1})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    return str;
  }

  partIntoWord(valueArr: string[], ind: number): string {
    let word = '';
    if (Number(valueArr[ind]) !== 0) {
      word =
        (lessThanTwenty[Number(valueArr[ind])] || `${tenth[Number(valueArr[ind][0])]} ${lessThanTwenty[Number(valueArr[ind][1])]}`) +
        suffix[ind];
    }
    if (ind === 2 && word === '' && Number(valueArr[1]) !== 0) {
      word = 'Crore ';
    }
    return word;
  }
}
