import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpyScrollComponent } from './components/spy-scroll/spy-scroll.component';
import { SpyScrollLinkComponent } from './components/spy-scroll-link/spy-scroll-link.component';

import { SpyScrollLinkDirective } from './directives/spy-scroll-link.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [SpyScrollComponent, SpyScrollLinkComponent, SpyScrollLinkDirective],
  exports: [SpyScrollComponent, SpyScrollLinkComponent, SpyScrollLinkDirective]
})
export class SpyScrollModule {}
