import { Component, OnInit, forwardRef, Input, Inject } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FILE_UPLOAD_CONFIG } from '../../file-upload-config.token';
import { IFileUploadConfig } from '../../model/file-upload';

@Component({
  selector: 'zet-dragdrop',
  templateUrl: './dragdrop.component.html',
  styleUrls: ['./dragdrop.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DragDropComponent),
      multi: true
    }
  ]
})
export class DragDropComponent implements OnInit, ControlValueAccessor {
  @Input() allowMultiple = true;

  @Input() acceptFolder = false;

  @Input() disabled: boolean;

  onChange: (files: FileList) => void;

  onTouch: (files: FileList) => void;

  inputFiles: FormControl = new FormControl();

  constructor(@Inject(FILE_UPLOAD_CONFIG) public fileUploadConfig: IFileUploadConfig) {}

  ngOnInit(): void {
    this.inputFiles.valueChanges.subscribe(files => {
      this.onChange(files);
    });
  }

  onFileDrop(files: FileList): void {
    this.onChange(files);
  }

  writeValue(): void {}

  registerOnChange(fn: (files: FileList) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (files: FileList) => void): void {
    this.onTouch = fn;
  }
}
