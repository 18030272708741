import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { PaginationControlsComponent } from './components/pagination-controls/pagination-contols.component';
import { DefaultPaginationControlsComponent } from './components/default-pagination-controls/default-pagination-controls.component';
import { BasePaginationControls } from './directives/base-pagination-control.directive';

import { PaginatePipe } from './pipes/paginate.pipe';
import { DropdownPaginationControlsComponent } from './components/dropdown-pagination-controls/dropdown-pagination-controls.component';

@NgModule({
  imports: [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule],
  declarations: [
    BasePaginationControls,
    PaginationControlsComponent,
    DefaultPaginationControlsComponent,
    DropdownPaginationControlsComponent,
    PaginatePipe
  ],
  exports: [
    PaginationControlsComponent,
    DefaultPaginationControlsComponent,
    DropdownPaginationControlsComponent,
    BasePaginationControls,
    PaginatePipe
  ]
})
export class PaginationModule {}
