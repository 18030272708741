<article class="widget widget-item">
  <header class="widget--header modal-header">
    <h2 *ngIf="!header">Add Items to {{ (transactionName$ | async) || "" }}</h2>
    <h2 *ngIf="header">{{ header }}</h2>
    <button type="button" (click)="onCancel()" class="btn btn-secondary">Cancel</button>
    <button type="button" (click)="onSubmit()" class="btn btn-primary">Submit</button>
  </header>

  <section class="widget-item--category">
    <label id="category-filter" for=""> Filter by Category </label>
    <zet-tree-select
      *ngIf="(isCategoryLoading$ | async) !== true; else categoryLoaderTemplate"
      class="tree"
      [dataSource]="categories$ | async"
      (select)="selectCategory($event)"
    ></zet-tree-select>

    <aside class="d-grid mt-2">
      <button type="button" name="" id="" class="btn btn-primary-outline btn-block link-external">
        View Item Group Structure

        <span class="material-icons">open_in_new</span>
      </button>
    </aside>
  </section>

  <section class="widget-item--filters">
    <article class="widget-item--filter searchbar form-group">
      <label for="">Search</label>
      <div class="input-group mb-3">
        <div *ngIf="!searchControl.value" class="input-group-prepend">
          <span class="input-group-text material-icons" id="basic-addon3">search</span>
        </div>
        <input
          type="text"
          class="form-control"
          id="basic-url"
          [formControl]="searchControl"
          placeholder="Search Item Code, Name, HSN/SAC Code"
          aria-describedby="basic-addon3"
        />
        <div *ngIf="searchControl.value && tabs[0].selected" class="input-group-append" (click)="clearSearch()">
          <span class="input-group-text material-icons" id="basic-addon3">close</span>
        </div>
      </div>
    </article>
    <article class="widget-item--filter">
      <label class="address-form--label" for="segment"> UOM </label>
      <zet-address-dropdown
        [items]="uoms$ | async"
        [formControl]="uomControl"
        [multiple]="true"
        placeholder="Select"
        [clearable]="true"
        [disabled]="tabs[1].selected"
        [checkbox]="true"
        [closeOnSelect]="false"
        id="UOM"
        name="UOM"
      ></zet-address-dropdown>
    </article>
    <article class="widget-item--filter">
      <label class="address-form--label" for="segment"> Product Type </label>
      <zet-address-dropdown
        [items]="productTypes$ | async"
        [formControl]="productTypeControl"
        placeholder="Select"
        [clearable]="true"
        [disabled]="tabs[1].selected"
        bindLabel="code"
        bindValue="name"
        id="productTypes"
      ></zet-address-dropdown>
    </article>
    <section class="meta">
      <dl>
        <dt *ngIf="searchText$ | async">Search result for: &nbsp;</dt>
        <dd *ngIf="searchText$ | async">"{{ searchText$ | async }}"</dd>
        <dt *ngIf="(parentCategory$ | async)">Selected &nbsp;</dt>
        <dd *ngIf="parentCategory$ | async">"{{ categoryLabel$ | async }}"</dd>
        <dt *ngIf="(uom$ | async)?.length || (productType$ | async)">Filtered by &nbsp;</dt>
        <dd *ngIf="(uom$ | async)?.length">"Item Group {{ (uom$ | async).join(", ") }}"</dd>
        <dd *ngIf="(uom$ | async)?.length && (productType$ | async)">&nbsp;&&nbsp;</dd>
        <dd *ngIf="productType$ | async">"Product Type {{ productType$ | async }}"</dd>
      </dl>
    </section>
  </section>

  <article class="widget-item--list" style="margin-top: -24px">
    <tabset class="nav-tabs-striped">
      <tab [heading]="tabs[0].label + ' (' + itemListMetaTotal + ')'" [active]="tabs[0].selected" (selectTab)="gotoLink(tabs[0])">
        <zet-item-list
          #itemList
          class="widget-item--list"
          [class.is-list-hidden]="
            (isListLoading$ | async) ||
            (emptyList$ | async) ||
            (noResults$ | async)
          "
          [mode]="tabs[0].listMode"
          [categoryType]="categoryType$ | async"
          [filters]="listFilters"
          [canSelectItem]="context?.canSelectItem"
          [allowSingleSelection]="context?.allowSingleSelection"
          [dataProvider]="dataProvider"
        >
        </zet-item-list>

        <ng-container *ngIf="(isListLoading$ | async)" [ngTemplateOutlet]="listLoaderTemplate"> </ng-container>

        <ng-container
          *ngIf="emptyList$ | async"
          [ngTemplateOutlet]="noResultTemplate"
          [ngTemplateOutletContext]="{
            title: 'No items in category',
            subtitle: 'Try adjusting search or use filter',
            src: '/assets/zetui/svg/empty.svg',
            buttonText: 'Clear All Filter'
          }"
        >
        </ng-container>

        <ng-container
          *ngIf="noResults$ | async"
          [ngTemplateOutlet]="noResultTemplate"
          [ngTemplateOutletContext]="{
            title: 'No Item matched',
            subtitle: 'Try adjusting search or use filter',
            src: '/assets/zetui/svg/no-item-found.svg',
            buttonText: 'Clear All Filter'
          }"
        >
        </ng-container>
      </tab>

      <tab
        [heading]="tabs[1].label + ' (' + (selectedItems$ | async)?.length + ')'"
        [active]="tabs[1].selected"
        (selectTab)="gotoLink(tabs[1])"
      >
        <button
          *ngIf="(unselectSelectedItems$ | async).length > 0"
          type="button"
          class="btn btn-secondary"
          (click)="removeItemsFromSelected()"
        >
          <span class="material-icons-outlined">delete</span>
        </button>
        <zet-item-list
          class="widget-item--list"
          [class.is-list-hidden]="(selectedItems$ | async)?.length === 0"
          [mode]="tabs[1].listMode"
          [dataProvider]="selectedDataProvider"
          [filters]="selectedItems$ | async"
          [categoryType]="categoryType$ | async"
        >
        </zet-item-list>
        <ng-container
          *ngIf="(selectedItems$ | async)?.length === 0"
          [ngTemplateOutlet]="noResultTemplate"
          [ngTemplateOutletContext]="{
            title: 'You have not added any Items yet!',
            subtitle: 'Start adding Items',
            src: '/assets/zetui/svg/no-selected-item.svg'
          }"
        >
        </ng-container>
      </tab>
    </tabset>
  </article>
</article>

<ng-template #noResultTemplate let-title="title" let-subtitle="subtitle" let-src="src" let-buttonText="buttonText">
  <article class="no-result">
    <section class="no-result--content">
      <img [src]="src" class="no-result--icon" alt="no-result" />
      <p class="no-result--title">{{ title }}</p>
      <p class="no-result--subtitle">{{ subtitle }}</p>
      <button *ngIf="buttonText" (click)="onEmptyBtnClick(buttonText)" type="button" class="zbtn zbtn-primary">{{ buttonText }}</button>
    </section>
  </article>
</ng-template>

<!--Category skeleton loader-->
<ng-template #categoryLoaderTemplate>
  <div class="tree skeleton-loader">
    <div class="skeleton-loader--item">
      <ngx-skeleton-loader
        count="16"
        appearance="line"
        animation="progress-dark"
        [theme]="{
          height: '12px',
          width: '24px',
          display: 'flex',
          'margin-bottom': '24px'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="16"
        appearance="line"
        animation="progress-dark"
        [theme]="{
          height: '12px',
          width: '110px',
          display: 'flex',
          'margin-bottom': '24px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>

<!--List skeleton loader-->
<ng-template #listLoaderTemplate>
  <div class="skeleton-loader">
    <div *ngFor="let item of [].constructor(6)" class="skeleton-loader--item skeleton-loader--list">
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        animation="progress-dark"
        [theme]="{ height: '12px', width: '16px', 'margin-bottom': '0px' }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        animation="progress-dark"
        [theme]="{ height: '12px', width: '100px', 'margin-bottom': '0px' }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        animation="progress-dark"
        [theme]="{ height: '12px', width: '256px', 'margin-bottom': '0px' }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        animation="progress-dark"
        [theme]="{ height: '12px', width: '256px', 'margin-bottom': '0px' }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        animation="progress-dark"
        [theme]="{ height: '12px', width: '60px', 'margin-bottom': '0px' }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        animation="progress-dark"
        [theme]="{ height: '12px', width: '60px', 'margin-bottom': '0px' }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="1"
        appearance="line"
        animation="progress-dark"
        [theme]="{ height: '12px', width: '76px', 'margin-bottom': '0px' }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>

<!--Filter skeleton loader-->
<ng-template #filterLoaderTemplate>
  <div class="skeleton-loader--item skeleton-loader--filter">
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      animation="progress-dark"
      [theme]="{ height: '12px', width: '140px', 'margin-bottom': '0px' }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      animation="progress-dark"
      [theme]="{ height: '12px', width: '24px', 'margin-bottom': '0px' }"
    ></ngx-skeleton-loader>
  </div>
</ng-template>

<!--Search skeleton loader-->
<ng-template #searchLoaderTemplate>
  <div class="skeleton-loader--item">
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      animation="progress-dark"
      [theme]="{ height: '12px', width: '256px', 'margin-bottom': '0px' }"
    ></ngx-skeleton-loader>
  </div>
</ng-template>
