<section class="form-container">
  <h4 *ngIf="title" class="form-container--title">{{ title }}</h4>
  <form class="widget-form" [class]="customClass" [formGroup]="addressForm">
    <div class="widget-form--group span-full">
      <label class="widget-form--label" for="addressName">Address name<span class="required"> * </span></label>
      <input
        class="widget-form--control widget-form--input"
        [class.widget-form--control-error]="nameControl.invalid && (nameControl.dirty || nameControl.touched)"
        [attr.disabled]="fieldToDisable?.name === true ? true : null"
        id="addressName"
        type="text"
        placeholder="Enter Name"
        formControlName="name"
      />
      <zet-address-validation-message
        *ngIf="nameControl.invalid && (nameControl.dirty || nameControl.touched)"
        [errorMessage]="validationMessages.name"
        [control]="nameControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group span-full">
      <label class="widget-form--label" for="address">Address<span class="required"> * </span></label>
      <textarea
        id="address"
        class="widget-form--control widget-form--textarea"
        [class.widget-form--control-error]="streetControl.invalid && (streetControl.dirty || streetControl.touched)"
        rows="3"
        [attr.disabled]="fieldToDisable?.street === true ? true : null"
        placeholder="Enter Address"
        formControlName="street"
      ></textarea>
      <zet-address-validation-message
        *ngIf="streetControl.invalid && (streetControl.dirty || streetControl.touched)"
        [errorMessage]="validationMessages.street"
        [control]="streetControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group span-full">
      <label class="widget-form--label" for="locationRoles">Address purpose<span class="required"> * </span></label>
      <zet-address-dropdown
        id="locationRoles"
        placeholder="Address purpose"
        [isError]="locationRolesControl.invalid && (locationRolesControl.dirty || locationRolesControl.touched)"
        [items]="locationRoleItems"
        [multiple]="true"
        [typeahead]="true"
        [disabled]="fieldToDisable?.locationRoles"
        [itemToDisable]="dropdownItemToDisable?.locationRoles"
        formControlName="locationRoles"
      ></zet-address-dropdown>
      <zet-address-validation-message
        *ngIf="locationRolesControl.invalid && (locationRolesControl.dirty || locationRolesControl.touched)"
        [errorMessage]="validationMessages.locationRoles"
        [control]="locationRolesControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group">
      <label class="widget-form--label" for="country">Country<span class="required"> * </span></label>
      <zet-address-dropdown
        id="country"
        placeholder="Country"
        [isError]="countryIdControl.invalid && (countryIdControl.dirty || countryIdControl.touched)"
        [items]="countryItems"
        [bindLabel]="'name'"
        [bindValue]="'_id'"
        [disabled]="fieldToDisable?.country"
        formControlName="countryId"
        (changeData)="onCountryChange($event)"
      ></zet-address-dropdown>
      <zet-address-validation-message
        *ngIf="countryIdControl.invalid && (countryIdControl.dirty || countryIdControl.touched)"
        [errorMessage]="validationMessages.country"
        [control]="countryIdControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group">
      <label class="widget-form--label" for="state">State<span class="required"> * </span></label>
      <zet-address-dropdown
        id="state"
        placeholder="State"
        [isError]="stateIdControl.invalid && (stateIdControl.dirty || stateIdControl.touched)"
        [items]="stateItems"
        [bindLabel]="'name'"
        [bindValue]="'_id'"
        [disabled]="fieldToDisable?.state"
        formControlName="stateId"
        (changeData)="onStateChange($event)"
      ></zet-address-dropdown>
      <zet-address-validation-message
        *ngIf="stateIdControl.invalid && (stateIdControl.dirty || stateIdControl.touched)"
        [errorMessage]="validationMessages.state"
        [control]="stateIdControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group">
      <label class="widget-form--label" for="city">City<span class="required"> * </span></label>
      <zet-address-dropdown
        id="city"
        placeholder="City"
        [isError]="cityIdControl.invalid && (cityIdControl.dirty || cityIdControl.touched)"
        [items]="cityItems"
        [bindLabel]="'name'"
        [bindValue]="'_id'"
        [typeahead]="true"
        [disabled]="fieldToDisable?.city"
        formControlName="cityId"
        (search)="getCities($event)"
      ></zet-address-dropdown>
      <zet-address-validation-message
        *ngIf="cityIdControl.invalid && (cityIdControl.dirty || cityIdControl.touched)"
        [errorMessage]="validationMessages.city"
        [control]="cityIdControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group">
      <label class="widget-form--label" for="pincode">Pincode<span class="required"> * </span></label>
      <input
        class="widget-form--control widget-form--input"
        [class.widget-form--control-error]="pincodeControl.invalid && (pincodeControl.dirty || pincodeControl.touched)"
        [attr.disabled]="fieldToDisable?.pincode === true ? true : null"
        id="pincode"
        type="text"
        placeholder="Pincode"
        formControlName="pincode"
      />
      <zet-address-validation-message
        *ngIf="pincodeControl.invalid && (pincodeControl.dirty || pincodeControl.touched)"
        [errorMessage]="validationMessages.pincode"
        [control]="pincodeControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group">
      <label class="widget-form--label" for="gstNumber">GST No.</label>
      <input
        class="widget-form--control widget-form--input"
        [class.widget-form--control-error]="gstNumberControl.invalid && (gstNumberControl.dirty || gstNumberControl.touched)"
        [attr.disabled]="fieldToDisable?.gstNumber === true ? true : null"
        id="gstNumber"
        type="text"
        placeholder="GST No."
        formControlName="gstNumber"
      />
      <zet-address-validation-message
        *ngIf="gstNumberControl.invalid && (gstNumberControl.dirty || gstNumberControl.touched)"
        [errorMessage]="validationMessages.gstNumber"
        [control]="gstNumberControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group">
      <label class="widget-form--label" for="epzCode">EPZ Code<span class="required"> * </span></label>
      <zet-address-dropdown
        id="epzCode"
        placeholder="EPZ Code"
        [isError]="epzCodeControl.invalid && (epzCodeControl.dirty || epzCodeControl.touched)"
        [items]="epzCodes"
        [disabled]="fieldToDisable?.epzCode"
        [itemToDisable]="dropdownItemToDisable?.epzCode"
        formControlName="epzCode"
      ></zet-address-dropdown>
      <zet-address-validation-message
        *ngIf="epzCodeControl.invalid && (epzCodeControl.dirty || epzCodeControl.touched)"
        [errorMessage]="validationMessages.epzCode"
        [control]="epzCodeControl"
      ></zet-address-validation-message>
    </div>

    <div class="widget-form--group span-full widget-form--group-inline">
      <input
        id="primary"
        class="widget-form--control widget-form--checkbox"
        [class.widget-form--control-error]="isPrimaryControl.invalid && (isPrimaryControl.dirty || isPrimaryControl.touched)"
        [attr.disabled]="fieldToDisable?.isPrimary === true ? true : null"
        type="checkbox"
        formControlName="isPrimary"
      />
      <label class="widget-form--label" for="primary"> Is this primary address ? </label>
      <zet-address-validation-message
        *ngIf="isPrimaryControl.invalid && (isPrimaryControl.dirty || isPrimaryControl.touched)"
        [errorMessage]="validationMessages?.isPrimary"
        [control]="isPrimaryControl"
      ></zet-address-validation-message>
    </div>
  </form>
</section>
