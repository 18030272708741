// eslint-disable-next-line max-classes-per-file
import { Component, Input, Output } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AddressWidgetComponent } from '../address';
import { FinancialDimensionWidgetComponent } from '../financial-dimension';
import { WarehouseWidgetComponent } from '../warehouse';
import { ItemMasterWidgetComponent } from '../item-master';
import { UploadMasterWidgetComponent } from '../upload-master/components/upload-master-widget/upload-master-widget.component';
import { WidgetData, WidgetEvent, WidgetEventData, WidgetEventType, WidgetModalOptions, WidgetMode, WidgetType } from '../widget.model';
import { BankAccountMasterWidgetComponent } from '../bank-master';

@Component({
  selector: 'zet-widget-btn',
  templateUrl: './widget-btn.component.html',
  styleUrls: ['./widget-btn.component.scss']
})
export class WidgetBtnComponent {
  @Input() widgetId: string;

  @Input() type: WidgetType | string;

  @Input() title: string;

  @Input() btnClass = 'btn btn-primary';

  @Input() modalOptions?: WidgetModalOptions;

  @Input() customClass?: string;

  @Input() header?: string;

  @Input() data: WidgetData;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: Subject<WidgetEvent> = new Subject();

  @Output() done: Subject<WidgetEvent> = new Subject();

  @Output() cancel: Subject<WidgetEvent> = new Subject();

  get _modalOptions(): WidgetModalOptions {
    const modalClass = this.modalClass;
    const options = {
      ...{
        class: modalClass || 'modal-centered widget',
        ignoreBackdropClick: this.type === WidgetType.ITEM_MASTER,
        animated: true,
        keyboard: false,
        initialState: {
          widgetId: this.widgetId,
          header: this.header,
          context: this.data,
          mode: WidgetMode.MODAL,
          ...this.data
        }
      },
      ...this.modalOptions
    };

    return options;
  }

  get modalClass(): string {
    let modalClass = 'widget ';
    switch (this.type) {
      case WidgetType.ADDRESS:
        modalClass = modalClass.concat('address-widget');
        return modalClass;
      case WidgetType.ITEM_MASTER:
        modalClass = modalClass.concat('modal-centered modal-full-width widget-slide-in item-master');
        return modalClass;
      case WidgetType.FINANCIAL_DIMENSION:
        modalClass = modalClass.concat('financial-widget');
        return modalClass;
      case WidgetType.WAREHOUSE:
        modalClass = modalClass.concat('warehouse');
        return modalClass;
      case WidgetType.UPLOAD_MASTER:
        return modalClass;
      case WidgetType.BANK_ACCOUNT_MASTER:
        modalClass = modalClass.concat('widget-bank-account');
        return modalClass;
      default:
        return modalClass;
    }
  }

  get displayTitle(): string {
    if (this.title) {
      return this.title;
    }

    switch (this.type) {
      case WidgetType.ADDRESS:
        return 'Add Address';
        break;
      case WidgetType.ITEM_MASTER:
        return 'Add Items';
        break;
      case WidgetType.FINANCIAL_DIMENSION:
        return 'Add Financial Dimension';
        break;
      case WidgetType.WAREHOUSE:
        return 'Add Warehouse';
      case WidgetType.UPLOAD_MASTER:
        return 'Upload Master';
        break;
      default:
        return '';
        break;
    }
  }

  constructor(private modal: BsModalService) {}

  openModal(): void {
    let componentRef;
    switch (this.type) {
      case WidgetType.ADDRESS:
        componentRef = AddressWidgetComponent;
        break;
      case WidgetType.ITEM_MASTER:
        componentRef = ItemMasterWidgetComponent;
        break;
      case WidgetType.FINANCIAL_DIMENSION:
        componentRef = FinancialDimensionWidgetComponent;
        break;
      case WidgetType.WAREHOUSE:
        componentRef = WarehouseWidgetComponent;
        break;
      case WidgetType.UPLOAD_MASTER:
        componentRef = UploadMasterWidgetComponent;
        break;
      case WidgetType.BANK_ACCOUNT_MASTER:
        componentRef = BankAccountMasterWidgetComponent;
        break;
      default:
        break;
    }

    const modalRef = this.modal.show(componentRef, this._modalOptions as ModalOptions<any>);
    modalRef.content.change.subscribe((eventData: WidgetEventData) => {
      this.change.next({
        type: WidgetEventType.CHANGE,
        data: eventData,
        modalRef
      });
    });

    modalRef.content.done.subscribe((eventData: WidgetEventData) => {
      this.done.next({
        type: WidgetEventType.DONE,
        data: eventData,
        modalRef
      });

      this.change.next({
        type: WidgetEventType.DONE,
        data: eventData,
        modalRef
      });

      modalRef.hide();
    });

    modalRef.content.cancel.subscribe((eventData: WidgetEventData) => {
      this.cancel.next({
        type: WidgetEventType.CANCEL,
        data: eventData,
        modalRef
      });

      this.change.next({
        type: WidgetEventType.CANCEL,
        data: eventData,
        modalRef
      });

      modalRef.hide();
    });
  }
}
