import { HttpClient } from '@angular/common/http';
import { Component, forwardRef, Inject, Input, OnChanges, OnInit, TemplateRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subject, timer } from 'rxjs';
import { debounceTime, map, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BrowserEnvConfig, browserEnvProviders, BrowserEnvToken } from '../../browserEnvConfig';
import { Widget } from '../../widget';
import { WidgetMode } from '../../widget.model';
import { AccountScope, FormMode, IBankAccountMaster, validationMessage, IBankAccountFieldToDisable } from '../models/bank-master';
import { BANK_ACCOUNT_TYPES } from '@zetwerk/zet-erp-constants';

@Component({
  selector: 'zet-bank-account-master-widget',
  templateUrl: './bank-account-master-widget.component.html',
  styleUrls: ['./bank-account-master-widget.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BankAccountMasterWidgetComponent),
      multi: true
    },
    ...browserEnvProviders
  ]
})
export class BankAccountMasterWidgetComponent
  extends Widget<Partial<BankAccountMasterWidgetComponent>, IBankAccountMaster>
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() customClass = '';

  @Input() header: string;

  @Input() mode: WidgetMode = WidgetMode.FORM;

  @Input() headerTemplate: TemplateRef<any>;

  @Input() accountScope: AccountScope = AccountScope.DOMESTIC;

  @Input() bankAccount: IBankAccountMaster;

  @Input() formMode: FormMode = FormMode.ADD;

  @Input() fieldToDisable: IBankAccountFieldToDisable = {};

  public bankMasterForm: FormGroup;

  public validationMessage = validationMessage;

  private stopPolling$ = new Subject();

  public isIFSCCodeLoading = false;

  public accountType = [...BANK_ACCOUNT_TYPES];

  public onChange: (data: any) => void;

  public onTouch: () => void;

  currencyCodes = [];

  constructor(@Inject(BrowserEnvToken) private apis: BrowserEnvConfig[], private fb: FormBuilder, private http: HttpClient) {
    super();
  }

  ngOnInit(): void {
    this.getCurrency();

    this.bankMasterForm = this.initBankMasterForm();

    this.ifscCodeControl.valueChanges.pipe(debounceTime(400)).subscribe(value => {
      if (value.length === 11) {
        this.verifyifscCode(value);
      }
    });

    if (this.mode === WidgetMode.FORM) {
      this.bankMasterForm.valueChanges.subscribe((bankAccount: IBankAccountMaster) => {
        if (this.onChange) {
          this.onChange(bankAccount);
        }
      });
    }

    if (this.formMode === FormMode.VIEW) {
      this.bankMasterForm.disable({ emitEvent: true });
    }
  }

  ngOnChanges(): void {
    if (this.formMode === FormMode.VIEW) {
      this.bankMasterForm.disable({ emitEvent: true });
    } else {
      this.bankMasterForm.enable();
    }
  }

  get accountNoControl(): AbstractControl {
    return this.bankMasterForm.controls.accountNo;
  }

  get reAccountNoControl(): AbstractControl {
    return this.bankMasterForm.controls.reAccountNo;
  }

  get ifscCodeControl(): AbstractControl {
    return this.bankMasterForm.controls.ifscCode;
  }

  get bankNameControl(): AbstractControl {
    return this.bankMasterForm.controls.bankName;
  }

  get swiftCodeControl(): AbstractControl {
    return this.bankMasterForm.controls.swiftCode;
  }

  get currencyCodeControl(): AbstractControl {
    return this.bankMasterForm.controls.currencyCode;
  }

  get accountTypeControl(): AbstractControl {
    return this.bankMasterForm.controls.accountType;
  }

  get contactNameControl(): AbstractControl {
    return this.bankMasterForm.controls.contactName;
  }

  get accountVerifiedControl(): AbstractControl {
    return this.bankMasterForm.controls.accountVerified;
  }

  public writeValue(obj: IBankAccountMaster): void {
    this.patchBankMasterForm(obj);
  }

  public registerOnChange(fn: (data: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public initBankMasterForm(): FormGroup {
    return this.fb.group(
      {
        bankAccountId: [this.bankAccount?.bankAccountId || null],
        contactName: [this.bankAccount?.contactName || null, Validators.compose([Validators.required])],
        accountNo: [this.bankAccount?.accountNo || '', Validators.compose([Validators.required])],
        reAccountNo: [this.bankAccount?.reAccountNo || '', Validators.compose([Validators.required])],
        ifscCode: [
          this.bankAccount?.ifscCode || '',
          Validators.compose([
            Validators.pattern('^[A-Z]{4}0[A-Z0-9]{6}$'),
            this.accountScope !== AccountScope.INTERNATIONAL ? Validators.required : null
          ])
        ],
        bankName: [this.bankAccount?.bankName || '', Validators.compose([Validators.required])],
        swiftCode: [
          this.bankAccount?.swiftCode || '',
          Validators.compose([
            Validators.pattern('^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$'),
            this.accountScope === AccountScope.INTERNATIONAL ? Validators.required : null
          ])
        ],
        currencyCode: [this.bankAccount?.currencyCode || null, Validators.compose([Validators.required])],
        accountVerified: [this.accountScope !== AccountScope.INTERNATIONAL],
        accountScope: [this.accountScope || AccountScope.DOMESTIC],
        accountType: [this.bankAccount?.accountType || null, Validators.compose([Validators.required])]
      },
      {
        validators: this.password.bind(this)
      }
    );
  }

  public patchBankMasterForm(bankAccount: IBankAccountMaster): void {
    this.bankMasterForm.patchValue({
      bankAccountId: bankAccount?.bankAccountId || null,
      contactName: bankAccount?.contactName || null,
      accountNo: bankAccount.accountNo || '',
      reAccountNo: bankAccount.reAccountNo || '',
      ifscCode: bankAccount.ifscCode || '',
      bankName: bankAccount.bankName || '',
      swiftCode: bankAccount.swiftCode || '',
      currencyCode: bankAccount.currencyCode || null,
      accountVerified: bankAccount.accountVerified || this.accountScope !== AccountScope.INTERNATIONAL,
      accountScope: bankAccount.accountScope || AccountScope.DOMESTIC,
      accountType: bankAccount.accountType || null
    });
  }

  public async verifyaccountNo(): Promise<void> {
    const bankAccountNumber = this.accountNoControl.value;
    const bankIfscCode = this.ifscCodeControl.value;

    const res: any = await this.http
      .post(`${this.apis[0].baseUrl}bank-account/verify`, {
        bankAccountNumber,
        bankIfscCode,
        accountType: this.accountScope
      })
      .toPromise();

    if (res.status === 200 && bankAccountNumber.length > 0) {
      const requestId = res?.data?.requestId;

      timer(1, 5000)
        .pipe(
          switchMap(() => this.http.get(`${this.apis[0].baseUrl}bank-account/verification-status/${requestId}`)),
          map((response: any) => response.data),
          tap(data => {
            const detail = data[0];

            if (detail?.status === 'failed' || detail?.status === 'completed') {
              this.isIFSCCodeLoading = false;
              this.stopPolling$.next();
              if (detail?.error === 'BAD_REQUEST') {
                this.accountNoControl.setErrors({ accountNoNotExist: true });
                this.contactNameControl.setValue(null);
                this.accountVerifiedControl.setValue(false);
              }
            }

            if (detail?.status === 'completed') {
              if (detail?.result?.account_exists === 'NO') {
                this.accountNoControl.setErrors({ accountNoNotExist: true });
                this.contactNameControl.setValue(null);
                this.accountVerifiedControl.setValue(false);
                return;
              }
              const contactName = detail?.result?.name_at_bank || null;
              this.contactNameControl.setValue(contactName);
              this.accountVerifiedControl.setValue(true);
            }
          }),
          share(),
          takeUntil(this.stopPolling$)
        )
        .toPromise();
    }
  }

  public async verifyifscCode(ifscCode: string): Promise<void> {
    if (!ifscCode || ifscCode?.length === 0) {
      return;
    }

    this.isIFSCCodeLoading = true;
    try {
      const res: any = await this.http.get(`${this.apis[0].baseUrl}bank-account/ifsc/${ifscCode}`).toPromise();

      if (res.status === 200) {
        const { BANK } = res.data;
        this.bankNameControl.setValue(BANK);

        this.verifyaccountNo();
      }
    } catch (error) {
      this.isIFSCCodeLoading = false;
      this.ifscCodeControl.setErrors({ ifscCodeNotExist: true });
    }
  }

  public async getCurrency(): Promise<void> {
    const res: any = await this.http.get(`${this.apis[1].baseUrl}currency`).toPromise();

    if (res.success) {
      this.currencyCodes = res.data;
    }
  }

  public isValidForm(): boolean {
    if (this.bankMasterForm.valid || this.bankAccount) {
      return true;
    }

    Object.keys(this.bankMasterForm.controls).forEach(field => {
      const control = this.bankMasterForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });

    return false;
  }

  public save(): void {
    if (this.isValidForm()) {
      const bankDetail = this.bankMasterForm.getRawValue();
      this.done.next(bankDetail);
    }
  }

  public password(formGroup: FormGroup): void | null {
    const accountNoControl = formGroup.get('accountNo');
    const reAccountNoControl = formGroup.get('reAccountNo');

    if (!reAccountNoControl?.value?.length) {
      return null;
    }

    if (accountNoControl?.value === reAccountNoControl?.value) {
      reAccountNoControl.setErrors(null);
    }

    return accountNoControl?.value === reAccountNoControl?.value ? null : reAccountNoControl.setErrors({ accountNotMatch: true });
  }
}
