<section class="sidemenu--container">
  <div class="sidemenu--wrapper">
    <ng-content select="zet-sidemenu"></ng-content>
  </div>
  <div
    class="sidemenu--content"
    [ngClass]="[(isOpen$ | async) ? 'sidemenu--content-is-collapsed' : 'sidemenu--content-is-expanded', 'sidemenu--content-' + mode]"
  >
    <ng-content select="zet-sidemenu-content"></ng-content>
  </div>
</section>
<div *ngIf="hasBackdrop" class="backdrop" [class.backdrop-is-active]="isOpen$ | async" (click)="onBackdropClick()"></div>
