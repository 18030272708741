import { Component, OnDestroy, OnInit } from '@angular/core';
import { ISidemenuItem, SidemenuMode } from 'projects/zetui/src/components/sidemenu';
import { Subscription } from 'rxjs';
import { HelperService } from '../../@core/services/helper.service';
import { AppService } from '../../@core/services/app.service';

@Component({
  selector: 'zet-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  sidemenuMode: SidemenuMode = SidemenuMode.SIDE;

  pageHeader: string;

  sidemenuItems: ISidemenuItem[] = [
    {
      name: 'Basics',
      title: 'Basics',
      path: '/basics',
      selected: false,
      icons: {
        selected: '/assets/svg/basic.svg',
        unselected: '/assets/svg/basic-unselected.svg',
        default: '/assets/svg/lot/Unselected.svg'
      }
    },
    {
      name: 'Components',
      title: 'Components',
      path: '/components',
      selected: false,
      icons: {
        selected: '/assets/svg/folder.svg',
        unselected: '/assets/svg/folder-unselected.svg',
        default: '/assets/svg/icon-folder.svg'
      }
    },
    {
      name: 'Widgets',
      title: 'Widgets',
      path: '/widgets',
      selected: false,
      icons: {
        selected: '/assets/svg/folder.svg',
        unselected: '/assets/svg/folder-unselected.svg',
        default: '/assets/svg/icon-folder.svg'
      }
    },
    {
      name: 'Features',
      title: 'Features',
      path: '/features',
      selected: false,
      icons: {
        selected: '/assets/svg/folder.svg',
        unselected: '/assets/svg/folder-unselected.svg',
        default: '/assets/svg/icon-folder.svg'
      }
    },
    {
      name: 'Libraries',
      title: 'Libraries',
      path: '/libraries',
      selected: false,
      icons: {
        selected: '/assets/svg/lot/Selected.svg',
        unselected: '/assets/svg/lot/Unselected.svg',
        default: '/assets/svg/lot/Unselected.svg'
      }
    },
    {
      name: 'Utils',
      title: 'Utils',
      path: '/utils',
      selected: false,
      icons: {
        selected: '/assets/svg/lot/Selected.svg',
        unselected: '/assets/svg/lot/Unselected.svg',
        default: '/assets/svg/lot/Unselected.svg'
      }
    },
    {
      name: 'Icon Library',
      title: 'Icons',
      path: '/icon-library',
      selected: false,
      icons: {
        selected: '/assets/svg/lot/Selected.svg',
        unselected: '/assets/svg/lot/Unselected.svg',
        default: '/assets/svg/lot/Unselected.svg'
      }
    },
    {
      name: 'Showcase',
      title: 'Showcase',
      path: '/showcase',
      selected: false,
      icons: {
        selected: '/assets/svg/overview/Selected.svg',
        unselected: '/assets/svg/overview/Unselected.svg',
        default: '/assets/svg/lot/Unselected.svg'
      }
    },
    {
      name: 'Contributions',
      title: 'Contributions',
      path: '/contributions',
      selected: false,
      icons: {
        selected: '/assets/svg/overview/Selected.svg',
        unselected: '/assets/svg/overview/Unselected.svg',
        default: '/assets/svg/lot/Unselected.svg'
      }
    }
  ];

  hasBackdrop = false;

  isOpen = false;

  private subscriptions: Subscription = new Subscription();

  constructor(private appService: AppService, private helperService: HelperService) {
    this.appService.modeChange$.subscribe((val: SidemenuMode) => {
      this.sidemenuMode = SidemenuMode[val];
    });
    this.appService.backdropChange$.subscribe((val: boolean) => {
      this.hasBackdrop = val;
    });
  }

  ngOnInit(): void {
    const pageHeaderSubscription = this.helperService.pageHeader$.subscribe((title: string) => {
      this.pageHeader = title;
    });

    this.subscriptions.add(pageHeaderSubscription);
  }

  toggle(value: boolean): void {
    this.isOpen = value;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
