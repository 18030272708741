<ng-container *ngFor="let group of groups; let index=index">
  <zet-file-upload-list
    [attachmentCard]="attachmentCard"
    [group]="group"
    [type]="type"
    [files]="(files$ | async) | filterOnKey: 'groupId': group.id"
    [acceptFolder]="acceptFolder"
    [customClass]="customClass"
    [disabled]="disabled"
    [showDragnDrop]="showDragnDrop"
    (retry)="retry($event)"
    (cancel)="cancel($event)"
    (delete)="delete($event)"
    (view)="onView($event)"
    (fileDropped)="fileDropped($event)"
  >
    <ng-content select=".upload-btn-left" ngProjectAs=".upload-btn-left"></ng-content>
    <ng-content select=".upload-btn-right" ngProjectAs=".upload-btn-right"></ng-content>
    <ng-container ngProjectAs="[uploadGroupHeader]" *ngTemplateOutlet="getGroupComponent(index)?.fileGroupHeader"></ng-container>
  </zet-file-upload-list>
</ng-container>
<div>
  <ng-content select=".fs--upload-content"></ng-content>
</div>
