import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetBtnComponent } from './widget-btn/widget-btn.component';
import { AddressModule } from './address';
import { FinancialDimensionModule } from './financial-dimension';
import { ItemMasterModule } from './item-master';
import { UploadMasterModule } from './upload-master';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { BankMasterModule } from './bank-master';

@NgModule({
  imports: [CommonModule, AddressModule, FinancialDimensionModule, ItemMasterModule, UploadMasterModule, ModalModule, BankMasterModule],
  declarations: [WidgetBtnComponent],
  exports: [WidgetBtnComponent],
  providers: [BsModalService]
})
export class WidgetModule {}
