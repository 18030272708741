<ng-container [ngSwitch]="mode">
  <article class="agenda-job-card" *ngSwitchCase="refreshTypes.POLL">
    <ng-container *ngTemplateOutlet="jobStatusTemplate"></ng-container>
  </article>

  <article class="agenda-job-card" *ngSwitchCase="refreshTypes.REFRESH">
    <ng-container *ngTemplateOutlet="jobStatusTemplate"></ng-container>
    <button
      type="button"
      (click)="getJobStatus()"
      *ngIf="(job$ | async)?.status === jobStatuses.Started || (job$ | async)?.status === jobStatuses.Running"
      class="btn btn-secondary btn-refresh"
      [tooltip]="refreshToolTip"
      [isOpen]="true"
      [tooltipIsOpen]="true"
      [tooltipAppendToBody]="true"
      [placement]="'bottom'"
    >
      <span class="material-icons">refresh</span>
    </button>
  </article>

  <article class="agenda-job-card" *ngSwitchCase="refreshTypes.SSE">
    <ng-container *ngTemplateOutlet="jobStatusTemplate"></ng-container>
  </article>
  <article *ngSwitchDefault></article>
</ng-container>

<ng-template #jobStatusTemplate>
  <ng-container [ngSwitch]="(job$ | async)?.status">
    <ng-container *ngSwitchCase="jobStatuses.Started">
      <ng-container *ngTemplateOutlet="jobStartedTemplate || jobRunningTemplate; context: { job: (job$ | async) }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="jobStatuses.Running">
      <ng-container *ngTemplateOutlet="jobRunningTemplate; context: { job: (job$ | async) }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="jobStatuses.Success">
      <ng-container *ngTemplateOutlet="jobSuccessTemplate; context: { job: (job$ | async) }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="jobStatuses.Fail">
      <ng-container *ngTemplateOutlet="jobErrorTemplate; context: { job: (job$ | async) }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault></ng-container>
  </ng-container>
</ng-template>
