<article class="upload--section" [class]="customClass">
  <div class="upload--dragdrop" *ngIf="type !== uploadFileType.GROUP_UPLOAD">
    <zet-dragdrop
      *ngIf="isDragDropVisible"
      [allowMultiple]="type === uploadFileType.MULTIPLE_UPLOAD"
      [acceptFolder]="acceptFolder"
      [disabled]="disabled"
      [formControl]="fileControl"
    ></zet-dragdrop>
    <ng-container *ngIf="!isDragDropVisible">
      <ng-content select=".upload-btn-left"></ng-content>
      <label
        [attr.for]="group.id"
        [ngClass]="group?.actionBtn?.btnClass ? group?.actionBtn?.btnClass: 'zbtn-primary'"
        class="upload--select-btn"
        [class.upload--select-btn--disabled]="disabled"
        >{{ group?.actionBtn?.label || 'Select file' }}</label
      >
      <input
        type="file"
        [attr.id]="group.id"
        zetFileInput
        [formControl]="fileControl"
        [multiple]="group?.actionBtn?.multiple"
        [attr.disabled]="disabled ? '' : null"
      />
      <ng-content select=".upload-btn-right"></ng-content>
    </ng-container>
    <span class="upload--info" *ngIf="completedFiles?.length > 1">{{ completedFiles?.length }} of {{ files?.length }} files uploaded</span>
  </div>

  <article class="upload--select" *ngIf="type === uploadFileType.GROUP_UPLOAD">
    <h1 *ngIf="group?.title">
      <ng-content select="[uploadGroupHeader]"></ng-content>
    </h1>
    <section class="upload--select-btns">
      <ng-template [ngIf]="group?.actionBtn">
        <label
          [attr.for]="group.id"
          class="upload--select-btn"
          [ngClass]="group?.actionBtn?.btnClass ? group?.actionBtn?.btnClass: 'zbtn-primary'"
          >{{ group?.actionBtn?.label }}</label
        >
        <input
          type="file"
          [attr.id]="group.id"
          zetFileInput
          [formControl]="fileControl"
          [multiple]="group?.actionBtn?.multiple"
          [attr.disabled]="disabled ? '' : null"
        />
      </ng-template>
      <ng-template [ngIf]="group?.userBtn">
        <button
          type="button"
          class="upload--select-btn"
          [ngClass]="group?.actionBtn?.btnClass ? group?.actionBtn?.btnClass: 'zbtn-primary'"
        >
          {{ group?.userBtn?.label }}
        </button>
      </ng-template>
    </section>
  </article>

  <!-- Attachment card DOM-->
  <ul class="upload--files">
    <li *ngFor="let file of files; let index=index">
      <ng-container
        [ngIf]="attachmentCard"
        *ngTemplateOutlet="attachmentCard;context:{file: file, retry: _retry, cancel: _cancel, delete: _delete, view: _view}"
      ></ng-container>
      <zet-file-progress-card
        *ngIf="!attachmentCard"
        [file]="file"
        (retry)="onRetry($event)"
        (cancel)="onCancel($event)"
        (delete)="onDelete($event)"
        (view)="onView($event)"
      ></zet-file-progress-card>
    </li>
  </ul>
</article>
