import * as THREE from 'three';

export const threedViewerDefaults = {
  material: new THREE.MeshPhongMaterial({
    color: 0x5c98bd,
    side: THREE.DoubleSide,
    clipShadows: true
  }),
  lightPositions: [new THREE.Vector3(150, 150, 50), new THREE.Vector3(-150, -150, -50)],
  cameraPosition: new THREE.Vector3(0, 0, 100)
};
