export interface IWarehouseWidgetData {
  config?: DimensionConfig;
  companyId: string;
  isSiteDisabled?: boolean;
  isWarehouseDisabled?: boolean;
  filters?: IWarehouseWidgetFilter;
}

export interface IWarehouseEventData {
  site: {
    id: string;
    name: string;
    siteId: string;
    uniqueCode: string;
  };
  warehouse: {
    id: string;
    name: string;
    siteId: string;
    type: string;
    uniqueCode: string;
    warehouseCode: string;
    address: any;
    company: any;
    vendorCode: string;
  };
}

export interface DimensionConfig {
  baseUrl?: string;
  absoluteUrl?: string;
  environment?: 'staging' | 'uat' | 'production' | 'local';
}

export const validationMessages = {
  site: {
    required: 'Site is required'
  },
  warehouse: {
    required: 'Warehouse is required'
  }
};

export interface IWarehouseWidgetFilter {
  siteName?: string;
  category?: string;
  legalEntityCode?: string;
  warehouseIds?: any;
  warehouseName?: string;
}
