import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface LoaderState {
  loading: boolean;
}

export function createInitialState(): LoaderState {
  return {
    loading: false
  };
}

@StoreConfig({ name: 'loader' })
@Injectable({ providedIn: 'root' })
export class LoaderStore extends Store<LoaderState> {
  constructor() {
    super(createInitialState());
  }
}
