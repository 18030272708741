import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'zet-address-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss']
})
export class ValidationMessageComponent {
  @Input() control: AbstractControl;

  @Input() errorMessage;

  get error(): string {
    const fieldControl = this.control;
    let message: string;

    Object.keys(fieldControl.errors).forEach(error => {
      if (Object.prototype.hasOwnProperty.call(fieldControl?.errors, error)) {
        if (typeof fieldControl.errors?.error === 'object' && Object.keys(fieldControl.errors?.error).length > 0) {
          if (fieldControl.errors[error].message) {
            message = fieldControl.errors[error].message;
          }
        }

        if (this.errorMessage?.[error]) {
          message = this.errorMessage?.[error];
        }
      }
    });

    return message;
  }
}
