import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BasePaginationControls } from '../../directives/base-pagination-control.directive';

@Component({
  selector: 'zet-default-pagination-controls',
  templateUrl: './default-pagination-controls.component.html',
  styleUrls: ['./default-pagination-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultPaginationControlsComponent extends BasePaginationControls {
  trackByIndex(index: number): number {
    return index;
  }
}
