import { Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { pollWhile } from '../../../../../utils/poll-while/poll-while';
import { Observable, Subject, timer } from 'rxjs';
import { share, switchMap, takeUntil } from 'rxjs/operators';
import { App, browserEnvProviders } from '../../../browserEnvConfig';
import { Widget } from '../../../widget';
import {
  AgendaJob,
  IServerFile,
  JobStatus,
  JobStatusRefreshType,
  JobType,
  PollingOptions,
  RefreshOptions,
  ServerSideOptions
} from '../../models/agenda-job';
import { AgendaJobQuery } from '../../services/agenda-job.query';
import { AgendaJobService } from '../../services/agenda-job.service';
import { AgendaJobStore } from '../../services/agenda-job.store';

@Component({
  selector: 'zet-agenda-job-status',
  templateUrl: './agenda-job-status.component.html',
  styleUrls: ['./agenda-job-status.component.scss'],
  providers: [...browserEnvProviders, AgendaJobStore, AgendaJobQuery, AgendaJobService]
})
export class AgendaJobStatusComponent extends Widget<Partial<AgendaJobStatusComponent>, boolean> implements OnInit, OnChanges {
  @Input() app: App;

  @Input() name: AgendaJob['name'];

  @Input() taskId: AgendaJob['_id'];

  @Input() type: JobType;

  jobTypes = JobType;

  @Input() refreshToolTip = 'The request is being processed. Kindly refresh to update the status';

  @Input() mode: JobStatusRefreshType;

  @Input() uploadedFile?: IServerFile;

  @Input() options: PollingOptions | RefreshOptions | ServerSideOptions;

  job$: Observable<Partial<AgendaJob>>;

  refreshTypes = JobStatusRefreshType;

  jobStatuses = JobStatus;

  @ContentChild('jobRunningTemplate') jobRunningTemplate: TemplateRef<any>;

  @ContentChild('jobSuccessTemplate') jobSuccessTemplate: TemplateRef<any>;

  @ContentChild('jobStartedTemplate') jobStartedTemplate: TemplateRef<any>;

  @ContentChild('jobErrorTemplate') jobErrorTemplate: TemplateRef<any>;

  constructor(private agendaJob: AgendaJobService, private query: AgendaJobQuery) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.agendaJob.init(this as any);
    this.job$ = this.query.job$;

    if (this.mode === JobStatusRefreshType.POLL) {
      const { pollInterval, isPollingActive, maxAttempts, emitOnlyLast } = this.options;
      await this.agendaJob
        .getJobStatus(this.app, this.taskId)
        .pipe(pollWhile(pollInterval, isPollingActive, maxAttempts, emitOnlyLast))
        .toPromise();
    } else {
      this.getJobStatus();
    }

    this.job$.subscribe((job: any) => {
      if (job.status === 'Success' || job.status === 'Fail') {
        this.done.next(job);
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.taskId.currentValue !== changes.taskId.previousValue && !changes.taskId.firstChange) {
      if (this.mode === JobStatusRefreshType.POLL) {
        const { pollInterval, isPollingActive, maxAttempts, emitOnlyLast } = this.options;
        await this.agendaJob
          .getJobStatus(this.app, this.taskId)
          .pipe(pollWhile(pollInterval, isPollingActive, maxAttempts, emitOnlyLast))
          .toPromise();
      } else {
        this.getJobStatus();
      }
    }
  }

  async getJobStatus(): Promise<void> {
    await this.agendaJob.getJobStatus(this.app, this.taskId).toPromise();
  }
}
