import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperModule } from '../../stepper';
import { UploadMasterWidgetComponent } from './components/upload-master-widget/upload-master-widget.component';
import { AddressModule } from '../address';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from '../../file-upload';
import { AgendaJobStatusModule } from '../agenda-job-status';
import { DownloadMasterWidgetComponent } from './components/download-master-widget/download-master-widget.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, StepperModule, AddressModule, FileUploadModule, AgendaJobStatusModule],
  declarations: [UploadMasterWidgetComponent, DownloadMasterWidgetComponent],
  exports: [UploadMasterWidgetComponent, DownloadMasterWidgetComponent]
})
export class UploadMasterModule {}
