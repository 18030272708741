<div class="spinner spinner-progress">
  <svg id="svg" width="36" height="36" viewPort="0 0 36 36">
    <circle
      r="9"
      cx="20"
      cy="20"
      [style.transition]="returnTransition()"
      fill="transparent"
      stroke-dasharray="60.65"
      stroke-dashoffset="0"
    ></circle>
    <circle
      [style.strokeDashoffset]="dashOffset"
      [style.transition]="returnTransition()"
      id="bar"
      r="9"
      cx="20"
      cy="20"
      fill="transparent"
      stroke-dasharray="60.65"
      stroke-dashoffset="0"
      transform="rotate(270 20 20)"
    ></circle>
  </svg>
</div>
