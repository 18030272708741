const firebaseConfig = {
  apiKey: 'AIzaSyBW4K5UWSLo3OBzjyYA7kR8N0i7yjczSnk',
  authDomain: 'zetui-demo.firebaseapp.com',
  projectId: 'zetui-demo',
  storageBucket: 'zetui-demo.appspot.com',
  messagingSenderId: '591577651915',
  appId: '1:591577651915:web:1cd0e9e1c0a3246904194e',
  measurementId: 'G-EDLRTNCG1B'
};

export const environment = {
  production: true,
  configuration: 'production',
  firebaseConfig,
  googleClientId: '533773529183-97ufurmdfs5q12phba0ksssqt6g8maqa.apps.googleusercontent.com',
  baseEndPoint: 'https://api.zetwerk.com/',
  versionCheckURL: 'https://platform.zetwerk.com/assets/version.json',
  versionCheckFrequency: 1800000, // In Milliseconds (1000*60*30ms = 30 Minutes)
  authApiEndPoint: 'https://api.zetwerk.com/v1/'
};
