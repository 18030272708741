<div class="modal-header">
  <h4 class="modal-title">{{ header }}</h4>
  <button type="button" class="zbtn-close" aria-label="Close" (click)="cancel.next()">
    <span class="material-icons">close</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngTemplateOutlet="oldAddressTemplate"></ng-container>
  <zet-address-form
    [address]="address"
    [fieldToDisable]="fieldToDisable"
    [dropdownItemToDisable]="dropdownItemToDisable"
    [syncAddressFromGST]="syncAddressFromGST"
    [customClass]="customClass"
    [formControl]="addressFormControl"
  ></zet-address-form>
</div>
<div class="modal-footer">
  <div class="form-container--buttons">
    <button type="button" class="zbtn-secondary" (click)="cancel.next()">Cancel</button>
    <button type="button" class="zbtn-primary" (click)="save()">Save</button>
  </div>
</div>
