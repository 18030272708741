import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ILoginConfig, LoginType } from './login.model';

export interface LoginState {
  loginConfig: ILoginConfig;
  ui?: {
    background?: { [key: string]: any };
    logo?: string;
    title?: { text: string; subText: string };
    subTitle?: string;
  };
}

export function createInitialState(): LoginState {
  return {
    loginConfig: {
      loginProviders: []
    },
    ui: undefined
  };
}

@StoreConfig({ name: 'login' })
@Injectable({ providedIn: 'root' })
export class LoginStore extends Store<LoginState> {
  constructor() {
    super(createInitialState());
  }
}
