import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { UploadFile } from '../model/file-upload';
import { FileUploadState, FileUploadStore } from './file-upload.store';

@Injectable()
export class FileUploadQuery extends Query<FileUploadState> {
  files$: Observable<UploadFile[]> = this.select('files');

  constructor(protected store: FileUploadStore) {
    super(store);
  }

  get files(): UploadFile[] {
    return this.getValue().files;
  }

  get context(): any {
    return this.getValue().context;
  }
}
