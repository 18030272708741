import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ILoginConfig, LoginType, LOGIN_CONFIG } from './login.model';
import { LoginQuery } from './login.query';
import { LoginStore } from './login.store';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loginInfo!: { [key: string]: string };

  private _logout$: Subject<boolean> = new Subject();

  public logout$: Observable<boolean> = this._logout$.asObservable();

  constructor(@Inject(LOGIN_CONFIG) config: ILoginConfig, private http: HttpClient, private store: LoginStore, private query: LoginQuery) {
    this.setLoginConfig(config);
  }

  setLoginConfig(config: ILoginConfig) {
    const { background, title, logo } = config;
    this.store.update({ loginConfig: config, ui: { background, logo, title } });
  }

  getLoginConfig(): ILoginConfig {
    return this.query.loginConfig;
  }

  setLoginInfo(key: string, value: string): void {
    this.loginInfo[key] = value;
  }

  getLoginInfo(key: any = false): any {
    if (key) {
      return this.loginInfo[key];
    }
    return this.loginInfo;
  }

  getActivLoginProvider(): { id: LoginType; component?: any; [key: string]: any } {
    return this.query.activeLoginProvider;
  }

  getUI(): any {
    return this.query.ui;
  }

  OTPLogin(payload: any) {
    const activeLoginProvider = this.query.activeLoginProvider;
    return this.http.post(activeLoginProvider.authenticateEndPoint, payload);
  }

  requestOTP(email: string) {
    const activeLoginProvider = this.query.activeLoginProvider;
    return this.http.post(activeLoginProvider.requestEndPoint, {
      email
    });
  }

  checkEmailRegistered(email: string) {
    const activeLoginProvider = this.query.activeLoginProvider;
    return this.http.get(`${activeLoginProvider.authenticateEndPoint}${email}`, {});
  }

  logout(): void {
    this._logout$.next(true);
  }
}
