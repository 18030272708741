import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { ILoginConfig, LoginType } from './login.model';
import { LoginState, LoginStore } from './login.store';

@Injectable({ providedIn: 'root' })
export class LoginQuery extends Query<LoginState> {
  constructor(protected store: LoginStore) {
    super(store);
  }

  loginConfig$: Observable<ILoginConfig> = this.select('loginConfig');

  get loginConfig(): ILoginConfig {
    return this.getValue().loginConfig;
  }

  get ui(): any {
    return this.getValue().ui;
  }

  get activeLoginProvider(): { id: LoginType; component?: any; [key: string]: any } {
    const loginProviders = this.getValue().loginConfig.loginProviders;
    return (loginProviders && loginProviders[0]) || null;
  }
}
