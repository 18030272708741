import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { CreateFlowType, ICreateFlowStep } from '../model/create-flow';
import { StepperStore, CreateFlowState, IStepState } from './stepper.store';

@Injectable()
export class StepperQuery extends Query<CreateFlowState> {
  constructor(protected store: StepperStore) {
    super(store);
  }

  type$: Observable<CreateFlowType> = this.select('type');

  steps$: Observable<ICreateFlowStep[]> = this.select('steps');

  stepState$: Observable<IStepState> = this.select(state => state.ui.stepState);

  // currentFlowStep$: Observable<ICreateFlowStep> = this.select(state => {
  //   state.flows.filter(flow => flow.state === CreateFlowStepState.CURRENT);
  // });

  get steps(): ICreateFlowStep[] {
    return this.getValue().steps;
  }

  get stepState(): IStepState {
    return this.getValue().ui.stepState;
  }

  get type(): CreateFlowType {
    return this.getValue().type;
  }

  get jump(): boolean {
    return this.getValue().jump;
  }
}
