import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemMasterWidgetComponent } from './components/item-master-widget/item-master-widget.component';
import { ItemListComponent } from './components/item-list/item-list.component';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { AddressModule } from '../address';
import { ZetListModule } from '@zetwerk/zet-list';
import { PluckPipe } from './pipes/pluck.pipe';

import { CdkTreeModule } from '@angular/cdk/tree';
import { TreeSelectComponent } from './components/tree-select/tree-select.component';
import { DeleteItemComponent } from './components/item-list/delete-item/delete-item.component';

@NgModule({
  imports: [
    CommonModule,
    TabsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    AddressModule,
    CdkTreeModule,
    ZetListModule
  ],
  declarations: [ItemMasterWidgetComponent, TreeSelectComponent, ItemListComponent, PluckPipe, DeleteItemComponent],
  exports: [ItemMasterWidgetComponent]
})
export class ItemMasterModule {}
