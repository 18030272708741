export interface IPageInfo {
  collection: any[];
  size: number;
  start: number;
  end: number;
  slice: any[];
}

export interface IPage {
  label: string;
  value: any;
}

export interface IPaginationInstance {
  itemsPerPage: number;
  currentPage: number;
  totalItems?: number;
}

export enum RowModelType {
  CLIENT_SIDE,
  SERVER_SIDE,
  INFINITE,
  VIEWPORT
}

// TODO will remove this after dynamic component implementation
export enum PaginationControlType {
  DEFAULT,
  DROPDOWN
}
