import { Injectable } from '@angular/core';
import * as objectPath from 'object-path-immutable';
import { ConfigQuery } from './config.query';
import { ConfigStore } from './config.store';

type Path = string | ReadonlyArray<number | string>;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(private store: ConfigStore, private query: ConfigQuery) {}

  public get(path: Path): boolean {
    const config = this.query.getValue();
    return objectPath.get(config, path);
  }

  public set(path: Path, value: any): void {
    const config = this.query.config;
    const newConfig = objectPath.set(config, path, value);
    this.setConfig(newConfig);
  }

  public delete(path: Path): void {
    const config = this.query.config;
    const newConfig = objectPath.del(config, path);
    this.setConfig(newConfig);
  }

  public push(path: Path, items: any[]): void {
    const config = this.query.config;
    const newConfig = objectPath.push(config, path, items);
    this.setConfig(newConfig);
  }

  public insert(path: Path, value: any, index: number): void {
    const config = this.query.config;
    const newConfig = objectPath.insert(config, path, value, index);
    this.setConfig(newConfig);
  }

  public setConfig(value: any): void {
    const config = this.query.getValue();
    const newConfig = { ...config, ...value };
    this.store.update({ newConfig });
  }
}
