import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UploadMasterState, UploadMasterStore } from './upload-master.store';
import { UploadMasterContext } from '../models/context';
import { UploadMasterEventData } from '../models/event';

@Injectable()
export class UploadMasterQuery extends Query<UploadMasterState> {
  steps$ = this.select('steps');

  type$ = this.select('type');

  app$ = this.select('app');

  isCompanyRequired$ = this.select('isCompanyRequired');

  selectMultipleCompany$ = this.select('selectMultipleCompany');

  title$ = this.select('title');

  sampleTemplate$ = this.select('sampleTemplate');

  downloadEndpoint$ = this.select('downloadEndpoint');

  allCompanies$ = this.select('allCompanies');

  uploadedFile$ = this.select('uploadedFile');

  jobTaskId$ = this.select('jobTaskId');

  jobTaskStatus$ = this.select('jobTaskStatus');

  downloadJobTaskId$ = this.select('downloadJobTaskId');

  downloadJobTaskStatus$ = this.select('downloadJobTaskStatus');

  downloadJobUrl$ = this.select('downloadJobUrl');

  constructor(protected store: UploadMasterStore) {
    super(store);
  }

  get type(): UploadMasterContext['type'] {
    return this.getValue().type;
  }

  get company(): UploadMasterEventData['company'] {
    return this.getValue().company;
  }

  get uploadedFile(): UploadMasterEventData['uploadedFile'] {
    return this.getValue().uploadedFile;
  }

  get masterResponse(): UploadMasterEventData['masterResponse'] {
    return this.getValue().masterResponse || [];
  }

  get jobTaskId(): UploadMasterState['jobTaskId'] {
    return this.getValue().jobTaskId;
  }

  get jobTaskStatus(): UploadMasterState['jobTaskStatus'] {
    return this.getValue().jobTaskStatus;
  }

  get downloadJobUrl(): UploadMasterState['downloadJobUrl'] {
    return this.getValue().downloadJobUrl;
  }

  get sampleTemplate(): UploadMasterState['sampleTemplate'] {
    return this.getValue().sampleTemplate;
  }
}
