import { Component, ChangeDetectionStrategy, Input, ContentChild, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SpyScrollLinkDirective } from '../../directives/spy-scroll-link.directive';
import { ISpyLink } from '../../model/spy-scroll';
import { SpyScrollService } from '../../services/spy-scroll.service';
import { SpyScrollQuery } from '../../states/spy-scroll.query';

@Component({
  selector: 'zet-spyscroll-link',
  templateUrl: './spy-scroll-link.component.html',
  styleUrls: ['./spy-scroll-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpyScrollLinkComponent implements OnInit {
  @Input() pushToUrl;

  @Input() title = '';

  @Input() href;

  @ContentChild(SpyScrollLinkDirective) linkRef!: SpyScrollLinkDirective;

  link$: Observable<ISpyLink>;

  // isActive$ = new Subject();

  constructor(private service: SpyScrollService, private query: SpyScrollQuery) {}

  ngOnInit(): void {
    let link: ISpyLink = {
      id: this.href,
      title: this.title || '',
      isActive: false
    };
    this.service.registerLink(link);

    this.link$ = this.query.getLinkById(this.href);
  }

  goto(): void {
    this.service.handleScrollTo(this.href);
  }

  setActive(): void {}

  unsetActive(): void {}
}
