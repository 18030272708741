import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ItemMasterData } from '../models/data';
import { ItemMasterFilterModel } from '../models/filter';
import { ItemListMode, ItemMasterListModel } from '../models/list';
import { UIState } from '../models/ui-state';

export interface ItemMasterState extends ItemMasterFilterModel, ItemMasterListModel, UIState, ItemMasterData {}

export function createInitialState(): ItemMasterState {
  return {
    parentCategory: '',
    listMode: ItemListMode.LIST,
    categoryType: 'PROCUREMENT',
    companyId: '',
    itemGroup: [],
    productType: '',
    searchText: '',
    selectedItems: [],
    unselectSelectedItems: [],
    pageNumber: 1,
    recordsPerPage: 20,
    sortBy: '',
    noResults: false,
    emptyList: false,
    categories: [],
    itemGroups: [],
    productTypes: [],
    isCategoryLoading: false,
    isListLoading: false,
    uom: [],
    uoms: []
  };
}

@StoreConfig({ name: 'widget.itemMaster' })
@Injectable()
export class ItemMasterStore extends Store<ItemMasterState> {
  constructor() {
    super(createInitialState());
  }
}
