import { Routes } from '@angular/router';
import { ContributionsComponent } from './pages/contributions/contributions.component';
import { IconLibraryComponent } from './pages/icon-library/icon-library.component';
import { ShowCaseComponent } from './pages/showcase/showcase.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from 'projects/zetui/src/public-api';

export const ROUTES: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/features/components/login/login.module').then(m => m.LoginDemoModule)
  },
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'showcase' },
      { path: 'basics', loadChildren: () => import('./pages/basics/basics.module').then(m => m.BasicsModule) },
      { path: 'components', loadChildren: () => import('./pages/components/components.module').then(m => m.ComponentsModule) },
      { path: 'widgets', loadChildren: () => import('./pages/widgets/widget.module').then(m => m.WidgetModule) },
      { path: 'features', loadChildren: () => import('./pages/features/features.module').then(m => m.FeaturesModule) },
      { path: 'libraries', loadChildren: () => import('./pages/libraries/libraries.module').then(m => m.LibrariesModule) },
      { path: 'utils', loadChildren: () => import('./pages/utils/utils.module').then(m => m.UtilsModule) },
      { path: 'icon-library', component: IconLibraryComponent },
      { path: 'showcase', component: ShowCaseComponent },
      { path: 'contributions', component: ContributionsComponent }
    ],
    canActivate: [AuthGuard]
  }
];
