import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AddressWidgetComponent } from './components/address-widget/address-widget.component';
import { AddressDropdownComponent } from './components/address-dropdown/address-dropdown.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';

@NgModule({
  imports: [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule],
  declarations: [AddressFormComponent, AddressWidgetComponent, AddressDropdownComponent, ValidationMessageComponent],
  exports: [AddressFormComponent, AddressDropdownComponent, AddressWidgetComponent, ValidationMessageComponent]
})
export class AddressModule {}
