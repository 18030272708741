import { HttpErrorResponse } from '@angular/common/http';
import { HTTPErrorTypes } from './http.model';

const errorTypeMsg: { [key in HTTPErrorTypes]: { msg: string; des: string } } = {
  400: {
    msg: 'Something went wrong',
    des: 'Please try again'
  },
  401: {
    msg: 'Please login to continue.',
    des: 'Auth Token is Invalid!'
  },
  422: {
    msg: '',
    des: 'Please try again'
  },
  500: {
    msg: 'Please try again!',
    des: 'Something went wrong'
  },
  502: {
    msg: 'Please try again!',
    des: 'Something went wrong'
  },
  504: {
    msg: 'Please try again!',
    des: 'Something went wrong'
  },
  0: {
    msg: 'Please try again!',
    des: 'Something went wrong'
  }
};

const errorTypeHandler: { [key in HTTPErrorTypes]: any } = {
  400(message: string, description: string) {
    this.toastr.error(message, description, {
      timeOut: 5000
    });
  },
  401(message: string, description: string) {
    this.login.logout();
    this.toastr.error(message, description);
  },
  422(message: string, description: string) {
    this.toastr.error(message, description, {
      timeOut: 5000
    });
  },
  500(message: string, description: string) {
    this.toastr.error(message, description, {
      timeOut: 5000
    });
  },
  502(message: string, description: string) {},
  504(message: string, description: string) {},
  0(message: string, description: string) {
    this.toastr.error(message, description, {
      timeOut: 5000
    });
  }
};

export function createErrorFactory(
  error: HttpErrorResponse,
  customeErrorMessage: {
    [key in HTTPErrorTypes]?: { msg: string; des: string };
  }
) {
  const status: HTTPErrorTypes = error?.status;

  const msg =
    customeErrorMessage[status]?.msg || error?.error?.message || errorTypeMsg[status]?.msg || error.message || errorTypeMsg[0].msg;
  const des = customeErrorMessage[status]?.des || errorTypeMsg[status]?.des || errorTypeMsg[0]?.des;
  const handler = errorTypeHandler[status] || errorTypeHandler[0];

  return { msg, des, handler };
}
