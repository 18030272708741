<article class="file-card">
  <img [src]="fileIconPath" />
  <div class="file-card--info">
    <h6>{{ file?.name }}</h6>
    <span *ngIf="file?.loaded > 0" class="file-card--status">
      {{ file?.loaded | formatBytes }} / {{ file?.total | formatBytes }} uploaded
    </span>
  </div>
  <div class="file-card--progress">
    <div *ngIf="file?.status === fileUploadStatus.INPROGRESS">
      <zet-progress-spinner [current]="file?.loaded" [total]="file?.total" class="file-card--progress-circular"></zet-progress-spinner>
    </div>
    <span *ngIf="file?.status === fileUploadStatus.COMPLETED" class="material-icons success">check_circle</span>
    <span *ngIf="file?.status === fileUploadStatus.ERROR" class="material-icons error" title="file?.error?.message">error</span>
  </div>
  <div *ngIf="file?.status === fileUploadStatus.INPROGRESS" class="file-card--actions">
    <button type="button" class="material-icons" (click)="cancel.emit(file)">close</button>
  </div>
  <div *ngIf="file?.status === fileUploadStatus.COMPLETED" class="file-card--actions">
    <button type="button" class="material-icons" (click)="view.emit(file)">visibility</button>
    <button type="button" class="material-icons" (click)="delete.emit(file)">delete</button>
  </div>
  <div *ngIf="file?.status === fileUploadStatus.ERROR" class="file-card--actions">
    <button type="button" class="material-icons" (click)="view.emit(file)">visibility</button>
    <button type="button" class="material-icons" (click)="retry.emit(file)">replay</button>
    <button type="button" class="material-icons" (click)="cancel.emit(file)">close</button>
  </div>
</article>
