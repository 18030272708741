/* eslint-disable no-alert */
/* --------- FILE FROM THE INTERNET ----------- */
/* https://medium.com/@aakashbumiya/auto-reload-for-clients-after-deploy-with-angular-7-production-build-bdc45be9b2bd */
/* -------- Version check for auto refresh on deployment ---- */

import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { VersionPopupComponent } from './version-popup.component';
import { APP_NAME } from './depolyment';

//
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  // This will be replaced by actual hash (See build/post-build.js)
  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  private currentVersion = '__ZETWERK_POST_BUILD_ENTERS_VERSION_HERE__';

  private confirmModalRef: any;

  private versionCollection: AngularFirestoreCollection;

  private version$: Observable<any>;

  constructor(@Inject(APP_NAME) private appName: string, private http: HttpClient, private fbs: AngularFirestore) {
    const projectName = this.appName;

    this.versionCollection = this.fbs.collection('/version', ref =>
      ref.where('name', '==', projectName).orderBy('createdAt', 'desc').limit(1)
    );

    this.version$ = this.versionCollection.valueChanges({ idField: 'id' });
  }

  // Checks in every set frequency the version of frontend application
  public init(): void {
    this.version$.subscribe(docs => {
      if (docs.length === 0) {
        return;
      }

      const doc = docs[0];

      console.log('event version', doc.version);

      if (doc.buildMode === 'BUILD') {
        return;
      }

      this.checkVersion({ doc });
    });
  }

  // Will do the call and check if the hash has changed or not
  private checkVersion({ doc }): void {
    // Timestamp these requests to invalidate caches
    console.log('currentVersion', this.currentVersion);
    console.log('newVersion', doc.version);

    if (this.hasVersionChanged(this.currentVersion, doc.version)) {
      this.showVersionPopup({ releaseType: doc.releaseType, version: doc.version });
    }
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   */
  // private hasHashChanged(currentHash: string, newHash: string): boolean {
  //   if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
  //     return false;
  //   }
  //   return currentHash !== newHash;
  // }

  private hasVersionChanged(currentVersion: string, newVersion: string): boolean {
    if (!currentVersion || currentVersion === '__ZETWERK_POST_BUILD_ENTERS_VERSION_HERE__') {
      return false;
    }
    return currentVersion !== newVersion;
  }

  private showVersionPopup({ hash, releaseType, version }: { hash?: string; releaseType?: string; version: string }): void {
    if (releaseType === 'MAJOR') {
      let res;
      do {
        res = window.confirm('A new update is available! Click Yes to reload and fetch the updates.');
      } while (!res);

      this.updateHashAndVersion({ hash, version });
      return;
    }

    if (window.confirm('A new update is available! Click Yes to reload and fetch the updates.')) {
      this.updateHashAndVersion({ hash, version });
    }
    // if (this.confirmModalRef) return;

    // const config: ModalOptions = {
    //   ignoreBackdropClick: true,
    //   class: 'version common-messages-modal',
    //   initialState: {
    //     hash,
    //     releaseType
    //   }
    // };

    // this.confirmModalRef = this.modalService.show(VersionPopupComponent, config);

    // this.confirmModalRef.content.onSubmit.subscribe(() => {
    //   this.updateHashAndVersion({ hash, version });
    // });

    // this.confirmModalRef.content.onCancel.subscribe(() => {
    //   this.destroyPopup();
    // });
  }

  private hideVersionPopup(): void {
    this.confirmModalRef?.hide();
  }

  public updateHashAndVersion({ hash, version }: { hash?: string; version?: string }): void {
    this.currentHash = hash || '{{POST_BUILD_ENTERS_HASH_HERE}}';
    this.currentVersion = version || '__ZETWERK_POST_BUILD_ENTERS_VERSION_HERE__';
    // this.hideVersionPopup();
    window.location.reload();
  }

  public destroyPopup(): void {
    this.hideVersionPopup();

    setTimeout(() => {
      this.confirmModalRef = null;
    }, 1000);
  }
}
