/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ItemMasterService } from '../../../services/item-master.service';

@Component({
  selector: 'zet-delete-item',
  templateUrl: './delete-item.component.html',
  styleUrls: ['./delete-item.component.scss']
})
export class DeleteItemComponent {
  params: any;

  constructor(private item: ItemMasterService) {}

  init(params: any) {
    this.params = params;
  }

  deleteItem(): void {
    const row = this.params.value.data;
    this.item.removeItemsFromSelectedList([row]);
  }
}
