<div class="modal-header">
  <h4 class="modal-title">{{ header }}</h4>
  <button type="button" class="zbtn-close" aria-label="Close" (click)="cancel.next()">
    <span class="material-icons">close</span>
  </button>
</div>
<div class="modal-body">
  <section class="form-container">
    <form class="widget-form" novalidate [formGroup]="warehouseForm">
      <div class="widget-form--group">
        <label class="widget-form--label" for="site">Site<span class="required"> * </span></label>
        <zet-address-dropdown
          id="site"
          placeholder="Site"
          [isError]="siteControl.invalid && (siteControl.dirty || siteControl.touched)"
          [items]="sites"
          [bindLabel]="'name'"
          [(ngModel)]="selectedSite"
          formControlName="site"
          (changeData)="onSiteChange($event)"
          [disabled]="isSiteDisabled"
        ></zet-address-dropdown>
        <zet-address-validation-message
          *ngIf="siteControl.invalid && (siteControl.dirty || siteControl.touched)"
          [errorMessage]="validationMessages?.site"
          [control]="siteControl"
        ></zet-address-validation-message>
      </div>

      <div class="widget-form--group">
        <label class="widget-form--label" for="warehouse">Warehouse<span class="required"> * </span></label>
        <zet-address-dropdown
          id="warehouse"
          placeholder="Warehouse"
          [isError]="warehouseControl.invalid && (warehouseControl.dirty || warehouseControl.touched)"
          [items]="warehouses"
          [bindLabel]="'name'"
          [(ngModel)]="selectedWarehouse"
          formControlName="warehouse"
          [disabled]="isWarehouseDisabled"
        ></zet-address-dropdown>
        <zet-address-validation-message
          *ngIf="warehouseControl.invalid && (warehouseControl.dirty || warehouseControl.touched)"
          [errorMessage]="validationMessages?.warehouse"
          [control]="warehouseControl"
        ></zet-address-validation-message>
      </div>

      <div *ngIf="warehouseControl?.value" class="widget-form--group warehouse--address">
        <header>Address</header>
        <p>{{ getAddress(warehouseControl?.value) }}</p>
      </div>

      <div class="form-container--buttons">
        <button type="button" class="zbtn-secondary" (click)="onCancel()">Cancel</button>
        <button type="button" class="zbtn-primary" (click)="save()">Save</button>
      </div>
    </form>
  </section>
</div>
<div class="modal-footer"></div>
