import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IFileUploadGroup, UploadFile, UploadFileType } from '../../model/file-upload';

// FIXME Add onPush for this componet
@Component({
  selector: 'zet-file-upload-list',
  templateUrl: './file-upload-list.component.html',
  styleUrls: ['./file-upload-list.component.scss']
})
export class FileUploadListComponent implements OnInit {
  @Input() files: UploadFile[];

  @Input() group: IFileUploadGroup;

  @Input() type: UploadFileType;

  @Input() acceptFolder: boolean;

  @Input() disabled: boolean;

  @Input() attachmentCard: TemplateRef<unknown> = null;

  @Input() customClass: string;

  @Input() showDragnDrop: boolean;

  @Output() retry: EventEmitter<UploadFile> = new EventEmitter();

  @Output() view: EventEmitter<UploadFile> = new EventEmitter();

  @Output() cancel: EventEmitter<UploadFile> = new EventEmitter();

  @Output() delete: EventEmitter<UploadFile> = new EventEmitter();

  @Output() fileDropped = new EventEmitter();

  fileControl: FormControl = new FormControl('');

  uploadFileType = UploadFileType;

  _retry = this.onRetry.bind(this);

  _cancel = this.onCancel.bind(this);

  _delete = this.onDelete.bind(this);

  _view = this.onView.bind(this);

  ngOnInit(): void {
    this.fileControl.valueChanges.subscribe((fileList: FileList) => {
      this.fileDropped.emit({ fileList, groupId: this.group.id });
    });
  }

  onRetry(file: UploadFile): void {
    this.retry.emit(file);
  }

  onCancel(file: UploadFile): void {
    this.cancel.emit(file);
  }

  onView(file: UploadFile): void {
    this.view.emit(file);
  }

  onDelete(file: UploadFile): void {
    this.delete.emit(file);
  }

  get isDragDropVisible(): boolean {
    if (!this.showDragnDrop || (this.type === UploadFileType.SINGLE_UPLOAD && this.files.length > 0)) {
      return false;
    }
    return true;
  }
}
