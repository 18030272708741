import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadFile, FileUploadStatus } from '../../model/file-upload';

@Component({
  selector: 'zet-file-progress-card',
  templateUrl: './file-progress-card.component.html',
  styleUrls: ['./file-progress-card.component.scss']
})
export class FileProgressCardComponent implements OnInit {
  @Input() file: UploadFile;

  @Output() retry: EventEmitter<UploadFile> = new EventEmitter();

  @Output() view: EventEmitter<UploadFile> = new EventEmitter();

  @Output() cancel: EventEmitter<UploadFile> = new EventEmitter();

  @Output() delete: EventEmitter<UploadFile> = new EventEmitter();

  fileUploadStatus = FileUploadStatus;

  fileIconsBasePath = '/assets/zetui/svg/files/';

  availableIcons = ['doc', 'docx', 'jpeg', 'jpg', 'mov', 'mpg', 'pdf', 'png', 'ppt', 'svg', 'tiff', 'txt', 'xls', 'xlsx', 'zip'];

  fileIconPath = `${this.fileIconsBasePath}default.svg`;

  ngOnInit(): void {
    if (this.availableIcons.find(icon => icon === this.file?.type)) {
      this.fileIconPath = `${this.fileIconsBasePath}${this.file?.type}.svg`;
    }
  }
}
