import { AfterViewInit, Directive, OnDestroy, TemplateRef } from '@angular/core';
import { SidemenuService } from '../sidemenu.service';

@Directive({
  selector: 'zetSidemenuHeaderInfo, [zetSidemenuHeaderInfo]'
})
export class SidemenuHeaderInfoDirective implements AfterViewInit, OnDestroy {
  constructor(public temRef: TemplateRef<unknown>, private sidemenuService: SidemenuService) {}

  ngAfterViewInit(): void {
    this.sidemenuService.setHeaderInfoTemplate(this.temRef);
  }

  ngOnDestroy(): void {
    this.sidemenuService.setHeaderInfoTemplate(null);
  }
}
