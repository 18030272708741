import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginService } from './login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// TODO Refactor code
@Component({
  selector: 'zet-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.scss']
})
export class LoginEmailComponent implements OnInit {
  @Output() changeScreen: EventEmitter<string> = new EventEmitter();

  loginForm: FormGroup;

  email: any;

  constructor(public loginService: LoginService, public formBuilder: FormBuilder) {
    this.loginForm = this.formBuilder.group({
      email: [this.email, [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    this.email = this.loginService.getLoginInfo('email');
  }

  get formErrors() {
    return this.loginForm.errors;
  }

  login() {
    if (!this.loginForm.valid) {
      const email: any = this.loginForm.get('email');
      if (email.errors) {
        email.errors.required ? this.loginForm.setErrors({ required: true }) : '';
        email.errors.email ? this.loginForm.setErrors({ email: true }) : '';
      }
      return;
    }
    this.loginService.checkEmailRegistered(this.loginForm.value.email).subscribe((res: any) => {
      if (!res.success) {
        this.loginForm.setErrors({ notRegistered: true });
      } else {
        this.loginService.setLoginInfo('email', this.loginForm.value.email);
        this.loginService.requestOTP(this.loginForm.value.email).subscribe();
        this.changeScreen.emit('loing-enter-otp');
      }
    });
  }
}
