import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';

import { LoginComponent } from './login.component';
import { LoginEmailComponent } from './login-email.component';
import { EmailOtpLoginComponent } from './email-otp-login.component';
import { GoogleLoginComponent } from './google-login.component';
import { OtpComponent } from './otp.component';
import { SocialLoginModule } from '../social-login';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgOtpInputModule, SocialLoginModule],
  declarations: [EmailOtpLoginComponent, LoginComponent, LoginEmailComponent, GoogleLoginComponent, OtpComponent],
  exports: [EmailOtpLoginComponent, LoginComponent, LoginEmailComponent, GoogleLoginComponent, OtpComponent, SocialLoginModule],
  entryComponents: [EmailOtpLoginComponent, GoogleLoginComponent, LoginEmailComponent]
})
export class LoginModule {}
