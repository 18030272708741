import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { IPage, IPageInfo, IPaginationInstance } from '../model/pagination';
import { PaginationState, PaginationStore } from './pagination.store';

@Injectable()
export class PaginationQuery extends Query<PaginationState> {
  pageInfo$: Observable<IPageInfo> = this.select('pageInfo');

  pageInstance$: Observable<IPaginationInstance> = this.select('pageInstance');

  pages$: Observable<IPage[]> = this.select('pages');

  allPages$: Observable<IPage[]> = this.select('allPages');

  constructor(protected store: PaginationStore) {
    super(store);
  }

  get pageInfo(): IPageInfo {
    return this.getValue().pageInfo;
  }

  get pageInstance(): IPaginationInstance {
    return this.getValue().pageInstance;
  }
}
