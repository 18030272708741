import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EnvironmentBarComponent } from './environment-bar.component';
import { VersionPopupComponent } from './version-popup.component';

import { AngularFireModule, FIREBASE_OPTIONS, FIREBASE_APP_NAME } from '@angular/fire';

import { AngularFirestoreModule } from '@angular/fire/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCCbZfLhxP4VYg-bXclwxcEAakjHB-ogy8',
  authDomain: 'deployment-9d204.firebaseapp.com',
  databaseURL: 'https://deployment-9d204-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'deployment-9d204',
  storageBucket: 'deployment-9d204.appspot.com',
  messagingSenderId: '763042033169',
  appId: '1:763042033169:web:11dc8dc4c509befffb0bb0'
};

@NgModule({
  imports: [CommonModule, AngularFireModule, AngularFirestoreModule],
  declarations: [EnvironmentBarComponent, VersionPopupComponent],
  providers: [{ provide: FIREBASE_OPTIONS, useValue: firebaseConfig }],
  exports: [AngularFireModule, EnvironmentBarComponent, VersionPopupComponent, AngularFirestoreModule]
})
export class DepolymentModule {}
