/* Remove Zet from the interface names */
export interface ISidemenuItem {
  name: string;
  title: string;
  selected: boolean;
  icons?: ISidemenuIcon;
  indicators?: ISidemenuIndicators[];
  isSub?: boolean;
  sub?: Array<unknown>;
  path: string;
}
interface ISidemenuIcon {
  selected?: string;
  unselected?: string;
  default: string;
}

interface ISidemenuIndicators {
  id: string;
  type: string;
  /* FIXME name should be position. value should be an enum before_icon, after_icon, after_item */
  positions: string;
}

/* FIXME Don't export the interface */
export enum SidemenuMode {
  PUSH = 'push',
  OVER = 'over',
  SIDE = 'side'
}
