import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: 'zetFileDrop, [zetFileDrop]'
})
export class FileDropDirective {
  @Input() preventBodyDrop: boolean;

  @Output() fileDropped = new EventEmitter();

  @HostBinding('style.background-color') private backgroundColor = '#fff';

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.backgroundColor = '#edf5fd';
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.backgroundColor = '#FFF';
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.backgroundColor = '#FFF';
    this.fileDropped.emit(event.dataTransfer.files);
  }
}
