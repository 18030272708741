import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImageViewerModule } from '@zetwerk/image-viewer';
import { NgSelectModule } from '@ng-select/ng-select';

import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { FileViewerControlComponent } from './components/file-viewer-control/file-viewer-control.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { PDFViewerOptionDirective } from './directive/pdf-viewer-option.directive';
import { ImageViewerOptionDirective } from './directive/image-viewer-option.directive';
import { ThreedViewerComponent } from './components/threed-viewer/threed-viewer.component';

@NgModule({
  imports: [CommonModule, ImageViewerModule, PdfViewerModule, NgSelectModule, FormsModule, ReactiveFormsModule],
  declarations: [
    FileViewerComponent,
    FileViewerControlComponent,
    PdfViewerComponent,
    ImageViewerComponent,
    PDFViewerOptionDirective,
    ImageViewerOptionDirective,
    ThreedViewerComponent
  ],
  exports: [
    FileViewerComponent,
    PdfViewerComponent,
    ImageViewerComponent,
    PDFViewerOptionDirective,
    ImageViewerOptionDirective,
    ThreedViewerComponent
  ]
})
export class FileViewerModule {}
