/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ItemMasterListModel {
  listMode: ItemListMode;
  selectedItems: any[];
  unselectSelectedItems: any[];
  pageNumber: number;
  recordsPerPage?: number;
  sortBy?: string;
  noResults: boolean;
  emptyList: boolean;
}

export enum ItemListMode {
  LIST = 'LIST',
  SELECTED = 'SELECTED'
}
