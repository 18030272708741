/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable no-underscore-dangle */
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

export interface ITreeNode {
  _id: string;
  parent: string;
  children?: ITreeNode[];
  name: string;
  [key: string]: any;
}

@Component({
  selector: 'zet-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss']
})
export class TreeSelectComponent implements OnChanges {
  @Input() dataSource: ITreeNode[];

  @Output() select: EventEmitter<ITreeNode> = new EventEmitter();

  selectedNode: ITreeNode = null;

  treeControl = new NestedTreeControl<ITreeNode>(node => node.children);

  ngOnChanges(): void {
    this.selectedNode = this.dataSource[0];
    this.treeControl.expand(this.selectedNode);
  }

  hasChild(_: number, node: ITreeNode): boolean {
    return !!node.children && node.children.length > 0;
  }

  isSelectedNode(node: ITreeNode): boolean {
    return this.selectedNode?._id === node._id;
  }

  selectNode(node: ITreeNode): void {
    this.collapseSiblingAndChildrenNodes(node);

    this.treeControl.expand(node);
    this.select.emit(node);
    this.selectedNode = node;
  }

  getLevel(data: ITreeNode[], node: ITreeNode): number {
    let path = data.find(branch => {
      return this.treeControl.getDescendants(branch).some(n => n.name === node.name);
    });

    return path ? this.getLevel(path.children, node) + 1 : 0;
  }

  getSiblingNodesByFlatteningTree(node: ITreeNode): ITreeNode[] {
    const parentNodeId = node.parent;
    const allNodes: ITreeNode[] = this.dataSource.reduce(
      (accumulator, dataNode) => [...accumulator, ...this.treeControl.getDescendants(dataNode), dataNode],
      []
    );
    return allNodes.find(value => value._id === parentNodeId)?.children || [];
  }

  collapseSiblingAndChildrenNodes(node: ITreeNode): void {
    let siblingNodes = [];
    let childNodes = [];
    if (node.parent) {
      siblingNodes = this.getSiblingNodesByFlatteningTree(node);
    }
    childNodes = node?.children || [];

    const nodes = [...siblingNodes, ...childNodes];
    nodes.forEach(treeNode => {
      this.treeControl.collapse(treeNode);
    });
  }

  reset(): void {
    this.selectedNode = this.dataSource[0];
    this.treeControl.expand(this.selectedNode);

    this.select.emit(this.selectedNode);
  }
}
