import { InjectionToken } from '@angular/core';

export interface IAuthData {
  userObject: any;
  tokens: any;
}

export interface IAuthConfig {
  authUrl: string;
  [key: string]: string | number;
}

export const AUTH_CONFIG = new InjectionToken<IAuthConfig>('AuthConfig');

export class User {
  id!: string;

  name!: string;

  firstName!: string;

  lastName!: string;

  email!: string;

  constructor({ id, firstName, lastName, email }: { id: string; firstName: string; lastName: string; email: string }) {
    this.id = id || '';
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.email = email || '';
    this.name = this.getFullName();
  }

  getFullName(): string {
    const name = `${this?.firstName} ${this?.lastName}`;
    return name.trim();
  }
}
