import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { UploadFile } from '../model/file-upload';

export interface FileUploadState {
  files: UploadFile[];
  context: any;
}

export function createInitialState(): FileUploadState {
  return {
    files: [],
    context: null
  };
}

@Injectable()
@StoreConfig({ name: 'zetui.file-upload' })
export class FileUploadStore extends Store<FileUploadState> {
  constructor() {
    super(createInitialState());
  }
}
