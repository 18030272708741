import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Widget } from '../../../widget';
import { IAddress, IFieldToDisable, IDropdownItemToDisable } from '../../models/address-widget';
import { AddressFormComponent } from '../address-form/address-form.component';

@Component({
  selector: 'zet-address-widget',
  templateUrl: './address-widget.component.html',
  styleUrls: ['./address-widget.component.scss']
})
export class AddressWidgetComponent extends Widget<Partial<AddressWidgetComponent>, IAddress> {
  @Input() header: string;

  @Input() address: IAddress;

  @Input() fieldToDisable: IFieldToDisable;

  @Input() dropdownItemToDisable: IDropdownItemToDisable;

  @Input() syncAddressFromGST = false;

  @Input() customClass = 'g-22';

  @Input() oldAddressTemplate: TemplateRef<any>;

  @ViewChild(AddressFormComponent) addressForm: AddressFormComponent;

  public addressFormControl: FormControl;

  constructor(private fb: FormBuilder) {
    super();
    this.addressFormControl = this.fb.control({});
  }

  save(): void {
    if (this.addressForm.isValidForm()) {
      this.done.next(this.addressFormControl.value);
    }
  }
}
