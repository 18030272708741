import { ChangeDetectionStrategy, Component, ContentChild, ContentChildren, Inject, Input, OnChanges, QueryList } from '@angular/core';
import { Observable } from 'rxjs';
import { ITimelineConfig, ITimelineStep, TimelineOrientation, TimelineMode } from '../model/timeline';
import { TimelineQuery } from '../state/timeline.query';
import { TIMELINE_CONFIG } from '../timeline-config.token';
import { TimelineStepItemDirective } from '../directives/timeline-step-item.directive';
import { TimelineStepDirective } from '../directives/timeline-step.directive';
import { TimelineService } from '../timeline.service';
import { TimelineStore } from '../state/timeline.store';

@Component({
  selector: 'zet-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TimelineStore, TimelineQuery, TimelineService]
})
export class TimeLineComponent implements OnChanges {
  @Input() timelineSteps: ITimelineStep[];

  @Input() customClass: string;

  @Input() config: ITimelineConfig;

  @ContentChild(TimelineStepDirective) timelineRef!: TimelineStepDirective;

  @ContentChildren(TimelineStepItemDirective) timelineItemsRef!: QueryList<TimelineStepItemDirective>;

  timelineSteps$: Observable<ITimelineStep[]> = this.timelineQuery.timelineSteps$;

  constructor(
    @Inject(TIMELINE_CONFIG) private timelineConfig: ITimelineConfig,
    private timelineQuery: TimelineQuery,
    private timlineService: TimelineService
  ) {}

  ngOnChanges(): void {
    this.timlineService.updateTimelineSteps(this.timelineSteps);
  }

  get orientation(): TimelineOrientation {
    return this.config?.orientation || this.timelineConfig.orientation;
  }

  get mode(): TimelineMode {
    return this.config?.mode || this.timelineConfig.mode;
  }

  getRef(index: number): TimelineStepItemDirective {
    return this.timelineItemsRef.get(index);
  }
}
