<article [ngClass]="[(stepState$ | async)[step.id]?.accordionState ? 'accordion-is-open' : 'accordion-is-close']">
  <section class="stepper--header" (click)="onGoto()">
    <div class="stepper--icon" [class]="'stepper--icon-' + getStepState(step.state)">
      <span class="stepper--icon--content"> {{ index+1 }} </span>
    </div>
    <div class="stepper--title">
      <ng-container
        [ngTemplateOutlet]="headerContent?.template || defaultHeaderContent"
        [ngTemplateOutletContext]="{step: step}"
      ></ng-container>
    </div>
    <span class="stepper--arrow material-icons">keyboard_arrow_down</span>
  </section>
  <section
    class="stepper--body"
    [ngClass]="{'stepper--vertical-line': index != length-1}"
    [class]="'stepper--vertical-line-' + getStepState(step.state)"
  >
    <div class="stepper--content">
      <ng-container *ngTemplateOutlet="itemContent.template;context:{step: step, next: _next}"></ng-container>
    </div>
    <!-- FIXME try to remove this external logic -->
    <div *ngIf="index != length-1" class="horizontal-line"></div>
  </section>
</article>

<ng-template #defaultHeaderContent let-step="step">{{ step?.header }}</ng-template>
