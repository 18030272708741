<ul class="stepper" [class]="customClass">
  <li
    *ngFor="let step of (steps$ | async); let index = index"
    class="stepper--list"
    [ngClass]="{'stepper-is-active': step.state === createFlowStepState.CURRENT}"
  >
    <!-- FIXME Still accepting length as in input -->

    <zet-stepper-item
      [step]="step"
      [index]="index"
      [length]="(steps$ | async)?.length"
      [itemContent]="getcreateFlowItemTemplateRef(index)"
      [headerContent]="getcreateFlowHeaderTemplateRef(index)"
      (next)="next($event)"
      (goto)="goto($event)"
    ></zet-stepper-item>
  </li>
</ul>
