import { InjectionToken } from '@angular/core';

export enum EVENT_PROVIDER {
  GA = 'GA',
  MIX_PANEL = 'MIX_PANEL'
}

export interface IPageViewTrackEvent {
  path: string;
  eventData?: any;
  keysToTrack?: { key: string; configPath?: string }[];
}

export interface IEventTrackEvent {
  eventId?: string;
  eventType: string;
  keysToTrack?: { key: string; configPath?: string }[];
  eventData?: { [key: string]: any };
}

export interface IAnalyticsEvent {
  id: string;
  eventType: string;
  keysToTrack: { key: string; configPath?: string }[];
}

export interface IUserProperties {
  name: string;
  email: string;
  [key: string]: any;
}

export interface IEventAnalyticsConfig {
  eventProviders: IEventProviderConfig[];
  events: IAnalyticsEvent[];
  pageTrack?: {
    basePath?: string;
    clearIds?: boolean;
    clearHash?: boolean;
    clearQueryParams?: boolean;
    idsRegExp?: RegExp;
  };
  enablePageTitle?: boolean;
  customDimensions?: string[];
  appUrl?: string;
}

export interface IEventProviderConfig {
  provider: EVENT_PROVIDER;
  accessToken: string;
}

export const EventAnalyticsConfig = new InjectionToken<IEventAnalyticsConfig>('EventAnalyticsConfig');

export const EventAnalyticsEvents = new InjectionToken<IAnalyticsEvent[]>('EventAnalyticsEvents');
