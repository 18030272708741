import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  OnInit,
  Optional,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CreateFlowStepState, ICreateFlowStep, CreateFlowType } from '../../../../stepper';
import { FileUploadComponent, UploadFileType, IFileUploadConfig, FILE_UPLOAD_CONFIG } from '../../../../file-upload';
import { Observable } from 'rxjs';
import { BrowserEnvConfig, browserEnvProviders, BrowserEnvToken } from '../../../browserEnvConfig';
import { Widget } from '../../../widget';
import { UploadMasterContext } from '../../models/context';
import { UploadMasterEventData } from '../../models/event';
import { MasterUploadConfig } from '../../models/upload';
import { UploadMasterQuery } from '../../services/upload-master.query';
import { UploadMasterService } from '../../services/upload-master.service';
import { UploadMasterState, UploadMasterStore } from '../../services/upload-master.store';
import { JobStatus } from '../../../agenda-job-status';
import { tap } from 'rxjs/operators';

export const fileUploadConfig: IFileUploadConfig = {
  acceptedFileTypes: ['jpg', 'png', 'xlsx', 'pdf'],
  maxFiles: 2,
  maxSize: '50mb',
  signedUrlEndpoint: 'https://api.staging.zetwerk.com/ziso/v2/signed-upload-urls',
  registerFileEndpoint: 'https://api.staging.zetwerk.com/ziso/v2/upload-files'
};

@Component({
  selector: 'zet-upload-master-widget',
  templateUrl: './upload-master-widget.component.html',
  styleUrls: ['./upload-master-widget.component.scss'],
  providers: [
    ...browserEnvProviders,
    UploadMasterService,
    { provide: FILE_UPLOAD_CONFIG, useValue: fileUploadConfig },
    UploadMasterStore,
    UploadMasterQuery
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadMasterWidgetComponent extends Widget<UploadMasterContext, UploadMasterEventData> implements OnInit, AfterViewInit {
  createFlowType = CreateFlowType;

  context: UploadMasterContext;

  companyControl = new FormControl();

  steps$: Observable<UploadMasterState['steps']>;

  type$: Observable<UploadMasterState['type']>;

  app$: Observable<UploadMasterState['app']>;

  isCompanyRequired$: Observable<UploadMasterState['isCompanyRequired']>;

  title$: Observable<UploadMasterState['title']>;

  sampleTemplate$: Observable<UploadMasterState['sampleTemplate']>;

  downloadEndpoint$: Observable<UploadMasterState['downloadEndpoint']>;

  allCompanies$: Observable<UploadMasterState['allCompanies']>;

  uploadedFile$: Observable<UploadMasterState['uploadedFile']>;

  jobTaskId$: Observable<UploadMasterState['jobTaskId']>;

  jobTaskStatus$: Observable<UploadMasterState['jobTaskStatus']>;

  downloadJobTaskId$: Observable<UploadMasterState['jobTaskId']>;

  downloadJobTaskStatus$: Observable<UploadMasterState['jobTaskStatus']>;

  get steps(): ICreateFlowStep[] {
    return [
      {
        id: '0',
        header: 'Select Companies',
        body: 'Select the companies you want to upload to',
        state: CreateFlowStepState.CURRENT
      },
      {
        id: '1',
        header: `Upload ${MasterUploadConfig[this.context.type].title} Excel`,
        body: '',
        state: CreateFlowStepState.UNVISITED
      }
    ];
  }

  uploadConfig = {
    singleGroup: [
      {
        title: '',
        id: '1',
        actionBtn: {
          label: 'Select File',
          multiple: false,
          btnClass: ['zbtn-primary', 'excel-upload-icon']
        }
      }
    ],
    acceptedFileTypes: ['xlsx', 'xls'],
    maxFileSize: 10000000
  };

  @ViewChild('fileUploadVC', { read: ViewContainerRef }) fileUploadVC: ViewContainerRef;

  currentFiles = [];

  constructor(
    private uploadMaster: UploadMasterService,
    public query: UploadMasterQuery,
    private injector: Injector,
    @Inject(BrowserEnvToken) private apis: BrowserEnvConfig[],
    private componentResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.uploadMaster.update('steps', this.context.steps || this.steps);
    this.uploadMaster.update('type', this.context.type);
    this.uploadMaster.update('app', MasterUploadConfig[this.context.type].app);
    this.uploadMaster.update('isCompanyRequired', MasterUploadConfig[this.context.type].isCompanyRequired);
    this.uploadMaster.update('selectMultipleCompany', MasterUploadConfig[this.context.type].selectMultipleCompany);
    this.uploadMaster.update('title', MasterUploadConfig[this.context.type].title);
    this.uploadMaster.update('sampleTemplate', MasterUploadConfig[this.context.type].sampleTemplate);
    this.uploadMaster.update('downloadEndpoint', MasterUploadConfig[this.context.type].downloadEndpoint);

    this.steps$ = this.query.steps$;
    this.type$ = this.query.type$;
    this.isCompanyRequired$ = this.query.isCompanyRequired$;
    this.app$ = this.query.app$;
    this.title$ = this.query.title$;
    this.sampleTemplate$ = this.query.sampleTemplate$;
    this.downloadEndpoint$ = this.query.downloadEndpoint$;

    this.allCompanies$ = this.query.allCompanies$;
    this.uploadedFile$ = this.query.uploadedFile$;

    this.jobTaskId$ = this.query.jobTaskId$;
    this.jobTaskStatus$ = this.query.jobTaskStatus$;

    this.downloadJobTaskId$ = this.query.downloadJobTaskId$;
    this.downloadJobTaskStatus$ = this.query.downloadJobTaskStatus$;

    this.companyControl.valueChanges.subscribe(value => {
      this.uploadMaster.update('company', value);
    });

    const allCompanies = await this.uploadMaster.getAllCompanies().toPromise();
    this.uploadMaster.update('allCompanies', allCompanies);
  }

  ngAfterViewInit(): void {
    this.attachUploadFile();
  }

  attachUploadFile(): void {
    const cf = this.componentResolver.resolveComponentFactory(FileUploadComponent);
    const fileBaseUrl = this.apis.find(api => api.app === MasterUploadConfig[this.context.type].app).baseUrl;

    const injector = Injector.create({
      providers: [
        {
          provide: FILE_UPLOAD_CONFIG,
          useValue: {
            acceptedFileTypes: ['xlsx', 'xls'],
            maxFiles: 1,
            type: UploadFileType.SINGLE_UPLOAD,
            maxSize: '50mb',
            signedUrlEndpoint: `${fileBaseUrl}${MasterUploadConfig[this.context.type].signedUrlEndpoint}`,
            registerFileEndpoint: `${fileBaseUrl}${MasterUploadConfig[this.context.type].registerFileEndpoint}`
          }
        }
      ],
      parent: this.injector
    });

    const compRef = this.fileUploadVC.createComponent(cf, 0, injector);
    compRef.instance.type = UploadFileType.GROUP_UPLOAD;
    compRef.instance.showDragnDrop = false;
    compRef.instance.files = [];
    compRef.instance.groups = this.uploadConfig.singleGroup;
    compRef.instance.ngOnChanges({});
    compRef.instance.done.subscribe($event => {
      const [first] = $event;
      this.uploadMaster.update('uploadedFile', first);
      compRef.changeDetectorRef.detectChanges();
    });
  }

  onChange(message?: string) {
    this.change.next({
      type: this.query.type,
      success: false,
      message: 'Change',
      uploadedFile: this.query.uploadedFile,
      company: this.query.company,
      masterResponse: this.query.masterResponse
    });
  }

  async onDone() {
    const jobTaskId = this.query.jobTaskId;
    const jobTaskStatus = this.query.jobTaskStatus;

    if (jobTaskId && jobTaskStatus === JobStatus.Started) {
      return;
    }

    if (jobTaskId && (jobTaskStatus === JobStatus.Fail || jobTaskStatus === JobStatus.Success)) {
      this.done.next({
        type: this.query.type,
        success: true,
        message: 'Upload master done',
        uploadedFile: this.query.uploadedFile,
        company: this.query.company,
        masterResponse: this.query.masterResponse
      });

      return;
    }

    const res: any = await this.createJob()
      .toPromise()
      .catch(err => {
        this.done.next({
          type: this.query.type,
          success: false,
          message: 'Upload master failed',
          uploadedFile: this.query.uploadedFile,
          company: this.query.company,
          masterResponse: this.query.masterResponse
        });
      });

    this.uploadMaster.update('masterResponse', res.data);
    this.uploadMaster.update('jobTaskId', res.data.taskId);
    this.uploadMaster.update('jobTaskStatus', JobStatus.Started);
    this.cd.detectChanges();
    this.onChange();
  }

  onCancel() {
    this.onChange();
    this.cancel.next({
      type: this.query.type,
      success: false,
      message: 'Upload cancelled',
      uploadedFile: this.query.uploadedFile,
      company: this.query.company,
      masterResponse: this.query.masterResponse
    });
  }

  createJob() {
    const fileBaseUrl = this.apis.find(api => api.app === MasterUploadConfig[this.context.type].app).baseUrl;

    return this.uploadMaster.createJob(`${fileBaseUrl}${MasterUploadConfig[this.context.type].postEndPoint}`);
  }

  onJobDone($event) {
    this.uploadMaster.update('jobTaskStatus', $event.status);
    this.onChange();
  }

  onDownloadDone($event) {
    this.uploadMaster.update('downloadJobTaskStatus', $event.status);
    window.open($event.jobStats.downloadUrl, '_blank');
  }

  downloadSampleTemplate(): void {
    const sampleTemplateUrl = this.query.sampleTemplate;
    window.open(sampleTemplateUrl, '_blank');
  }

  async downloadMaster() {
    const fileBaseUrl = this.apis.find(api => api.app === MasterUploadConfig[this.context.type].app).baseUrl;

    const res = await this.uploadMaster.createJob(`${fileBaseUrl}${MasterUploadConfig[this.context.type].downloadEndpoint}`).toPromise();
    this.uploadMaster.update('downloadJobTaskId', res.data.taskId);
    this.uploadMaster.update('downloadJobTaskStatus', JobStatus.Started);
  }
}
