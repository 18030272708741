import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { LoaderState, LoaderStore } from './loader.store';

@Injectable({ providedIn: 'root' })
export class LoaderQuery extends Query<LoaderState> {
  constructor(protected store: LoaderStore) {
    super(store);
  }

  loading$: Observable<boolean> = this.select('loading');

  public get loading(): boolean {
    return this.getValue().loading;
  }
}
