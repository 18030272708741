import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { App } from '../../browserEnvConfig';
import { UploadMasterContext } from '../models/context';
import { IMasterUploadConfig } from '../models/upload';
import { UploadMasterEventData } from '../models/event';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UploadMasterState extends UploadMasterContext, IMasterUploadConfig, UploadMasterEventData {
  allCompanies: any[];
  jobTaskId: string;
  jobTaskStatus: string;
  downloadJobTaskId: string;
  downloadJobUrl: string;
  downloadJobTaskStatus: string;
}

export function createInitialState(): Partial<UploadMasterState> {
  return {
    app: App.COMMON_MASTER,
    signedUrlEndpoint: 'file-upload',
    registerFileEndpoint: 'files',
    isCompanyRequired: true,
    selectMultipleCompany: true,
    uploadedFile: null,
    steps: [],
    allCompanies: []
  };
}

@StoreConfig({ name: 'widget.uploadMaster' })
@Injectable()
export class UploadMasterStore extends Store<UploadMasterState> {
  constructor() {
    super(createInitialState());
  }
}
