<section class="image-viewer">
  <zet-image-viewer
    [zetImageViewerOption]="imgOption"
    [images]="[src]"
    (onZoomIn)="zoomIn()"
    (onZoomOut)="zoomOut()"
    (onZoomReset)="zoomReset()"
  >
  </zet-image-viewer>
  <zet-file-viewer-control
    *ngIf="!hasParent"
    (loaded)="loaded.emit($event)"
    (deleted)="deleted.emit($event)"
    data-testid="file-viewer-control"
  >
    <ng-content select="fileControlContent" ngProjectAs="fileControlContent"></ng-content>
  </zet-file-viewer-control>
</section>
