<div class="modal-header">
  <h4 class="modal-title">{{ header }}</h4>
  <button type="button" class="zbtn-close" aria-label="Close" (click)="cancel.next()">
    <span class="material-icons">close</span>
  </button>
</div>
<div class="modal-body">
  <zet-financial-dimension
    [config]="config"
    [financialDimension]="financialDimension"
    [fieldToDisable]="fieldToDisable"
    (done)="done.next($event)"
    (cancel)="cancel.next($event)"
    [companyId]="companyId"
  ></zet-financial-dimension>
</div>
<div class="modal-footer"></div>
