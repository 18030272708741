import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { User } from './auth.model';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  constructor(protected store: AuthStore) {
    super(store);
  }

  public get user(): User | null {
    return this.getValue().user;
  }

  public get roles(): any {
    return this.getValue().roles;
  }

  public get isLoggedIn(): boolean {
    return this.getValue().isLoggedIn;
  }

  public get appsEnabledDetails(): any {
    return this.getValue().appsEnabledDetails;
  }

  public get companies(): any {
    return this.getValue().companies;
  }
}
