import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderQuery } from './loader.query';
import { LoaderStore } from './loader.store';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private requestsInProgress = new Set();

  constructor(private store: LoaderStore, private query: LoaderQuery) {}

  getIsLoading(): Observable<boolean> {
    return this.query.loading$;
  }

  showLoader() {
    this.store.update({ loading: true });
  }

  hideLoader() {
    this.store.update({ loading: false });
  }

  addRequest(requestUrl: string) {
    this.requestsInProgress.add(requestUrl);
    if (!this.query.loading) {
      this.showLoader();
    }
  }

  removeRequest(requestUrl: string) {
    this.requestsInProgress.delete(requestUrl);
    if (!this.requestsInProgress.size) {
      this.hideLoader();
    }
  }
}
