/* eslint-disable no-plusplus */
import { MonoTypeOperatorFunction, timer } from 'rxjs';
import { scan, tap, switchMapTo, takeWhile, last } from 'rxjs/operators';

function attemptsGuardFactory(maxAttempts: number) {
  return (attempts: number) => {
    if (attempts > maxAttempts) {
      throw new Error('Exceeded maxAttempts');
    }
  };
}

export function pollWhile<T>(
  pollInterval: number,
  isPollingActive: (res: T) => boolean,
  maxAttempts = Infinity,
  emitOnlyLast = false
): MonoTypeOperatorFunction<T> {
  return source$ => {
    const poll$ = timer(0, pollInterval).pipe(
      scan(attempts => ++attempts, 0),
      tap(attemptsGuardFactory(maxAttempts)),
      switchMapTo(source$),
      takeWhile(isPollingActive, true)
    );

    return emitOnlyLast ? poll$.pipe(last()) : poll$;
  };
}
