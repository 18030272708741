/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-classes-per-file */
/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, skip, switchMap, take, tap } from 'rxjs/operators';
import { ITreeNode, TreeSelectComponent } from '../tree-select/tree-select.component';
import { ItemMasterFilterModel } from '../../models/filter';
import { ItemMasterQuery } from '../../services/item-master.query';
import { ItemMasterService } from '../../services/item-master.service';
import { ItemMasterState, ItemMasterStore } from '../../services/item-master.store';
import { ItemListComponent } from '../item-list/item-list.component';
import { ItemListMode } from '../../models/list';
import { HttpClient } from '@angular/common/http';
import { Widget } from '../../../widget';
import { ItemMasterContext, ItemMasterEventData } from '../../models/data';
import { RouterLinkTabs } from '../../../../tabs/directives/router-link-tabs.directive';
import { ITab } from '../../../../tabs/model/tabs';
import { browserEnvProviders, ServerConfig } from '../../../browserEnvConfig';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'zet-item-master-widget',
  templateUrl: './item-master-widget.component.html',
  styleUrls: ['./item-master-widget.component.scss'],
  providers: [...browserEnvProviders, ItemMasterService, ItemMasterStore, ItemMasterQuery]
})
export class ItemMasterWidgetComponent extends RouterLinkTabs implements OnInit, OnDestroy, Widget<ItemMasterContext, ItemMasterEventData> {
  @Input() widgetId: string;

  @Input() context: ItemMasterContext;

  @Input() header: string;

  @Output() done: EventEmitter<ItemMasterEventData> = new EventEmitter();

  @Output() cancel: EventEmitter<ItemMasterEventData> = new EventEmitter();

  @Output() change: EventEmitter<ItemMasterEventData> = new EventEmitter();

  searchControl = new FormControl();

  uomControl = new FormControl();

  productTypeControl = new FormControl();

  parentCategory$: Observable<ItemMasterState['parentCategory']>;

  companyId$: Observable<ItemMasterState['companyId']>;

  categoryType$: Observable<ItemMasterState['categoryType']>;

  productType$: Observable<ItemMasterState['productType']>;

  itemGroup$: Observable<ItemMasterState['itemGroup']>;

  searchText$: Observable<ItemMasterState['searchText']>;

  noResults$: Observable<ItemMasterState['noResults']>;

  emptyList$: Observable<ItemMasterState['emptyList']>;

  selectedItems$: Observable<ItemMasterState['selectedItems']>;

  unselectSelectedItems$: Observable<ItemMasterState['unselectSelectedItems']>;

  categories$: Observable<ItemMasterState['categories']>;

  uoms$: Observable<ItemMasterState['uoms']>;

  uom$: Observable<ItemMasterState['uom']>;

  productTypes$: Observable<ItemMasterState['productTypes']>;

  isCategoryLoading$: Observable<ItemMasterState['isCategoryLoading']>;

  isListLoading$: Observable<ItemMasterState['isListLoading']>;

  transactionName$: Observable<ItemMasterState['transactionName']>;

  categoryLabel$: Observable<ItemMasterState['categoryLabel']>;

  dataProvider;

  selectedDataProvider;

  listFilters: Partial<ItemMasterFilterModel>;

  @ViewChild(ItemListComponent) itemList: ItemListComponent;

  @ViewChild(TreeSelectComponent) treeSelect: TreeSelectComponent;

  @ViewChild(TabsetComponent) tabList: TabsetComponent;

  constructor(
    router: Router,
    route: ActivatedRoute,
    private item: ItemMasterService,
    private query: ItemMasterQuery,
    private cd: ChangeDetectorRef
  ) {
    super(router, route);
    this.setTabs(0);
  }

  async ngOnInit(): Promise<void> {
    this.parentCategory$ = this.query.parentCategory$;
    this.companyId$ = this.query.companyId$;
    this.categoryType$ = this.query.categoryType$;
    this.productType$ = this.query.productType$;
    this.uom$ = this.query.uom$;
    this.searchText$ = this.query.searchText$;
    this.noResults$ = this.query.noResults$;
    this.emptyList$ = this.query.emptyList$;
    this.selectedItems$ = this.query.selectedItems$;
    this.categories$ = this.query.categories$;
    this.uoms$ = this.query.uoms$;
    this.productTypes$ = this.query.productTypes$;
    this.unselectSelectedItems$ = this.query.unselectSelectedItems$;
    this.isCategoryLoading$ = this.query.isCategoryLoading$;
    this.isListLoading$ = this.query.isListLoading$;
    this.transactionName$ = this.query.transactionName$;
    this.categoryLabel$ = this.query.categoryLabel$;

    this.dataProvider = new DataProvider(this.item);
    this.selectedDataProvider = new SelectedDataProvider(this.item);

    const companyId = this.context?.companyId || '';

    const categoryType = this.context?.categoryType;

    const productType = this.context?.productType || null;
    this.productTypeControl.setValue(productType);

    this.item.update('transactionName', this.context?.transactionName);
    this.item.update('companyId', companyId);
    this.item.update('categoryType', categoryType);
    this.item.update('productType', productType);

    const categoryData = await this.item.getCategoryTree().toPromise();
    const uomData = await this.item.getUomSymbols().toPromise();
    const uoms = await this.filterUomSymbols(uomData);
    const productTypes = await this.item.getProductTypes().toPromise();

    this.item.update('categories', categoryData.data);
    this.item.update('uoms', uoms);
    this.item.update('productTypes', productTypes);

    this.item.removeItem.subscribe((items: any) => {
      this.itemList.clearSelection(items);
    });

    this.listFilters = {
      companyId,
      categoryType,
      productType
    };

    setTimeout(() => {
      this.item.update('selectedItems', this.context?.selectedItems || []);
      this.subscribeToFilters();
    }, 0);
  }

  filterUomSymbols(symbolData): any {
    let result = [];
    for (let bomSymbol of symbolData.data) {
      result.push(bomSymbol.symbol);
    }
    return result;
  }

  get itemListMetaTotal(): number {
    return this.dataProvider.listMeta?.total || 0;
  }

  subscribeToFilters(): void {
    this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe(currValue => {
      this.item.update('searchText', currValue);
    });

    this.uomControl.valueChanges.subscribe((value: string[]) => this.item.update('uom', value));

    this.productTypeControl.valueChanges.subscribe((value: string) => this.item.update('productType', value));

    combineLatest(this.companyId$, this.categoryType$, this.parentCategory$, this.uom$, this.productType$, this.searchText$)
      .pipe(skip(1), debounceTime(300))
      .subscribe(filters => {
        this.listFilters = {
          companyId: filters[0],
          categoryType: filters[1],
          parentCategory: filters[2],
          uom: filters[3],
          productType: filters[4],
          searchText: filters[5]
        };

        if (filters[5]) {
          this.gotoLink(this.tabs[0]);
        }
        this.cd.detectChanges();
      });
  }

  setTabs(activeIndex: number): void {
    this.tabs = [
      {
        label: 'Items',
        link: `items`,
        listMode: ItemListMode.LIST,
        selected: activeIndex === 0
      },
      {
        label: 'All Selected Items',
        link: `selected`,
        listMode: ItemListMode.SELECTED,
        selected: activeIndex === 1
      }
    ];
  }

  selectCategory(category: ITreeNode): void {
    this.item.update('parentCategory', category._id);
    this.item.update('categoryLabel', category.name);
    this.tabList.tabs[0].active = true;
  }

  removeItemsFromSelected(): void {
    this.item.removeItemsFromSelectedList(this.query.unselectSelectedItems);
  }

  onSubmit(): void {
    this.done.emit({ selectedItems: this.item.getSelectedItems() });
  }

  onCancel(): void {
    this.cancel.emit({ selectedItems: this.item.getSelectedItems() });
  }

  public gotoLink(tab: ITab): void {
    this.selectTab(tab);
  }

  onEmptyBtnClick(event: any): void {
    this.searchControl.setValue('');
    this.productTypeControl.setValue('');
    this.uomControl.setValue('');
    this.treeSelect.reset();
  }

  clearSearch(): void {
    this.searchControl.setValue('');
  }

  ngOnDestroy(): void {
    this.item.clear();
    this.searchControl.reset();
    this.productTypeControl.reset();
    this.uomControl.reset();
  }
}

class DataProvider {
  constructor(private item: ItemMasterService) {
    this.fetchData$ = this.fetchData.pipe(
      switchMap((value: any) => {
        return this.item.getItems({ context: value }).pipe(
          tap((res: any) => {
            if (res.success) {
              this.listMeta = res?.data?.meta;
            }
          })
        );
      })
    );
  }

  listMeta: any;

  fetchData = new BehaviorSubject('');

  fetchData$;

  /**
   * Get list data from API. Called when list is initialized, page change, filter change
   * @param {*} pageNumber
   * @param {*} filterValues - { customers: [a,b,c], bus: [123, 9001,2]}
   * @param {*} rowCount
   */
  getRows({ pageNumber = '1', filterQueryString = '', context = {} }: any) {
    if (!context.companyId) {
      return of({
        meta: {
          total: 0
        },
        data: {
          allDocuments: []
        }
      });
    }

    /** const { activeTab: status, searchText } = context; */
    const payload = {
      ...context,
      pageNumber
    };

    this.fetchData.next(payload);
    return new Observable(observer => {
      this.fetchData$.pipe(take(1)).subscribe((ev: any) => {
        observer.next(ev);
        observer.complete();
        this.fetchData$ = this.fetchData.pipe(
          switchMap((value: any) => {
            return this.item.getItems({ context: value }).pipe(
              tap((res: any) => {
                if (res.success) {
                  this.listMeta = res?.data?.meta;
                }
              })
            );
          })
        );
      });
    });
  }
}

class SelectedDataProvider {
  constructor(private item: ItemMasterService) {}

  /**
   * Get list data from API. Called when list is initialized, page change, filter change
   * @param {*} pageNumber
   * @param {*} filterValues - { customers: [a,b,c], bus: [123, 9001,2]}
   * @param {*} rowCount
   */
  lastItems;

  getRows({ pageNumber = '1', filterQueryString = '', context = {} }: any) {
    /** const { activeTab: status, searchText } = context;  */
    /* FIXME tempory hotfix for https://zetwerk.atlassian.net/browse/PLAT-1589 */
    let selectedItems = this.item.getSelectedItems();
    if (selectedItems === this.lastItems) {
      selectedItems = [];
    } else {
      this.lastItems = selectedItems;
    }

    return of({ data: { allDocuments: selectedItems } }).pipe(
      tap(() => {
        this.item.update('unselectSelectedItems', []);
      })
    );
  }
}
