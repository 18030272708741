import {
  Component,
  Input,
  OnInit,
  QueryList,
  ChangeDetectionStrategy,
  ContentChildren,
  OnChanges,
  ContentChild,
  SimpleChanges
} from '@angular/core';
import { Observable } from 'rxjs';
import { StepperHeaderDirective } from '../../directives/stepper-header.directive';
import { StepperItemDirective } from '../../directives/stepper-item.directive';
import { CreateFlowStepState, CreateFlowType, ICreateFlowStep } from '../../model/create-flow';
import { StepperStepDirective } from '../../directives/stepper-step.directive';
import { StepperItemService } from '../../services/stepper-item.service';
import { StepperService } from '../../services/stepper.service';
import { StepperQuery } from '../../state/stepper.query';
import { StepperStore } from '../../state/stepper.store';

@Component({
  selector: 'zet-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [StepperStore, StepperQuery, StepperService, StepperItemService]
})
export class StepperComponent implements OnChanges {
  @Input() steps: ICreateFlowStep[];

  @Input() type: CreateFlowType;

  @Input() customClass: string;

  @Input() jump = false;

  steps$: Observable<ICreateFlowStep[]>;

  type$: Observable<CreateFlowType>;

  @ContentChild(StepperStepDirective) stepperStepRef!: StepperStepDirective;

  @ContentChildren(StepperHeaderDirective) createFlowHeaderRefs!: QueryList<StepperHeaderDirective>;

  @ContentChildren(StepperItemDirective) createFlowItemsRefs!: QueryList<StepperItemDirective>;

  constructor(private itemService: StepperItemService, private stepperService: StepperService, private query: StepperQuery) {
    this.steps$ = this.query.steps$;
    this.type$ = this.query.type$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.stepperService.init({
      steps: this.steps,
      type: this.type,
      jump: this.jump
    });

    if (changes?.steps?.previousValue !== changes?.steps?.currentValue) {
      this.itemService.initStepState();
    }
  }

  get createFlowStepState(): typeof CreateFlowStepState {
    return CreateFlowStepState;
  }

  getcreateFlowItemTemplateRef(index: number): StepperItemDirective | StepperStepDirective {
    if (this.stepperStepRef) {
      return this.stepperStepRef;
    }
    return this.createFlowItemsRefs.toArray()[index];
  }

  getcreateFlowHeaderTemplateRef(index: number): StepperHeaderDirective {
    return this.createFlowHeaderRefs.toArray()[index];
  }

  next(id: string): void {
    this.stepperService.next(id);
  }

  goto(id: string): void {
    this.stepperService.goto(id);
  }
}
