import { NgModule } from '@angular/core';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { IEventAnalyticsConfig, EventAnalyticsConfig } from './event-analytics';
import { FilteredEventPlugin } from './filtered-event.plugin';
import { FilteredEventService } from './filtered-event.service';
import { GAService } from './providers/ga.service';

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class ZetEventAnalyticsModule {
  /* FIXME */
  // static forRoot(config: IEventAnalyticsConfig) {
  //   return {
  //     ngModule: ZetEventAnalyticsModule,
  //     providers: [
  //       FilteredEventService,
  //       GAService,
  //       {
  //         provide: EventAnalyticsConfig,
  //         useValue: config
  //       },
  //       {
  //         provide: EVENT_MANAGER_PLUGINS,
  //         useClass: FilteredEventPlugin,
  //         multi: true,
  //      }
  //     ]
  //   }
  // }
}
