import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterOnKey' })
export class FilterOnKeyPipe implements PipeTransform {
  transform(objects: Array<never>, key: string, value: string): Array<[]> {
    if (!key) return [];

    if (!value) return objects;

    return !!objects && objects.filter(file => file[key] === value);
  }
}
