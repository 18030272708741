import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FileViewerBase, IFileViewerFile, ThreedViewerBase } from '../../model/file-viewer';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { threedViewerDefaults } from '../../model/threed-viewer';
import { FileViewerService } from '../../service/file-viewer.service';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { FileViewerQuery } from '../../state/file-viewer.query';

@Component({
  selector: 'zet-threed-viewer',
  templateUrl: './threed-viewer.component.html',
  styleUrls: ['./threed-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreedViewerComponent extends ThreedViewerBase implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('canvas') private canvasRef!: ElementRef;

  private scene: THREE.Scene;

  private camera: THREE.PerspectiveCamera;

  private renderer: THREE.WebGLRenderer;

  private boundingBox: THREE.Box3;

  private controls: OrbitControls;

  name$: Observable<string>;

  private get canvas(): HTMLCanvasElement {
    return this.canvasRef.nativeElement;
  }

  constructor(@Optional() private parent: FileViewerBase, private query: FileViewerQuery, private fileViewer: FileViewerService) {
    super();
  }

  ngOnInit(): void {
    this.name$ = this.query.name$;
    setTimeout(() => {
      this.loaded.emit();
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.src?.previousValue && changes?.src?.currentValue !== changes?.src?.previousValue) {
      this.reset();
      this.initializeScene();
      this.fileViewer.updateStore({ key: 'src', value: this.src });
      if (!this.hasParent) {
        this.setFiles();
      }
    }

    if (changes?.name?.currentValue !== changes?.name?.previousValue) {
      this.fileViewer.updateStore({ key: 'name', value: this.name });
    }
  }

  get hasParent(): boolean {
    return this.parent && this.parent instanceof FileViewerComponent;
  }

  ngAfterViewInit(): void {
    this.initializeScene();
  }

  initializeScene() {
    this.camera = this.createCameraAndPosition();

    this.scene = this.createScene(threedViewerDefaults.lightPositions);
    this.scene.background = new THREE.Color(0xf8f8f8);

    this.renderer = this.createRenderer();

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    const animate = () => {
      requestAnimationFrame(animate);
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
    };
    animate();

    this.loadFile()
      .pipe(
        tap(ev => {
          if (ev instanceof ProgressEvent) {
            /* Update progess */
          }
        })
      )
      .subscribe(ev => {
        if (ev instanceof THREE.BufferGeometry) {
          this.addMeshToModel(ev as any);
          this.focusCameraToModel();
          const volume = this.calculateVolume(ev as any);
          const surfaceArea = this.calculateArea(ev as any);
          const coordinates = this.calculateModelDimensions();
          this.loaded.emit({ volume, surfaceArea, coordinates });
        }
      });
  }

  createCameraAndPosition() {
    const camera = new THREE.PerspectiveCamera(75, this.canvas.clientWidth / this.canvas.clientHeight, 0.1, 1000);
    camera.position.set(
      threedViewerDefaults.cameraPosition.x,
      threedViewerDefaults.cameraPosition.y,
      threedViewerDefaults.cameraPosition.z
    );
    return camera;
  }

  /**
   * @method private
   * Initializes a new Three.js Scene on DOM
   */
  private createScene(lightPositions: THREE.Vector3[]) {
    const scene = new THREE.Scene();

    for (const pos of lightPositions) {
      const light = new THREE.SpotLight();
      light.position.set(pos.x, pos.y, pos.z);
      scene.add(light);
    }

    return scene;
  }

  /**
   * @method private
   * Initializes a new Three.js model renderer
   */
  private createRenderer() {
    const renderer = new THREE.WebGLRenderer({ canvas: this.canvas });
    renderer.setPixelRatio(devicePixelRatio);
    renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    renderer.localClippingEnabled = true;
    return renderer;
  }

  /**
   * @method private
   * Load file based on fileType
   */
  private loadFile() {
    return new Observable<THREE.BoxGeometry | ProgressEvent>(observer => {
      let loader: STLLoader | OBJLoader;
      switch (this.type.toLocaleLowerCase()) {
        case 'stl':
        case 'step':
        case 'stp':
          loader = new STLLoader();
          break;
        case 'obj':
          loader = new OBJLoader();
          break;
        default:
          loader = new OBJLoader();
      }

      loader.load(
        this.src,
        (geometry: any) => {
          observer.next(geometry);
        },
        xhr => {
          observer.next(xhr);
        }
      );
    });
  }

  /**
   * @method private
   * @param geometry
   * Add mesh to rendered 3D model
   */
  private addMeshToModel(geometry: THREE.BoxGeometry) {
    const mesh = new THREE.Mesh(geometry, threedViewerDefaults.material);
    this.scene.add(mesh);
    mesh.geometry.center();
    this.boundingBox = mesh.geometry.boundingBox.clone();
  }

  /**
   * @method private
   * @param geometry
   * @returns 3D model volume
   * Calcuates volume of the rendered 3D model
   */
  private calculateVolume(geometry: THREE.BoxGeometry) {
    let position = geometry.attributes.position;
    let faces = position.count / 3;
    let volume = 0;
    let point1 = new THREE.Vector3();
    let point2 = new THREE.Vector3();
    let point3 = new THREE.Vector3();
    for (let i = 0; i < faces; i += 1) {
      point1.fromBufferAttribute(position, i * 3 + 0);
      point2.fromBufferAttribute(position, i * 3 + 1);
      point3.fromBufferAttribute(position, i * 3 + 2);
      volume += point1.dot(point2.cross(point3)) / 6.0;
    }
    return volume;
  }

  /**
   * @method private
   * @param geometry
   * @returns 3D model area
   * Calcuates area of the rendered 3D model
   */
  private calculateArea(geometry: THREE.BoxGeometry) {
    const trianle = new THREE.Triangle();
    const position = geometry.attributes.position;
    let area = 0;
    for (let i = 0; i < position.count; i += 3) {
      trianle.a.fromBufferAttribute(position, i);
      trianle.b.fromBufferAttribute(position, i + 1);
      trianle.c.fromBufferAttribute(position, i + 2);
      area += trianle.getArea();
    }
    return area;
  }

  /**
   * @method private
   * @returns 3D model dimensions
   * Calcuates dimensions i.e. lenght, breadth and height of the rendered 3D model
   */
  private calculateModelDimensions() {
    const coordinates = {
      x: this.boundingBox.max.x - this.boundingBox.min.x,
      y: this.boundingBox.max.y - this.boundingBox.min.y,
      z: this.boundingBox.max.z - this.boundingBox.min.z
    };
    return coordinates;
  }

  /**
   * @method private
   * Focus camera to model once rendering is complete
   */
  private focusCameraToModel() {
    const max = Math.max(this.boundingBox.max.x, this.boundingBox.max.y, this.boundingBox.max.z);
    this.camera.position.setZ(max);
  }

  reset(): void {
    this.fileViewer.reset();
  }

  setFiles(): void {
    let file: IFileViewerFile;
    file.name = this.name;
    file.src = this.src;
    file.type = this.type;
    file.selected = true;
    this.fileViewer.updateStore({ key: 'files', value: [file] });
  }
}
