<section class="pdf-viewer">
  <pdf-viewer
    [zetPDFViewerOption]="pdfOptions"
    [src]="src"
    [page]="page$ | async"
    [zoom]="zoom$ | async"
    (after-load-complete)="afterLoadComplete($event)"
  >
  </pdf-viewer>
  <zet-file-viewer-control
    *ngIf="!hasParent"
    (loaded)="loaded.emit($event)"
    (deleted)="deleted.emit($event)"
    data-testid="file-viewer-control"
  >
    <ng-content select="fileControlContent" ngProjectAs="fileControlContent"></ng-content>
  </zet-file-viewer-control>

  <div class="center">
    <div [class.hidden]="isLoaded" class="spinner--border" role="status" data-testid="loader"></div>
  </div>
</section>
