import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { FileViewerBase, IFileViewerFile, IImgOption, ImageViewerBase } from '../../model/file-viewer';
import { FileViewerService } from '../../service/file-viewer.service';
import { FileViewerQuery } from '../../state/file-viewer.query';

@Component({
  selector: 'zet-images-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageViewerComponent extends ImageViewerBase implements OnInit, OnChanges {
  @Input() imgOption: IImgOption;

  zoom$: Observable<number>;

  src$: Observable<string>;

  name$: Observable<string>;

  constructor(@Optional() private parent: FileViewerBase, private query: FileViewerQuery, private fileViewer: FileViewerService) {
    super();
  }

  ngOnInit(): void {
    this.zoom$ = this.query.zoom$;
    this.name$ = this.query.name$;
    setTimeout(() => {
      this.loaded.emit();
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.src?.currentValue !== changes?.src?.previousValue) {
      this.reset();
      this.fileViewer.updateStore({ key: 'src', value: this.src });
      if (!this.hasParent) {
        this.setFiles();
      }
    }

    if (changes?.name?.currentValue !== changes?.name?.previousValue) {
      this.fileViewer.updateStore({ key: 'name', value: this.name });
    }
  }

  get hasParent(): boolean {
    return this.parent && this.parent instanceof FileViewerComponent;
  }

  zoomIn(): void {
    this.fileViewer.zoomIn();
  }

  zoomOut(): void {
    this.fileViewer.zoomOut();
  }

  zoomReset(): void {
    this.fileViewer.zoomReset();
  }

  reset(): void {
    this.fileViewer.reset();
  }

  setFiles(): void {
    let file: IFileViewerFile;
    file.name = this.name;
    file.src = this.src;
    file.type = this.type;
    file.selected = true;
    this.fileViewer.updateStore({ key: 'files', value: [file] });
  }
}
