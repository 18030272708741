/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

type QueryParams =
  | HttpParams
  | {
      [param: string]: string | string[];
    };

export interface IRestClientOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: QueryParams;
  pathParams?: { [params: string]: string | number };
  reportProgress?: boolean;
  observe?: 'body' | 'events' | 'response';
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
}

export const END_POINTS = new InjectionToken('END_POINTS');

export interface IEndPoints {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class RestClientService {
  public endPoints: IEndPoints;

  constructor(@Optional() @Inject(END_POINTS) private _endPoints: IEndPoints[], private http: HttpClient) {
    this.convertToFlatEndPoints();
  }

  get(url: string, options?: IRestClientOptions): Observable<any> {
    return this.request({
      method: 'get',
      url,
      options
    });
  }

  post(url: string, body: any | null, options?: IRestClientOptions): Observable<any> {
    return this.request({
      method: 'post',
      url,
      options,
      body
    });
  }

  put(url: string, body: any | null, options?: IRestClientOptions): Observable<any> {
    return this.request({
      method: 'put',
      url,
      options,
      body
    });
  }

  patch(url: string, body: any | null, options?: IRestClientOptions): Observable<any> {
    return this.request({
      method: 'patch',
      url,
      options,
      body
    });
  }

  delete(url: string, body?: any | null, options?: IRestClientOptions): Observable<any> {
    return this.request({
      method: 'delete',
      url,
      options,
      body
    });
  }

  private request({
    method,
    url,
    options,
    body
  }: {
    method: string;
    url: string;
    options?: IRestClientOptions;
    body?: any;
  }): Observable<any> {
    let reqUrl = this.buildPath({ url, params: options?.pathParams || {} });

    this.isValidUrl(reqUrl);

    let query = this.buildQuery(options?.params);

    const headers = this.buildHeaders(options?.headers);

    const payload = this.buildBody(body);

    // Creating final url if query is string
    if (typeof query === 'string') {
      reqUrl = `${reqUrl}${query}`;
      query = new HttpParams();
    }

    return this.http.request(method, reqUrl, {
      body: payload || undefined,
      headers: headers || undefined,
      params: query || undefined,
      reportProgress: (options && options.reportProgress) || false,
      ...(options && options.observe && { observe: options.observe }),
      responseType: (options && options.responseType) || 'json'
    });
  }

  private convertToFlatEndPoints(): void {
    this.endPoints =
      this._endPoints &&
      this._endPoints.reduce((acc, current) => {
        return {
          ...acc,
          ...current
        };
      }, {});
  }

  private buildPath({ url, params }: { url: string; params?: Pick<IRestClientOptions, 'pathParams'> }): string {
    let newUrl = '';
    newUrl = Object.keys(params).reduce((acc, param) => {
      return acc.replace(`:${param}`, params[param].toString());
    }, url);
    return newUrl;
  }

  private buildQuery(params?: QueryParams): QueryParams | string {
    if (params instanceof HttpParams) {
      return params;
    }

    return this.serializeQueryParams(params);
  }

  private buildHeaders(headers: Pick<IRestClientOptions, 'headers'> | HttpHeaders): HttpHeaders {
    let newHeaders = new HttpHeaders();

    newHeaders = newHeaders.append('Content-Type', 'application/json');
    newHeaders = newHeaders.append('Accept', 'application/json');
    newHeaders = newHeaders.append('Access-Control-Allow-Origin', '*');

    if (!headers) {
      return newHeaders;
    }

    if (headers instanceof HttpHeaders) {
      return headers;
    }

    Object.keys(headers).forEach(key => {
      const value = headers[key];
      if (Array.isArray(value)) {
        value.forEach(v => (newHeaders = newHeaders.append(key, v)));
      } else {
        newHeaders = newHeaders.append(key, value);
      }
    });

    return newHeaders;
  }

  //
  private buildBody(body: any): any {
    return body;
  }

  // TODO refactor the code
  private isValidUrl(url: string): boolean {
    if ((url.match(new RegExp(':')) || []).length > 1) {
      throw new Error(`Request url is invalid ${url}`);
    }
    return true;
  }

  private serializeQueryParams(paramObj): string {
    if (paramObj) {
      return `?${Object.keys(paramObj)
        .filter(k => {
          return paramObj[k] !== undefined && paramObj[k] !== null;
        })
        .map(k => {
          if (typeof paramObj[k] === 'object' && !!paramObj[k]) {
            return paramObj[k].map(v => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&');
          }
          return `${encodeURIComponent(k)}=${encodeURIComponent(paramObj[k])}`;
        })
        .join('&')}`;
    }
    return '';
  }
}
