import { Component } from '@angular/core';
import { VersionCheckService } from 'projects/zetui/src/public-api';

declare let window;

@Component({
  selector: 'zet-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'zetui-demo';

  constructor() {
    window.zBrowserEnvConfig = {
      baseUrl: 'https://api.staging.zetwerk.com/common-masters/api/v1/'
    };
  }
}
