import { InjectionToken } from '@angular/core';
import { App } from '../../browserEnvConfig';

export interface PollingOptions {
  pollInterval: number;
  isPollingActive: (res: any) => boolean;
  maxAttempts: number;
  emitOnlyLast: boolean;
}

// FIXME Add options
export interface RefreshOptions {
  [key: string]: any;
}

// FIXME Add options
export interface ServerSideOptions {
  [key: string]: any;
}

export interface IServerFile {
  _id: string;
  Key: string;
  name: string;
  src: string;
  uploadedBy: string;
  fileType: string;
  fileSize: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IUser {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface AgendaJob {
  _id: string;
  name: string;
  data: {
    file: IServerFile;
    user: IUser;
    [key: string]: any;
  };
  priority: boolean;
  shouldSaveResult: boolean;
  maxRetry: number;
  retryInterval: number[];
  jobAttempt: number;
  lastModifiedBy: string;
  lockedAt: Date;
  lastRunAt: Date;
  status?: JobStatus;
  failCount: number;
  failReason: string;
  failedAt: Date;
  lastFinishedAt: Date;
  type: string;
  nextRunAt: Date;
  jobStats?: {
    erroReportUrl?: string;
    errorReportUrl?: string;
  };
}

export enum JobStatus {
  Started = 'Started',
  Running = 'Running',
  Fail = 'Fail',
  Success = 'Success'
}

export enum JobType {
  Upload = 'Upload',
  Download = 'Download'
}

export enum JobStatusRefreshType {
  POLL = 'poll',
  REFRESH = 'refresh',
  SSE = 'sse'
}

export const AgendaJobConfig = new InjectionToken<IAgendaJobConfig>('AgendaJobConfig');

export interface IAgendaJobConfig {
  app: App;
  jobNames: string[];
}
