/* eslint-disable no-underscore-dangle */
import { ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { PaginationService } from '../services/pagination.service';
import { IPaginationInstance, IPage } from '../model/pagination';
import { PaginationQuery } from '../states/pagination.query';

@Directive({
  selector: '[zetBasePaginationControls]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BasePaginationControls implements OnInit, OnChanges, OnDestroy {
  @Input() maxSize = 7;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() pageBoundsCorrection: EventEmitter<number> = new EventEmitter<number>();

  pages$: Observable<IPage[]>;

  private changeSub: Subscription;

  constructor(private service: PaginationService, private changeDetectorRef: ChangeDetectorRef, private query: PaginationQuery) {
    this.pages$ = this.query.pages$;
    this.changeSub = this.service.change.subscribe(() => {
      this.updatePageLinks();
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.updatePageLinks();
  }

  ngOnChanges(): void {
    this.updatePageLinks();
  }

  ngOnDestroy(): void {
    this.changeSub.unsubscribe();
  }

  previous(): void {
    // this.checkValidId();
    this.setCurrent(this.getCurrent() - 1);
  }

  next(): void {
    // this.checkValidId();
    this.setCurrent(this.getCurrent() + 1);
  }

  isFirstPage(): boolean {
    return this.getCurrent() === 1;
  }

  isLastPage(): boolean {
    return this.getLastPage() === this.getCurrent();
  }

  setCurrent(page: number): void {
    this.pageChange.emit(page);
  }

  getCurrent() {
    return this.service.getCurrentPage();
  }

  getLastPage(): number {
    let inst = this.service.getInstance();
    if (inst.totalItems < 1) {
      return 1;
    }
    return Math.ceil(inst.totalItems / inst.itemsPerPage);
  }

  getTotalItems(): number {
    return this.service.getInstance().totalItems;
  }

  private checkValidId() {
    // if (this.service.getInstance(this.id).id == null) {
    // }
  }

  private updatePageLinks() {
    let inst = this.service.getInstance();
    let pages = [];
    const correctedCurrentPage = this.outOfBoundCorrection(inst);

    if (correctedCurrentPage !== inst.currentPage) {
      setTimeout(() => {
        this.pageBoundsCorrection.emit(correctedCurrentPage);
        pages = this._createPageArray(inst.currentPage, inst.itemsPerPage, inst.totalItems, this.maxSize);
      });
    } else {
      pages = this._createPageArray(inst.currentPage, inst.itemsPerPage, inst.totalItems, this.maxSize);
    }
    this.service.setPages(pages);
  }

  private outOfBoundCorrection(instance: IPaginationInstance): number {
    const totalPages = Math.ceil(instance.totalItems / instance.itemsPerPage);
    if (totalPages < instance.currentPage && totalPages > 0) {
      return totalPages;
    }
    if (instance.currentPage < 1) {
      return 1;
    }

    return instance.currentPage;
  }

  _createPageArray(currentPage: number, itemsPerPage: number, totalItems: number, paginationRange: number): IPage[] {
    let pageRange = +paginationRange;
    let pages = [];

    const totalPages = Math.max(Math.ceil(totalItems / itemsPerPage), 1);
    const halfWay = Math.ceil(pageRange / 2);

    const isStart = currentPage <= halfWay;
    const isEnd = totalPages - halfWay < currentPage;
    const isMiddle = !isStart && !isEnd;

    let ellipsesNeeded = pageRange < totalPages;
    let i = 1;

    while (i <= totalPages && i <= pageRange) {
      let label;
      let pageNumber = this.calculatePageNumber(i, currentPage, pageRange, totalPages);
      let openingEllipsesNeeded = i === 2 && (isMiddle || isEnd);
      let closingEllipsesNeeded = i === pageRange - 1 && (isMiddle || isStart);
      if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
        label = '...';
      } else {
        label = pageNumber;
      }
      pages.push({
        label,
        value: pageNumber
      });
      i += 1;
    }
    return pages;
  }

  private calculatePageNumber(i: number, currentPage: number, paginationRange: number, totalPages: number) {
    let halfWay = Math.ceil(paginationRange / 2);
    if (i === paginationRange) {
      return totalPages;
    }
    if (i === 1) {
      return i;
    }
    if (paginationRange < totalPages) {
      if (totalPages - halfWay < currentPage) {
        return totalPages - paginationRange + i;
      }
      if (halfWay < currentPage) {
        return currentPage - halfWay + i;
      }
      return i;
    }
    return i;
  }
}
