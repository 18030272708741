import { Component, OnInit } from '@angular/core';
import { RouterLinkTabs } from 'projects/zetui/src/public-api';

@Component({
  selector: 'zet-contributions',
  templateUrl: './contributions.component.html',
  styleUrls: ['./contributions.component.scss']
})
export class ContributionsComponent extends RouterLinkTabs implements OnInit {
  ngOnInit() {
    this.setTabs();
  }

  setTabs(): void {
    this.tabs = [
      {
        label: 'Saravyas',
        link: '',
        selected: true
      },
      {
        label: 'Soumyendra Shrivastava',
        link: '',
        selected: false
      },
      {
        label: 'Sankar',
        link: '',
        selected: false
      },
      {
        label: 'Mohit Yadav',
        link: '',
        selected: false
      }
    ];
  }
}
