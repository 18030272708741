/* eslint-disable no-underscore-dangle */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationControlType } from '../../model/pagination';

@Component({
  selector: 'zet-pagination-controls',
  templateUrl: './pagination-controls.component.html',
  styleUrls: ['./pagination-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationControlsComponent {
  // TODO will remove this after dynamic component implementation
  @Input() type: PaginationControlType = PaginationControlType.DEFAULT;

  @Input() maxSize = 7;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() pageBoundsCorrection: EventEmitter<number> = new EventEmitter<number>();

  // TODO will remove this after dynamic component implementation
  paginationControlType = PaginationControlType;
}
