import { InjectionToken } from '@angular/core';

export interface IHttpConfig {
  errorMessage?: { [key in HTTPErrorTypes]: { msg: string; des: string } };
  [key: string]: any;
}

export const HTTP_CONFIG = new InjectionToken<IHttpConfig>('HttpConfig');

export enum HTTPErrorTypes {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  GATEWAY_TIMEOUT = 504,
  UNKNOWN_ERROR = 0
}

export const MaintainenceErrorStatuses = [HTTPErrorTypes.BAD_GATEWAY, HTTPErrorTypes.GATEWAY_TIMEOUT];
