import { Directive, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({})
export class Widget<S, V> {
  @Input() widgetId: string;

  @Input() context: S;

  @Input() header: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: Subject<V> = new Subject();

  @Output() done: Subject<V> = new Subject();

  @Output() cancel: Subject<V> = new Subject();
}
