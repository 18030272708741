import { UploadFile } from '../model/file-upload';

// util for file upload service
export const convertFileToUploadFile = ({ fileList, groupId }: { fileList: FileList; groupId: string }): UploadFile[] => {
  const files = Array.from(fileList);
  return files.map(file => new UploadFile({ file, groupId }));
};

// util for fs
export const convertToBytes = (value: string): number => {
  if (value === '') {
    return 0;
  }
  const e = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const num = +value.slice(0, -2);
  const unitIndex = e.indexOf(value.slice(-2).toUpperCase()) + 1;
  if (unitIndex < 0) {
    return 0;
  }
  return num * 1024 ** unitIndex;
};

export const formatBytes = (a: number, b: number): string => {
  if (a === 0) {
    return '0 Bytes';
  }
  // using the decimal convention
  const c = 1000;
  const d = b || 2;
  const e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const f = Math.floor(Math.log(a) / Math.log(c));
  return `${parseFloat((a / c ** f).toFixed(d))} ${e[f]}`;
};
