import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { SidemenuContainerComponent } from './components/sidemenu-container/sidemenu-container.component';
import { SidemenuContentComponent } from './components/sidemenu-content/sidemenu-content.component';
import { SidemenuItemComponent } from './components/sidemenu-item/sidemenu-item.component';

import { SidemenuFooterInfoDirective } from './directives/sidemenu-footer-info.directive';
import { SidemenuHeaderInfoDirective } from './directives/sidemenu-header-info.directive';
/* TODO add token for Sidemenu config */
@NgModule({
  declarations: [
    SidemenuComponent,
    SidemenuContainerComponent,
    SidemenuContentComponent,
    SidemenuItemComponent,
    SidemenuFooterInfoDirective,
    SidemenuHeaderInfoDirective
  ],
  imports: [CommonModule],
  exports: [
    SidemenuComponent,
    SidemenuContainerComponent,
    SidemenuContentComponent,
    SidemenuItemComponent,
    SidemenuFooterInfoDirective,
    SidemenuHeaderInfoDirective
  ],
  providers: []
})
export class SidemenuModule {}
