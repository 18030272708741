import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidemenuModule } from 'projects/zetui/src/components/sidemenu';
import { StepperModule } from 'projects/zetui/src/components/stepper';
import { TimelineModule, TIMELINE_CONFIG } from 'projects/zetui/src/components/timeline';
import { FileUploadModule, FILE_UPLOAD_CONFIG } from 'projects/zetui/src/components/file-upload';
import { FileViewerModule } from 'projects/zetui/src/components/file-viewer';
import { PaginationModule } from 'projects/zetui/src/components/pagination';
import { SpyScrollModule } from 'projects/zetui/src/components/spy-scroll';
import { MarkdownModule } from 'ngx-markdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { PopoverHoverModule } from '@zetwerk/zet-popover-hover';
import { CdkTableModule } from '@angular/cdk/table';
import { CurrencyPipeModule } from 'projects/zetui/src/utils/currency-pipe';
import { ZetListModule } from '@zetwerk/zet-list';
import {
  AddressModule,
  FinancialDimensionModule,
  ItemMasterModule,
  UploadMasterModule,
  BankMasterModule,
  WidgetModule
} from 'projects/zetui/src/components/widgets';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from '../@core/interceptor/http-interceptor';
import { timelineConfig } from '../@core/configs/timeline-config';
import { fileUploadConfig } from '../@core/configs/file-upload-config';
import { HelperService } from '../@core/services/helper.service';
import { ModalModule as ZetModalModule } from 'projects/zetui/src/components/modal/modal.module';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { PageHeaderComponent } from './components/header/page-header.component';
import { StatusCellComponent } from '../pages/libraries/components/zet-list/table-components/status-cell/status-cell.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { BROWSER_ENV, Env } from 'projects/zetui/src/components/widgets/browserEnvConfig';
import { ZetAppSharedModule } from 'projects/zetui/src/zet-app-shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    FileViewerModule,
    StepperModule,
    SidemenuModule,
    TimelineModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    HttpClientModule,
    PopoverHoverModule,
    PaginationModule,
    SpyScrollModule,
    CdkTableModule,
    CurrencyPipeModule,
    TabsModule,
    ZetModalModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    ZetListModule.forRoot({
      frameworkComponents: {
        statusCellRenderer: StatusCellComponent
      }
    }),
    AddressModule,
    FinancialDimensionModule,
    ItemMasterModule,
    UploadMasterModule,
    BankMasterModule,
    WidgetModule,
    ZetAppSharedModule
  ],
  declarations: [SafeUrlPipe, PageHeaderComponent, ComingSoonComponent, StatusCellComponent],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    FileViewerModule,
    StepperModule,
    SidemenuModule,
    TimelineModule,
    TabsModule,
    ModalModule,
    ToastrModule,
    HttpClientModule,
    PopoverHoverModule,
    PaginationModule,
    SpyScrollModule,
    CdkTableModule,
    CurrencyPipeModule,
    TabsModule,
    ZetModalModule,
    MarkdownModule,
    ZetListModule,
    AddressModule,
    FinancialDimensionModule,
    ItemMasterModule,
    SafeUrlPipe,
    PageHeaderComponent,
    ComingSoonComponent,
    BankMasterModule,
    WidgetModule,
    ZetAppSharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    { provide: TIMELINE_CONFIG, useValue: timelineConfig },
    HelperService,
    { provide: FILE_UPLOAD_CONFIG, useValue: fileUploadConfig },
    { provide: BROWSER_ENV, useValue: Env.staging }
  ],
  entryComponents: [StatusCellComponent]
})
export class SharedModule {}
