import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { TimelineState, TimelineStore } from './timeline.store';

@Injectable({ providedIn: 'root' })
export class TimelineQuery extends Query<TimelineState> {
  timelineSteps$ = this.select('timelineSteps');

  constructor(protected store: TimelineStore) {
    super(store);
  }
}
