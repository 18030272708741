import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { ModalFooterComponent } from './components/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ModalComponent, ModalContentComponent, ModalFooterComponent, ModalHeaderComponent],
  exports: [ModalComponent, ModalContentComponent, ModalFooterComponent, ModalHeaderComponent]
})
export class ModalModule {}
