import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './components/stepper/stepper.component';
import { StepperItemComponent } from './components/stepper-item/stepper-item.component';

import { StepperHeaderDirective } from './directives/stepper-header.directive';
import { StepperItemDirective } from './directives/stepper-item.directive';
import { StepperStepDirective } from './directives/stepper-step.directive';

// import { CreateFlowService } from './services/create-flow.service';
// import { CreateFlowStepperService } from './services/create-flow-stepper.service';

@NgModule({
  imports: [CommonModule],
  declarations: [StepperComponent, StepperItemComponent, StepperHeaderDirective, StepperItemDirective, StepperStepDirective],
  exports: [StepperComponent, StepperItemComponent, StepperHeaderDirective, StepperItemDirective, StepperStepDirective]
  // providers: [CreateFlowService, CreateFlowStepperService]
})
export class StepperModule {}
