import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { SidemenuMode } from '../../models/sidemenu';
import { SidemenuService } from '../../sidemenu.service';
import { SidemenuQuery } from '../../state/sidemenu.query';

@Component({
  selector: 'zet-sidemenu-container',
  templateUrl: './sidemenu-container.component.html',
  styleUrls: ['./sidemenu-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidemenuContainerComponent {
  @Input() hasBackdrop = false;

  // TODO Move this to store
  @Input() mode: SidemenuMode = SidemenuMode.SIDE;

  isOpen$: Observable<boolean> = this.sidemenuQuery.isOpen$;

  constructor(private sidemenuService: SidemenuService, private sidemenuQuery: SidemenuQuery) {}

  onBackdropClick(): void {
    // TODO Create event in service
    this.sidemenuService.setIsOpen(false);
  }
}
