<zet-sidemenu-container [hasBackdrop]="hasBackdrop" [mode]="sidemenuMode">
  <zet-sidemenu
    [items]="sidemenuItems"
    [isOpen]="false"
    [trigger]="'hover'"
    [headerTemplate]="headderTemplate"
    [footerTemplate]="footerTemplate"
    (toggle)="toggle($event)"
  ></zet-sidemenu>
  <zet-sidemenu-content>
    <nav class="navbar">
      <!-- Navbar content -->
      <div class="navbar-left">
        <span class="navbar-logo">ZetUI</span>
        <a
          class="navbar-link"
          href="https://docs.google.com/document/d/1PEcdcoIXTu32DHK_9igZKsqfxgv8P2BboJpVq1YxQ5I/edit?usp=sharing"
          target="_blank"
          >Docs</a
        >
        <a class="navbar-link" href="https://docs.google.com/document/d/1qRFWEwHglRY-406AMI9XvE_gY6C0laMjFI5b6NzXogI/edit" target="_blank"
          >CSS Framwork</a
        >
        <a class="navbar-link" href="https://docs.google.com/document/d/1yiqanKjn1o15a-WlRsGvc8iZLAge_UDKjvoZRVeVS8I/edit" target="_blank"
          >Angular Components</a
        >
        <a class="navbar-link" href="https://docs.google.com/document/d/1D5X15vqh1sA-Fvh6lZQLwjDqsQISB0_yhHX70ru41Xk/edit" target="_blank"
          >Testing Framework</a
        >
      </div>
      <div class="navbar-right">
        <div>
          <a class="navbar-link">v0.0.6</a>
          <a class="navbar-link github" href="https://github.com/zetwerk/zetui/tree/sprint-1/demo" target="_blank">
            <img class="github-icon" src="/assets/svg/github.svg" />
            Github
          </a>
        </div>
      </div>
    </nav>
    <section *ngIf="pageHeader" class="navbar-sub">
      <!-- Navbar content -->
      {{ pageHeader }}
    </section>
    <router-outlet></router-outlet>
  </zet-sidemenu-content>
</zet-sidemenu-container>

<ng-template zetSidemenuHeaderInfo>
  <p class="welcome">Welcome back</p>
</ng-template>

<ng-template #headderTemplate let-close="close">
  <div class="sidemenu-header">
    <div class="brand-zetwerk"></div>
    <button class="zet-btn-close" (click)="close()">x</button>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <img src="assets/svg/logout.svg" />
  <button class="btn-logout">Logout</button>
</ng-template>

<!-- <section> Move template down and pass it as input </section> -->
