import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, Optional } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { Observable } from 'rxjs';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { FileViewerBase, PDFViewerBase, IPDFOption, IFileViewerFile } from '../../model/file-viewer';
import { FileViewerQuery } from '../../state/file-viewer.query';
import { FileViewerStore } from '../../state/file-viewer.store';
import { FileViewerService } from '../../service/file-viewer.service';

@Component({
  selector: 'zet-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewerComponent extends PDFViewerBase implements OnInit, OnChanges {
  @Input() pdfOptions: IPDFOption;

  page$: Observable<number>;

  zoom$: Observable<number>;

  isLoaded = false;

  constructor(
    @Optional() private parent: FileViewerBase,
    private query: FileViewerQuery,
    private store: FileViewerStore,
    private fileViewer: FileViewerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.page$ = this.query.page$;
    this.zoom$ = this.query.zoom$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.page?.currentValue !== changes?.page?.previousValue) {
      this.fileViewer.updateStore({ key: 'page', value: this.page });
    }

    if (changes?.src?.currentValue !== changes?.src?.previousValue) {
      this.reset();
      this.fileViewer.updateStore({ key: 'src', value: this.src });
      if (!this.hasParent) {
        this.setFiles();
      }
    }

    if (changes?.name?.currentValue !== changes?.name?.previousValue) {
      this.fileViewer.updateStore({ key: 'name', value: this.name });
    }
  }

  get hasParent(): boolean {
    return this.parent && this.parent instanceof FileViewerComponent;
  }

  afterLoadComplete(pdf: PDFDocumentProxy): void {
    this.isLoaded = true;
    this.loaded.emit(pdf);
    this.fileViewer.updateStore({ key: 'totalPage', value: pdf?.numPages });
  }

  reset(): void {
    this.isLoaded = false;
    this.fileViewer.reset();
  }

  setFiles(): void {
    let file: IFileViewerFile;
    file.name = this.name;
    file.src = this.src;
    file.type = this.type;
    file.selected = true;
    this.fileViewer.updateStore({ key: 'files', value: [file] });
  }
}
