import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '../social-login';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { LoginService } from './login.service';
import { ILoginConfig, IAuthApiData } from './login.model';

@Component({
  selector: 'zet-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.scss']
})
export class GoogleLoginComponent implements OnInit {
  title!: { text: string; subText: string };

  @Output() onLogin: EventEmitter<IAuthApiData> = new EventEmitter<null>();

  @Output() onError: EventEmitter<any> = new EventEmitter<null>();

  constructor(private socialAuthService: SocialAuthService, private authService: AuthService, private loginService: LoginService) {}

  ngOnInit() {
    this.socialAuthService.authState.pipe(take(1)).subscribe((user: SocialUser) => {
      if (user.idToken) {
        this.authenticateWithCode(user.idToken);
      }
    });

    this.loginService.logout$.subscribe(() => {
      this.socialAuthService.signOut();
    });

    this.title = this.loginService.getUI().title || { text: 'Zetwerk', subText: 'Sign In to' };
  }

  async socialSignIn() {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  async getCode() {
    this.socialAuthService.getOauth2Code(GoogleLoginProvider.PROVIDER_ID);
  }

  async authenticateWithToken(idToken: string) {
    const authResponse$: Observable<any> = this.authService.authenticate(idToken);
    const authResponse: any = await authResponse$.toPromise();

    if (!authResponse.success) return;

    this.onLogin.emit();
  }

  async authenticateWithCode(code: string) {
    const authResponse$: Observable<any> = this.authService.authenticateCode(code);
    const authResponse: any = await authResponse$.toPromise().catch(err => {
      this.onError.emit(err);
    });
    // console.log(authResponse);
    // console.log(authResponse.headers.keys());
    if (!authResponse?.success) return;
    this.onLogin.emit(this.authService.getUserFromStore());
  }
}
