import { Injectable, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ISidemenuItem } from './models/sidemenu';
import { SidemenuQuery } from './state/sidemenu.query';
import { SidemenuStoreService } from './state/sidemenu.store.service';

@Injectable({ providedIn: 'root' })
export class SidemenuService {
  menuClicked$: Subject<unknown> = new Subject();

  constructor(private sidemenuStoreService: SidemenuStoreService, private sidemenuQuery: SidemenuQuery, private router: Router) {}

  async handleMenuClick(index: number): Promise<void> {
    const items: ISidemenuItem[] = this.sidemenuQuery.items;

    // TODO proper validation
    // if (items[index].selected) {
    //   return;
    // }

    const isNavigationCompleted = await this.router.navigate([items[index].path]);

    if (isNavigationCompleted) {
      const preIndexItems = items.slice(0, index).map(t => {
        return { ...t, selected: false };
      });
      const currentItem = { ...items[index], selected: true };
      const postIndexItems = items.slice(index + 1).map(t => {
        return { ...t, selected: false };
      });
      const newItens = [...preIndexItems, currentItem, ...postIndexItems];

      this.sidemenuStoreService.setItems(newItens);
      this.sidemenuStoreService.setActiveItemIndex(index);
      this.sidemenuStoreService.setActiveItem(items[index]);
      this.menuClicked$.next(index);
    }
  }

  getActiveItem(): ISidemenuItem {
    return this.sidemenuQuery.activeItem;
  }

  setIsOpen(isOpen: boolean): void {
    this.sidemenuStoreService.setIsOpen(isOpen);
  }

  setItems(items: ISidemenuItem[]): void {
    this.sidemenuStoreService.setItems(items);
  }

  setHeaderInfoTemplate(headerInfoTemplate: TemplateRef<unknown>): void {
    this.sidemenuStoreService.setHeaderInfoTemplate(headerInfoTemplate);
  }

  setFooterInfoTemplate(footerInfoTemplate: TemplateRef<unknown>): void {
    this.sidemenuStoreService.setFooterInfoTemplate(footerInfoTemplate);
  }
}
