import { InjectionToken } from '@angular/core';

export const DIGITS_INFO = new InjectionToken<string>('Digit_info');

export const lessThanTwenty = [
  '',
  'One ',
  'Two ',
  'Three ',
  'Four ',
  'Five ',
  'Six ',
  'Seven ',
  'Eight ',
  'Nine ',
  'Ten ',
  'Eleven ',
  'Twelve ',
  'Thirteen ',
  'Fourteen ',
  'Fifteen ',
  'Sixteen ',
  'Seventeen ',
  'Eighteen ',
  'Nineteen '
];

export const tenth = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

export const suffix = ['', 'Hundred ', 'Crore ', 'Lakh ', 'Thousand ', 'Hundred ', ''];
