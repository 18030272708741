import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MimeType } from '../../file-upload/model/file-upload';
import { IFileViewerFile } from '../model/file-viewer';

export interface FileViewerState {
  zoom: number;
  src: string;
  name: string;
  type: MimeType;
  page: number;
  totalPage: number;
  files: IFileViewerFile[];
}

export function createInitialState(): FileViewerState {
  return {
    zoom: 1,
    src: '',
    name: '',
    type: undefined,
    page: 1,
    totalPage: 1,
    files: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'zetui.file-viewer' })
export class FileViewerStore extends Store<FileViewerState> {
  constructor() {
    super(createInitialState());
  }
}
