/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { App, BrowserEnvConfig, browserEnvProviders, BrowserEnvToken, ServerConfig } from '../../../browserEnvConfig';
import { DimensionConfig, FinancialDimensionField, IFieldToDisable, validationMsg } from './model';

interface Response {
  success: boolean;
  data: any;
}

@Component({
  selector: 'zet-financial-dimension',
  templateUrl: './financial-dimension.component.html',
  styleUrls: ['./financial-dimension.component.scss'],
  providers: [...browserEnvProviders]
})
export class FinancialDimensionComponent implements OnInit {
  @Input() financialDimension: FinancialDimensionField = {};

  @Input() companyId: string;

  @Input() fieldToDisable: IFieldToDisable;

  @Output() done: EventEmitter<any> = new EventEmitter();

  @Output() cancel: EventEmitter<any> = new EventEmitter();

  name: string;

  segments = [];

  businessUnits = [];

  originalBusinessUnits = [];

  subBusinessUnits = [];

  factories = [];

  regions = [];

  @Input() config: DimensionConfig;

  baseUrl: string;

  validationMsg = validationMsg;

  fieldToShowConfig: DimensionConfig['fieldToShow'] = {
    segment: true,
    businessUnit: true,
    subBusinessUnit: true,
    factory: true,
    region: true
  };

  zetAuth: ServerConfig;

  commonMaster: ServerConfig;

  constructor(private http: HttpClient, @Inject(BrowserEnvToken) private apis: BrowserEnvConfig[]) {
    this.zetAuth = this.apis.find(api => api.app === App.ZETAUTH);
    this.commonMaster = this.apis.find(api => api.app === App.COMMON_MASTER);
  }

  ngOnInit(): void {
    this.financialDimension = this.financialDimension || {};
    this.baseUrl = `${this.zetAuth.baseUrl}`;
    this.fieldToShowConfig = this.config?.fieldToShow || this.fieldToShowConfig;
    if (this.companyId) {
      this.getSegments();
      const segment = this.financialDimension?.segment;
      // eslint-disable-next-line no-underscore-dangle
      this.getBusinessUnits(segment?._id);
    }
  }

  getSegments(): void {
    const options = {
      params: {}
    };

    options.params = {
      companyId: this.companyId
    };

    this.http.get(`${this.baseUrl}company/${this.companyId}`, options).subscribe((response: Response) => {
      if (response.success) {
        const segments = response?.data?.segments || [];
        this.segments = segments?.map((result: any) => {
          return {
            // eslint-disable-next-line no-underscore-dangle
            _id: result?._id,
            name: result?.name,
            code: result?.uniqueCode
          };
        });
        const segmentId = this.financialDimension?.segment?._id;
        if (segmentId) {
          this.getObject(this.segments, 'segment', segmentId);
        }
      }
    });
  }

  getRegions(businessUnitId: string): void {
    if (!businessUnitId) {
      return;
    }
    this.http.get(`${this.baseUrl}region/getRegionBySubBuId/${businessUnitId}`).subscribe((response: Response) => {
      if (response.success) {
        this.regions = response.data?.map((result: any) => {
          return {
            // eslint-disable-next-line no-underscore-dangle
            _id: result?._id,
            name: result?.name,
            code: result?.uniqueCode
          };
        });
        const regionId = this.financialDimension?.region?._id;
        if (regionId) {
          this.getObject(this.regions, 'region', regionId);
        }
      }
    });
  }

  getFatories(businessUnitId: any): void {
    if (!businessUnitId) {
      return;
    }

    const businessUnit = this.getBusinessUnit(businessUnitId);
    const factories = businessUnit?.erpFactories || [];
    this.factories = factories.map((result: any) => {
      return {
        // eslint-disable-next-line no-underscore-dangle
        _id: result?._id,
        name: result?.name,
        code: result?.uniqueCode
      };
    });
    const factoryId = this.financialDimension?.factory?._id;
    if (factoryId) {
      this.getObject(this.factories, 'factory', factoryId);
    }
  }

  getBusinessUnits(segmentId: string): void {
    if (!segmentId) {
      return;
    }
    const companyId = this.companyId;
    this.http
      .get(`${this.baseUrl}business-unit/getBusinessUnitByQuery?segments._id=${segmentId}&company.companyId=${companyId}`)
      .subscribe((response: Response) => {
        if (response.success) {
          this.originalBusinessUnits = response.data || [];
          this.businessUnits = response.data?.map((result: any) => {
            return {
              // eslint-disable-next-line no-underscore-dangle
              _id: result?._id,
              name: result?.name,
              code: result?.uniqueCode,
              segments: result?.segments,
              projectTypeIds: result?.projectTypeIds,
              subBusinessUnits: result?.subBusinessUnits,
              erpFactories: result?.erpFactories
            };
          });
          const businessUnitId = this.financialDimension?.businessUnit?._id;
          if (businessUnitId) {
            this.getObject(this.businessUnits, 'businessUnit', businessUnitId);
            this.getSubBusinessUnits(businessUnitId);
            this.getFatories(businessUnitId);
            this.getRegions(businessUnitId);
          }
        }
      });
  }

  getBusinessUnit(businessUnitId: string) {
    return this.originalBusinessUnits.find(businessUnit => businessUnit._id === businessUnitId);
  }

  getSubBusinessUnits(businessUnitId: any): void {
    if (!businessUnitId) {
      return;
    }
    const businessUnit = this.getBusinessUnit(businessUnitId);
    const subBusinessUnits = businessUnit?.subBusinessUnits || [];
    this.subBusinessUnits = subBusinessUnits?.map((result: any) => {
      return {
        // eslint-disable-next-line no-underscore-dangle
        _id: result?._id,
        name: result?.name,
        code: result?.uniqueCode
      };
    });
    const subBusinessUnitId = this.financialDimension?.subBusinessUnit?._id;
    if (subBusinessUnitId) {
      this.getObject(this.subBusinessUnits, 'subBusinessUnit', subBusinessUnitId);
    }
  }

  onDataChange(event: { _id: string }, type: string): void {
    switch (type) {
      case 'SEGMENT':
        // eslint-disable-next-line no-underscore-dangle
        this.getBusinessUnits(event._id);
        this.financialDimension = {
          ...this.financialDimension,
          businessUnit: null,
          subBusinessUnit: null,
          factory: null,
          region: null
        };
        break;
      case 'BUSINESS_UNIT':
        this.getSubBusinessUnits(event._id);
        this.getFatories(event._id);
        this.getRegions(event._id);
        this.financialDimension = {
          ...this.financialDimension,
          subBusinessUnit: null,
          factory: null,
          region: null
        };
        break;
      default:
      // code block
    }
  }

  getObject(data: any[], key: string | number, id: any) {
    this.financialDimension[key] = data.find((d: { _id: any }) => d._id === id);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public save(form: NgForm): void {
    if (form.valid) {
      this.done.emit(this.financialDimension);
    } else {
      Object.keys(form.controls).forEach(key => {
        form.controls[key].markAsTouched();
      });
    }
  }

  public onCancel(): void {
    this.cancel.emit();
  }
}
