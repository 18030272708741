/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable max-classes-per-file */
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { RenderTextMode } from 'ng2-pdf-viewer';
import { MimeType } from '../../file-upload/model/file-upload';

export const checkImageExtension = (ext: string): boolean => {
  const exts = ['png', 'jpg', 'jpeg', 'tiff', 'JPG', 'PNG'];
  if (exts.includes(ext)) {
    return true;
  }
  return false;
};

export const check3DExtension = (ext: string): boolean => {
  const exts = ['stl', 'obj'];
  if (exts.includes(ext)) {
    return true;
  }
  return false;
};

export const getFileExtension = ({ src, type }: { src: string; type?: MimeType }): string => {
  if (type) {
    return type;
  }
  const filePathWithoutParams = src.split('?')[0];
  const fileTye = filePathWithoutParams?.split('.').pop()?.toLowerCase();
  return fileTye;
};
export interface IFileViewerFile {
  src: string;
  type?: MimeType;
  name: string;
  selected: boolean;
}

export interface IFileViewerControls {
  zoomOut: () => void;
  zoomIn: () => void;
  next: () => void;
  prev: () => void;
  goto: () => void;
  reset: () => void;
  delete: () => void;
  download: () => void;
}

export interface MultiFileViewer {
  files: IFileViewerFile[];
  select: (file: IFileViewerFile) => void;
}

export interface IPDFOption {
  autoresize: boolean;
  cMapsUrl: string;
  externalLinkTarget?: string;
  fitToPage: boolean;
  originalSize: boolean;
  renderText: boolean;
  renderTextMode: RenderTextMode;
  rotation: number;
  showAll: boolean;
  showBorders: boolean;
  stickToPage: boolean;
}

export interface IImgOption {
  download: boolean;
  idContainer: string;
  loadOnInit: boolean;
  showPDFOnlyOption: boolean;
}

@Directive({})
export abstract class FileViewerBase implements Partial<IFileViewerFile> {
  @Input() src: string;

  @Input() type: MimeType;

  @Input() name: string;

  @Output() loaded = new EventEmitter();

  @Output() deleted = new EventEmitter();

  /**
   * TODO We can implement better approach then this
   */
  getFileType({ src, type }: { src: string; type?: MimeType }): 'image' | any {
    const ext = getFileExtension({ src, type });
    if (checkImageExtension(ext)) {
      return 'image';
    }

    if (check3DExtension(ext)) {
      return '3d';
    }
    return ext;
  }
}
@Directive({})
export abstract class PDFViewerBase extends FileViewerBase {
  @Input() page = 1;

  totalPage = 1;
}

@Directive({})
export abstract class ImageViewerBase extends FileViewerBase {}

@Directive({})
export abstract class ThreedViewerBase extends FileViewerBase {}
