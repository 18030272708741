// eslint-disable-next-line max-classes-per-file

export interface IFinancialDimensionWidgetData {
  config?: DimensionConfig;
  companyId: string;
  financialDimension?: FinancialDimensionField;
  fieldToDisable: IFieldToDisable;
}

export interface DimensionConfig {
  baseUrl?: string;
  absoluteUrl?: string;
  environment?: 'staging' | 'production' | 'local';
  fieldToShow?: FieldToShow;
}

interface Field {
  _id: string;
  name: string;
  code: string;
}
export interface FinancialDimensionField {
  segment?: Field;
  businessUnit?: Field;
  subBusinessUnit?: Field;
  factory?: Field;
  region?: Field;
}

export type IFieldToDisable = FieldToShow;

interface FieldToShow {
  segment?: boolean;
  businessUnit?: boolean;
  subBusinessUnit?: boolean;
  factory?: boolean;
  region?: boolean;
}

export const validationMsg = {
  segment: {
    required: 'Segment is required'
  },
  businessUnit: {
    required: 'Business Unit is required'
  },
  subBusinessUnit: {
    required: 'Sub Business Unit is required'
  }
};
