<section class="file-viewer-control">
  <div class="file-name" data-testid="file-name">
    <ng-template [ngIf]="(files$ | async).length > 1">
      <ng-select
        class="file-viewer--dropdown"
        [items]="files$ | async"
        [searchable]="false"
        bindLabel="name"
        [multiple]="false"
        [clearable]="false"
        [dropdownPosition]="'top'"
        [formControl]="fileControl"
        data-testid="file-viewer-dropdown"
      ></ng-select>
    </ng-template>
    <ng-template [ngIf]="(files$ | async).length <= 1"> {{ name$ | async }} </ng-template>
  </div>
  <div>
    <button type="button" class="zbtn zbtn-circle material-icons" (click)="zoomIn()" data-testid="zoom-in">add</button>
    <span class="zbtn-value" data-testid="zoom-value">{{ (zoom$ | async) * 100 }}%</span>
    <button type="button" class="zbtn zbtn-circle material-icons" (click)="zoomOut()" data-testid="zoom-out">remove</button>
  </div>
  <ng-content select="fileControlContent"></ng-content>
  <button type="button" class="zbtn material-icons" (click)="download()" data-testid="download">download</button>
  <button type="button" class="zbtn material-icons" (click)="delete()">delete</button>
  <div *ngIf="(totalPage$ | async) > 0">
    <button type="button" class="zbtn zbtn-circle material-icons" (click)="prev()" data-testid="previous">keyboard_arrow_left</button>
    <span class="zbtn-value" data-testid="page-value">{{ page$ | async }}/{{ totalPage$ | async }}</span>
    <button type="button" class="zbtn zbtn-circle material-icons" (click)="next()" data-testid="next">keyboard_arrow_right</button>
  </div>
</section>
