import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { IFileViewerFile, FileViewerBase, IPDFOption, MultiFileViewer, IImgOption } from '../../model/file-viewer';
import { FileViewerService } from '../../service/file-viewer.service';
import { FileViewerQuery } from '../../state/file-viewer.query';

@Component({
  selector: 'zet-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FileViewerBase, useExisting: FileViewerComponent }]
})
export class FileViewerComponent extends FileViewerBase implements MultiFileViewer, OnChanges, OnInit {
  @Input() files: IFileViewerFile[];

  @Input() page = 1;

  @Input() pdfOptions: IPDFOption;

  @Input() imgOption: IImgOption;

  @ViewChild(ComponentRef) activeViewer: ComponentRef<FileViewerBase>;

  activeFile$: Observable<IFileViewerFile>;

  constructor(private fileViewer: FileViewerService, private query: FileViewerQuery, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(): void {
    this.fileViewer.init({ files: this.files, src: this.src, type: this.type, name: this.name });
  }

  ngOnInit(): void {
    this.activeFile$ = this.query.activeFile$;

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      this.cd.detectChanges();
    }, 1000);
  }

  select: (file: IFileViewerFile) => void;

  download(): void {
    this.fileViewer.download();
  }
}
