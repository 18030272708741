import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { createErrorFactory } from './http-error';
import { HTTP_CONFIG, IHttpConfig } from './http.model';
import { LoginService } from '../login';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(@Inject(HTTP_CONFIG) private config: IHttpConfig, private toastr: ToastrService, private login: LoginService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const customeErrorMessage = this.config?.errorMessage || {};
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const { msg, des, handler } = createErrorFactory(error, customeErrorMessage);
        handler && handler.call(this, msg, des);
        return throwError(() => error);
      })
    );
  }
}
