import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FinancialDimensionWidgetComponent } from './components/financial-dimension-widget/financial-dimension-widget.component';
import { FinancialDimensionComponent } from './components/financial-dimension/financial-dimension.component';
import { AddressModule } from '../address';

@NgModule({
  imports: [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule, AddressModule],
  declarations: [FinancialDimensionComponent, FinancialDimensionWidgetComponent],
  exports: [FinancialDimensionComponent, FinancialDimensionWidgetComponent]
})
export class FinancialDimensionModule {}
