/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line max-classes-per-file
import { Directive, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AbstractTabs } from '../model/abstract-tabs';
import { ITab } from '../model/tabs';

@Directive({
  selector: '[zetRouterLinkTabs]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class RouterLinkTabs extends AbstractTabs implements OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(public router: Router, public route: ActivatedRoute) {
    super();
    this.subscription.add(
      this.router.events.pipe(filter(ev => ev instanceof NavigationEnd)).subscribe(() => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        const routeSegment = this.route.snapshot['_urlSegment'].segments;
        const link = routeSegment[routeSegment.length - 1].path;
        const linkTab = this.tabs.find(tab => tab.link === link);
        if (linkTab) this.selectTab(linkTab);
      })
    );
  }

  get tabs(): ITab[] {
    return this._tabs;
  }

  set tabs(tabs: ITab[]) {
    this._tabs = tabs;
    this.activeTab = this._tabs.find(tab => tab.selected);
    setTimeout(() => {
      this.gotoLink(this.activeTab);
    }, 0);
  }

  public gotoLink(tab: ITab): void {
    if (!tab || !tab.link) {
      return;
    }

    this.router.navigate([tab.link], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
