import { CreateFlow, CreateFlowStepState } from './create-flow';

export class NonLinearCreateFlow extends CreateFlow {
  goto(id: string): void {
    const targetIndex = this.steps.findIndex(({ id: stepId }) => stepId === id);

    if (this.steps[targetIndex].state === CreateFlowStepState.VISITED || this.steps[targetIndex].state === CreateFlowStepState.CURRENT) {
      return;
    }

    for (let i = 0; i < this.steps.length; i += 1) {
      if (this.steps[i].state === CreateFlowStepState.CURRENT) {
        this.steps[i].state = CreateFlowStepState.UNVISITED;
      }
    }

    this.steps[targetIndex].state = CreateFlowStepState.CURRENT;
  }

  next(id: string): void {
    const currentIndex = this.steps.findIndex(({ id: stepId }) => stepId === id);

    this.steps[currentIndex].state = CreateFlowStepState.VISITED;

    let targetIndex = this.steps.findIndex(({ state }) => state === CreateFlowStepState.CURRENT);
    let unvisitedIndex = this.steps.findIndex(({ state }) => state === CreateFlowStepState.UNVISITED);

    if (targetIndex < 0 && unvisitedIndex >= 0) {
      this.steps[unvisitedIndex].state = CreateFlowStepState.CURRENT;
    }
  }

  jumpIsValid(): boolean {
    return true;
  }
}
