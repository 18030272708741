/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IPage } from '../../model/pagination';
import { BasePaginationControls } from '../../directives/base-pagination-control.directive';

@Component({
  selector: 'zet-dropdown-pagination-controls',
  templateUrl: './dropdown-pagination-controls.component.html',
  styleUrls: ['./dropdown-pagination-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownPaginationControlsComponent extends BasePaginationControls implements OnInit {
  pageControl: FormControl = new FormControl('');

  pages = [];

  ngOnInit(): void {
    this.pageControl.setValue(this.getCurrent());

    this.pageControl.valueChanges.subscribe((page: number) => {
      this.setCurrent(page);
    });
  }

  next(): void {
    this.pageControl.setValue(this.getCurrent() + 1);
    super.next();
  }

  previous(): void {
    this.pageControl.setValue(this.getCurrent() - 1);
    super.previous();
  }

  _createPageArray(currentPage: number, itemsPerPage: number, totalItems: number, paginationRange: number): IPage[] {
    // let pages = [];

    const totalPages = Math.max(Math.ceil(totalItems / itemsPerPage), 1);

    let i = 1;

    while (i <= totalPages) {
      // pages.push({
      //   label: i,
      //   value: i
      // });
      this.pages.push({
        label: i,
        value: i
      });
      i += 1;
    }
    return this.pages;
    // return pages;
  }
}
