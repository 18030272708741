<article class="zet-list" [class.zet-list-hide-select-all]="allowSingleSelection">
  <zet-list
    #list
    class="widget-item--list"
    [class.widget-item--list-selected]="mode === 'SELECTED'"
    [tableConfig]="tableOptions"
    [dataProvider]="dataProvider"
    [context]="filters"
    (rowSelect)="handleRowSelect($event)"
  >
  </zet-list>
</article>
