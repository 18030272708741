<ng-select
  class="address-form--dropdown"
  [class.address-form--dropdown-error]="isError"
  [items]="items"
  [searchable]="true"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [multiple]="multiple"
  [clearable]="clearable"
  [placeholder]="placeholder"
  [dropdownPosition]="'auto'"
  [formControl]="formControl"
  [closeOnSelect]="closeOnSelect"
  [typeahead]="typeahead ? search$ : null"
  [addTag]="addTag"
  (change)="changeData.emit($event)"
  [appendTo]="appendTo"
  (close)="close()"
>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
    <div class="dropdown-option">
      <div class="dropdown-option--body">
        <img *ngIf="item?.image" class="dropdown-option--image" [src]="item.image" alt="item.value" />
        <input
          *ngIf="checkbox"
          id="item-{{ index }}"
          class="form-check-input inline-checkbox"
          type="checkbox"
          id="inlineCheckbox1"
          [ngModel]="item$.selected"
        />
        <div class="dropdown-option--text">
          <p class="dropdown-option--title">{{ item[bindLabel] || item }}</p>
          <p *ngIf="item?.subtitle" class="dropdown-option--subtitle">{{ item.subtitle }}</p>
          <p *ngIf="item?.subText" class="dropdown-option--subtitle" [innerHTML]="item.subText"></p>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <div class="dropdown-value" *ngFor="let item of items">
      <span class="dropdown-value--label">{{ item[bindLabel] || item }}</span>
      <span class="dropdown-value--icon material-icons" (click)="!disabled && clear(item)">close</span>
    </div>
  </ng-template>

  <ng-template *ngIf="selectAll" ng-header-tmp>
    <div class="dropdown-option">
      <div class="dropdown-option--body select-all" (click)="onSelectAll()">
        <input *ngIf="checkbox" name="selectAll-checkbox" class="form-check-input inline-checkbox" type="checkbox" id="inlineCheckbox1" />

        <div class="dropdown-option--text">
          <p class="dropdown-option--title">Select All</p>
        </div>
      </div>
    </div>
  </ng-template>
</ng-select>
