import { Injectable, TemplateRef } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ISidemenuItem } from '../models/sidemenu';

export interface SidemenuState {
  ui: {
    isOpen: boolean;
  };
  items: ISidemenuItem[];
  activeItemIndex: number;
  activeItem: ISidemenuItem;
  headerInfoTemplate: TemplateRef<unknown>;
  footerInfoTemplate: TemplateRef<unknown>;
}

export function createInitialState(): SidemenuState {
  return {
    ui: {
      isOpen: true
    },
    items: [],
    activeItemIndex: 0,
    activeItem: null,
    headerInfoTemplate: null,
    footerInfoTemplate: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'zetui.sidemenu' })
export class SidemenuStore extends Store<SidemenuState> {
  constructor() {
    super(createInitialState());
  }
}
