export interface ITimelineConfig {
  orientation: TimelineOrientation;
  mode?: TimelineMode;
  theme?: TimelineTheme;
}

export interface ITimelineStep {
  id: string;
  title: string;
  type: TimelineStepType; // FIXME no longer makes sense
  header?: string;
  body?: string;
}

/* icon change -> state */
/* FIMXE TimelineStepType */
export enum TimelineStepType {
  CURRENT = 'current',
  COMPLETED = 'completed',
  ERROR = 'error',
  LOADING = 'loading',
  PENDING = 'pending',
  UNVISITED = 'unvisited',
  ONGOING = 'ongoing',
  PARTIALLY = 'partially'
}

export enum TimelineMode {
  STICK = 'stick',
  MULTISTATE = 'multistate'
}

export enum TimelineTheme {
  RED = 'red',
  BLUE = 'blue'
}

export enum TimelineOrientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

/* 
  TODO - Anurag

  timelineItemDirective
    timelineBodyDirective
    timelineIconDirective
    timelineHeaderDirective
*/
