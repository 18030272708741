<div class="form-container">
  <form autocomplete="off" class="" [formGroup]="loginForm">
    <div class="form-group">
      <label for="email" class="field-label">Enter Email ID</label
      ><input
        name="email"
        id="email"
        tabindex="1"
        placeholder="Enter your email address"
        type="text"
        formControlName="email"
        class="form-control"
        value=""
        (keydown.enter)="login()"
      />
    </div>
    <div class="alert alert-danger" *ngIf="formErrors && (formErrors.required || formErrors.email || formErrors.notRegistered)">
      <img src="/assets/images/error-icon.svg" />
      <span *ngIf="formErrors && formErrors.required">Email Id is required</span>
      <span *ngIf="formErrors && formErrors.email">Invalid Email Id</span>
      <span *ngIf="formErrors && formErrors.notRegistered">Email Id is not Registered</span>
    </div>

    <button type="button" class="login-black-button next" (click)="login()">
      Next <span class="arrow"><img src="/assets/images/arrow-white.svg" /></span>
    </button>
  </form>
</div>
