/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { HelperService } from '../../@core/services/helper.service';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Router } from '@angular/router';

enum FeatureStatus {
  INPROGRESS = 'feature-is-inprogress',
  PRODUCTION = 'feature-is-active',
  PICKED = 'feature-is-picked',
  INACTIVE = 'feature-is-inactive'
}

enum FeatureType {
  BASICS = 'basics',
  COMPONENT = 'component',
  UTILS = 'utils',
  LIBRARY = 'library'
}
export interface IFeature {
  id?: any;
  type: FeatureType;
  title: string;
  images: { title: string; src: string }[];
  status: FeatureStatus;
  feedback?: {
    viewCount?: number;
    usefulCount?: number;
    notUsefulCount?: number;
    neededCount?: number;
  };
  link?: string;
}

@Component({
  selector: 'zet-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowCaseComponent implements OnInit {
  featureCollection: AngularFirestoreCollection<IFeature>;

  features$: Observable<IFeature[]>;

  components$: Observable<IFeature[]>;

  basics$: Observable<IFeature[]>;

  featureStatus = FeatureStatus;

  templateComponentItems = [];

  designSystemItems = [];

  componentRows: IFeature[] = [
    {
      type: FeatureType.COMPONENT,
      title: 'Advanced Rich Text Editor',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,
      title: 'Banners',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Basic Rich Text Editor',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Breadcrumb',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Cardlist',
      images: [
        { title: 'Normal', src: '/assets/img/showcase/card-list/card-list.png' },
        { title: 'Selected', src: '/assets/img/showcase/card-list/card-list-selected.png' }
      ],
      status: FeatureStatus.PRODUCTION,
      link: 'card-list'
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Checklist',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Coachmark',
      images: [
        { title: 'Coachmark end', src: '/assets/img/showcase/coachmark/end.png' },
        { title: 'Coachmark end with gif', src: '/assets/img/showcase/coachmark/end-with-gif.png' }
      ],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Contact Card',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Dropdown/Multiselect',
      images: [
        { title: 'Dropdown', src: '/assets/img/showcase/dropdown/dropdown.png' },
        { title: 'Dropdown states', src: '/assets/img/showcase/dropdown/dropdown-states.png' }
      ],
      status: FeatureStatus.INPROGRESS
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Empty State',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'File upload',
      images: [
        { title: 'Single upload', src: '/assets/img/showcase/file-upload/single-file-upload.png' },
        { title: 'Multiple upload', src: '/assets/img/showcase/file-upload/multiple-file-upload.png' },
        { title: 'Exposed state', src: '/assets/img/showcase/file-upload/exposed-state.png' }
      ],
      status: FeatureStatus.PRODUCTION,
      link: 'file-upload'
    },
    // {
    //   title: 'Filter',
    //   images: [
    //     { title: 'Single Select', src: '/assets/img/showcase/filters/single-select.png' },
    //     { title: 'Multiple Select', src: '/assets/img/showcase/filters/multi-select.png' },
    //     { title: 'Server Side', src: '/assets/img/showcase/filters/server-side.png' }
    //   ],
    //   status: FeatureStatus.INACTIVE
    // },
    {
      type: FeatureType.COMPONENT,

      title: 'Grid list',
      images: [
        { title: 'Dynamic grid', src: '/assets/img/showcase/grid-list/grid.png' },
        { title: 'Basic grid', src: '/assets/img/showcase/grid-list/grid-1.png' },
        { title: 'Grid system', src: '/assets/img/showcase/grid-list/grid-2.png' }
      ],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Image preview',
      images: [
        { title: 'Normal view', src: '/assets/img/showcase/image-preview/normal-view.png' },
        { title: 'Full page view', src: '/assets/img/showcase/image-preview/full-page-view.png' }
      ],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Image Sprites',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Loader',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Modal',
      images: [
        { title: 'Normal', src: '/assets/img/showcase/modal/normal.png' },
        { title: 'Warning', src: '/assets/img/showcase/modal/warning.png' },
        { title: 'Scroll', src: '/assets/img/showcase/modal/scroll.png' },
        { title: 'Custom', src: '/assets/img/showcase/modal/custom.png' }
      ],
      status: FeatureStatus.PRODUCTION,
      link: 'modal'
    },
    {
      type: FeatureType.COMPONENT,

      title: 'File viewer',
      images: [
        { title: 'Normal', src: '/assets/img/showcase/pdf-preview/pdf-preview.png' },
        { title: 'Normal', src: '/assets/img/showcase/pdf-preview/pdf-preview-1.png' },
        { title: 'Normal', src: '/assets/img/showcase/pdf-preview/pdf-preview-2.png' }
      ],
      status: FeatureStatus.PRODUCTION,
      link: 'file-viewer'
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Page header',
      images: [
        { title: 'Normal', src: '/assets/img/showcase/page-header/page-header.png' },
        { title: 'Custom Template', src: '/assets/img/showcase/page-header/page-header-1.png' }
      ],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Pagination',
      images: [],
      status: FeatureStatus.PICKED
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Progressbar',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Search',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Scrollspy',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Scroll component',
      images: [{ title: 'Scroll Component', src: '/assets/img/showcase/scroll-component/scroll.png' }],
      status: FeatureStatus.PICKED
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Sidemenu',
      images: [
        { title: 'Primary Image', src: '/assets/img/showcase/sidemenu/sidemenu.png' },
        { title: 'Secondary Image', src: '/assets/img/showcase/sidemenu/sidemenu-with-subheading.png' }
      ],
      status: FeatureStatus.PRODUCTION,
      link: 'sidemenu'
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Stepper',
      images: [{ title: 'Stepper', src: '/assets/img/showcase/stepper/stepper.png' }],
      status: FeatureStatus.PRODUCTION,
      link: 'stepper'
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Table list (ZetList)',
      images: [
        { title: 'List', src: '/assets/img/showcase/table-list/table.png' },
        { title: 'Filter selected', src: '/assets/img/showcase/table-list/table-filter-selected.png' }
      ],
      status: FeatureStatus.PRODUCTION,
      link: 'table-list'
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Tabs',
      images: [],
      status: FeatureStatus.PRODUCTION,
      link: 'tabs'
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Tree',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Toasts',
      images: [
        { title: 'Toast', src: '/assets/img/showcase/toast/toast-1.png' },
        { title: 'Warning toast', src: '/assets/img/showcase/toast/toast.png' }
      ],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,

      title: 'Tooltip',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.COMPONENT,
      title: 'Timeline',
      images: [
        { title: 'Horizontal single state', src: '/assets/img/showcase/timeline/horizontal-single.png' },
        { title: 'Vertical single state', src: '/assets/img/showcase/timeline/vertical-single.png' },
        { title: 'Horizontal multi state', src: '/assets/img/showcase/timeline/horizontal-multi.png' },
        { title: 'Vertical multi state', src: '/assets/img/showcase/timeline/vertical-multi.png' }
      ],
      status: FeatureStatus.PRODUCTION,
      link: 'timeline'
    },
    {
      type: FeatureType.COMPONENT,
      title: 'Wizard',
      images: [{ title: 'Wizard', src: '/assets/img/showcase/wizard/wizard.png' }],
      status: FeatureStatus.INACTIVE
    }
  ];

  basicsRows: IFeature[] = [
    {
      type: FeatureType.BASICS,
      title: 'Avatar',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.BASICS,
      title: 'Badge',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.BASICS,
      title: 'Button',
      images: [
        {
          title: 'Button',
          src: '/assets/img/showcase/button/button.png'
        }
      ],
      status: FeatureStatus.PRODUCTION,
      link: 'buttons'
    },
    {
      type: FeatureType.BASICS,
      title: 'Card',
      images: [],
      status: FeatureStatus.PRODUCTION,
      link: 'card'
    },
    {
      type: FeatureType.BASICS,
      title: 'Checkbox',
      images: [
        {
          title: 'Checkbox Checked',
          src: '/assets/img/showcase/checkbox/checked.png'
        },
        {
          title: 'Checkbox Unchecked',
          src: '/assets/img/showcase/checkbox/unchecked.png'
        },
        {
          title: 'Checkbox Intermediate',
          src: '/assets/img/showcase/checkbox/intermediate.png'
        }
      ],
      status: FeatureStatus.PICKED
    },
    {
      type: FeatureType.BASICS,
      title: 'Colors',
      images: [
        {
          title: 'Primary color',
          src: '/assets/img/showcase/color/primary-color.png'
        },
        {
          title: 'Secondary color',
          src: '/assets/img/showcase/grid-list/secondary-color.png'
        },
        {
          title: 'Color shades',
          src: '/assets/img/showcase/grid-list/color-shades.png'
        }
      ],
      status: FeatureStatus.PRODUCTION,
      link: 'colors'
    },
    {
      type: FeatureType.BASICS,
      title: 'Divider',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.BASICS,

      title: 'Grid',
      images: [],
      status: FeatureStatus.PRODUCTION,
      link: 'grid'
    },
    {
      type: FeatureType.BASICS,

      title: 'Icon Library',
      images: [],
      status: FeatureStatus.PRODUCTION,
      link: 'icon-library'
    },
    {
      type: FeatureType.BASICS,

      title: 'Input box',
      images: [
        { title: 'Normal', src: '/assets/img/showcase/forms/normal.png' },
        { title: 'With label', src: '/assets/img/showcase/forms/with-label.png' },
        { title: 'Expandable', src: '/assets/img/showcase/forms/expandable.png' },
        { title: 'Icon left', src: '/assets/img/showcase/forms/icon-left.png' },
        { title: 'Right left', src: '/assets/img/showcase/forms/right-left.png' },
        { title: 'Unit left', src: '/assets/img/showcase/forms/unit-left.png' },
        { title: 'Dropdown right', src: '/assets/img/showcase/forms/dropdown-right.png' },
        { title: 'Textbox', src: '/assets/img/showcase/forms/textbox.png' }
      ],
      status: FeatureStatus.INPROGRESS
    },
    {
      type: FeatureType.BASICS,

      title: 'Logo',
      images: [],
      status: FeatureStatus.INACTIVE
    },
    {
      type: FeatureType.BASICS,

      title: 'Radio Button',
      images: [],
      status: FeatureStatus.PICKED
    },
    {
      type: FeatureType.BASICS,

      title: 'Scroll bar',
      images: [],
      status: FeatureStatus.PICKED
    },
    {
      type: FeatureType.BASICS,

      title: 'Tooltips',
      images: [],
      status: FeatureStatus.PICKED
    },
    {
      type: FeatureType.BASICS,

      title: 'Typography',
      images: [
        {
          title: 'Typography',
          src: '/assets/img/showcase/typography/typography.png'
        }
      ],
      status: FeatureStatus.PRODUCTION
    }
  ];

  displayedColumns = ['0', '1', '2', '3'];

  constructor(private helperService: HelperService, private toastr: ToastrService, private fbs: AngularFirestore, private router: Router) {
    this.featureCollection = this.fbs.collection<IFeature>('/features');
    this.features$ = this.featureCollection.valueChanges({ idField: 'id' });
    // this.addAllFeatures();
  }

  addAllFeatures(): void {
    const rows = [...this.componentRows, ...this.basicsRows];

    // eslint-disable-next-line no-restricted-syntax
    for (const feature of rows) {
      const featureToAdd: IFeature = {
        ...feature,
        feedback: {
          viewCount: 0,
          usefulCount: 0,
          notUsefulCount: 0,
          neededCount: 0
        }
      };
      this.featureCollection.add(featureToAdd);
    }
  }

  ngOnInit(): void {
    this.helperService.pageHeader$.next('');

    this.features$.subscribe((rows: Partial<IFeature>[]) => {
      this.componentRows = rows.filter(row => row.type === FeatureType.COMPONENT) as IFeature[];
      this.basicsRows = rows.filter(row => row.type === FeatureType.BASICS) as IFeature[];

      this.getItems('COMPONENT');
      this.getItems('BASIC');
    });
  }

  getItems(type: string): void {
    let items;
    let rowNumber;
    switch (type) {
      case 'COMPONENT':
        items = [];
        rowNumber = Math.ceil(this.componentRows.length / 4);
        for (let i = 0; i < rowNumber; i += 1) {
          let start = i * 4;
          let end = start + 4;
          items[i] = this.componentRows.slice(start, end);
        }
        this.templateComponentItems = items;
        break;
      case 'BASIC':
        items = [];
        rowNumber = Math.ceil(this.basicsRows.length / 4);
        for (let i = 0; i < rowNumber; i += 1) {
          let start = i * 4;
          let end = start + 4;
          items[i] = this.basicsRows.slice(start, end);
        }
        this.designSystemItems = items;
        break;
      default:
        break;
    }
  }

  submitFeedback(item: IFeature, positive = true): void {
    item.feedback = { ...item.feedback };
    if (item.status === FeatureStatus.PRODUCTION) {
      if (positive) {
        item.feedback.usefulCount += 1;
      } else {
        item.feedback.notUsefulCount -= 1;
      }
    } else {
      item.feedback.neededCount += 1;
    }

    this.featureCollection.doc(item.id).update({ feedback: item.feedback });

    this.toastr.success('Your response has been recorded');
  }

  navigate(item: IFeature): void {
    if (item.type === FeatureType.COMPONENT && item.status === FeatureStatus.PRODUCTION) {
      this.router.navigate(['components', item.link]);
    }

    if (item.type === FeatureType.BASICS && item.status === FeatureStatus.PRODUCTION) {
      this.router.navigate(['basics', item.link]);
    }
  }
}
