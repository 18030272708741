<nav class="pagination">
  <ul class="pagination--list">
    <li class="pagination--item pagination--previous">
      <a tabindex="0" (keyup.enter)="previous()" (click)="previous()" class="pagination--item-link" [class.page-is-disable]="isFirstPage()">
        <span class="material-icons">keyboard_arrow_left</span>
      </a>
    </li>
    <li class="pagination--item">
      <ng-select
        class="pagination--dropdown"
        [items]="pages$ | async"
        [multiple]="false"
        [searchable]="false"
        bindLabel="label"
        bindValue="value"
        [clearable]="false"
        [dropdownPosition]="'auto'"
        [formControl]="pageControl"
      ></ng-select>
      <span class="ml-8">/ {{ getLastPage() }}</span>
    </li>
    <li class="pagination--item pagination--next">
      <a tabindex="0" (keyup.enter)="next()" (click)="next()" class="pagination--item-link" [class.page-is-disable]="isLastPage()">
        <span class="material-icons">keyboard_arrow_right</span>
      </a>
    </li>
  </ul>
</nav>
