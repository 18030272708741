import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISpyLink } from '../model/spy-scroll';
import { SpyScrollStore, SpyScrollState } from './spy-scroll.store';

@Injectable()
export class SpyScrollQuery extends Query<SpyScrollState> {
  constructor(protected store: SpyScrollStore) {
    super(store);
  }

  get links(): ISpyLink[] {
    return this.getValue().links;
  }

  get scrollContainer(): Element | HTMLElement {
    return this.getValue().scrollContainer;
  }

  getLinkById(id: string): Observable<ISpyLink> {
    return this.select('links').pipe(
      map((links: ISpyLink[]) => {
        return links.filter(link => link.id === id)[0];
      })
    );
  }
}
