import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { IPDFOption } from '../model/file-viewer';

@Directive({
  selector: '[zetPDFViewerOption]'
})
export class PDFViewerOptionDirective implements OnChanges {
  @Input('zetPDFViewerOption') option: IPDFOption;

  constructor(private pdfViewer: PdfViewerComponent) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.option?.currentValue !== changes?.option?.previousValue) {
      this.pdfViewer.autoresize = this.option?.autoresize;
      this.pdfViewer.cMapsUrl = this.option?.cMapsUrl;
      this.pdfViewer.externalLinkTarget = this.option?.externalLinkTarget;
      this.pdfViewer.fitToPage = this.option?.fitToPage;
      this.pdfViewer.originalSize = this.option?.originalSize;
      this.pdfViewer.renderText = this.option?.renderText;
      this.pdfViewer.renderTextMode = this.option?.renderTextMode;
      this.pdfViewer.rotation = this.option?.rotation;
      this.pdfViewer.showBorders = this.option?.showBorders;
      this.pdfViewer.showAll = this.option?.showAll;
      this.pdfViewer.stickToPage = this.option?.stickToPage;
    }
  }
}
