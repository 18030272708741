import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { User } from './auth.model';

export interface AuthState {
  user: User | null;
  roles: [];
  isLoggedIn: boolean;
  appsEnabledDetails: [];
  companies: [];
}

export function createInitialState(): AuthState {
  return {
    user: null,
    roles: [],
    isLoggedIn: false,
    appsEnabledDetails: [],
    companies: []
  };
}

@StoreConfig({ name: 'auth' })
@Injectable({ providedIn: 'root' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
