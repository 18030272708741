import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthStore } from './auth.store';
import { AUTH_CONFIG, IAuthConfig, IAuthData, User } from './auth.model';
import { AuthQuery } from './auth.query';
import { RestClientService } from '../http';
import { LoginService } from '../login/login.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  AUTH_URL = '';

  constructor(
    private http: RestClientService,
    private store: AuthStore,
    private query: AuthQuery,
    private login: LoginService,
    @Inject('persistStorage') private persistStorage: any,
    @Inject(AUTH_CONFIG) private config: IAuthConfig
  ) {
    this.AUTH_URL = (this.config && this.config.authUrl) || '';
  }

  public getUser(): User | null {
    const user = this.query.user;
    return user || null;
  }

  public getIsLoggedIn(): boolean {
    const isLoggedIn = this.query.isLoggedIn;
    return isLoggedIn || false;
  }

  public getUserFromStore(): any {
    let mergedResponse = {
      ...this.query.user,
      ...{ companies: this.query.companies },
      ...{ appsEnabledDetails: this.query.appsEnabledDetails },
      ...{ roles: this.query.roles }
    };
    return mergedResponse;
  }

  public getRoles(): any {
    const roles = this.query.roles;
    return roles || null;
  }

  public authenticate(token: string): Observable<any> {
    return this.http.post(`${this.AUTH_URL}authenticate`, { token }).pipe(
      tap((res: any) => {
        const authData: IAuthData = res.data;
        this.setAuthData(authData);
      })
    );
  }

  public authenticateCode(code: string): Observable<any> {
    return this.http.post(`${this.AUTH_URL}authenticate-code`, { code, redirectUrl: window.location.origin }).pipe(
      tap((res: any) => {
        // console.log(res.headers.keys());
        const authData: IAuthData = res.data;
        this.setAuthData(authData);
      })
    );
  }

  public setAuthData(authData: IAuthData): void {
    const user = new User(authData.userObject);

    const roles = authData?.userObject?.roleDetails || [];

    const isLoggedIn = !!(authData && authData.userObject);

    this.store.update({
      user,
      roles,
      isLoggedIn,
      appsEnabledDetails: authData?.userObject?.appsEnabledDetails,
      companies: authData?.userObject?.companies
    });
  }

  public clearAuthData() {
    this.store.update({ user: null, roles: [], isLoggedIn: false });
    this.persistStorage.clearStore();
  }
}
