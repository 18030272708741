import { Inject, Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { App, BrowserEnvConfig, BrowserEnvToken, ServerConfig } from '../../browserEnvConfig';
import { RestClientService } from '../../item-master/services/rest-client.service';
import { AgendaJobQuery } from './agenda-job.query';
import { AgendaJobStore } from './agenda-job.store';
import { AgendaJob, IServerFile, JobStatus, JobStatusRefreshType } from '../models/agenda-job';

@Injectable()
export class AgendaJobService {
  appConfig: { [app in App]: ServerConfig };

  constructor(
    private http: RestClientService,
    @Inject(BrowserEnvToken) private apis: BrowserEnvConfig[],
    private store: AgendaJobStore,
    private query: AgendaJobQuery
  ) {
    this.appConfig = apis.reduce((acc, api) => {
      acc[api.app] = api;
      return acc;
    }, {}) as { [app in App]: ServerConfig };
  }

  init({
    app,
    name,
    taskId,
    uploadedFile,
    mode
  }: {
    app: App;
    name: AgendaJob['name'];
    taskId: AgendaJob['_id'];
    uploadedFile: IServerFile;
    mode: JobStatusRefreshType;
  }): void {
    this.store.update({
      app,
      jobName: name,
      uploadedFile,
      taskId,
      mode
    });
  }

  getJobStatus(app: App, taskId: string) {
    return this.http.get(`${this.appConfig[app].baseUrl}agenda-job/status`, { params: { taskId } }).pipe(
      tap((res: any) => {
        const job: AgendaJob = res.data;
        job.status = job.status || JobStatus.Started;
        this.store.update({ job });
        const uploadedFile = this.query.uploadedFile;
        if (!uploadedFile) {
          this.store.update({ uploadedFile: job?.data?.uploadedFile });
        }
      }),
      catchError((err: any) => {
        this.store.update({
          job: {
            status: JobStatus.Fail
          }
        });
        throw err;
      })
    );
  }
}
