import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    /* When case is auth then interceptor shall be added here */

    /* Shall be fixed when external interceptor is intoduced */
    const CREDENTIALS_ENDPOINTS = ['https://api.staging.zetwerk.com', 'https://api.uat.zetwerk.com', 'https://api.zetwerk.com'];
    if (CREDENTIALS_ENDPOINTS.some(url => request.url.startsWith(url))) {
      request = request.clone({
        withCredentials: true
      });
    }

    return next.handle(request);
  }
}
