import { Directive, Input, OnChanges } from '@angular/core';
import { IImgOption } from '../model/file-viewer';
import { ImageViewerComponent } from '@zetwerk/image-viewer';

@Directive({
  selector: '[zetImageViewerOption]'
})
export class ImageViewerOptionDirective implements OnChanges {
  @Input('zetImageViewerOption') option: IImgOption;

  constructor(private imageViewer: ImageViewerComponent) {}

  ngOnChanges(): void {
    this.imageViewer.download = this.option?.download || false;
    this.imageViewer.idContainer = this.option?.idContainer || 'idOnHTML';
    this.imageViewer.loadOnInit = this.option?.loadOnInit || true;
    this.imageViewer.showPDFOnlyOption = this.option?.showPDFOnlyOption || false;
  }
}
