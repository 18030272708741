import { Component } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';

@Component({
  selector: 'zet-status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss']
})
export class StatusCellComponent implements ICellCmp {
  params;

  columnDef;

  element;

  init(params: any): void {}
}
