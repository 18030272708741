import { CreateFlow, CreateFlowStepState } from './create-flow';

export class LinearCreateFlow extends CreateFlow {
  goto(id: string): void {
    const targetIndex = this.steps.findIndex(({ id: stepId }) => stepId === id);
    const targetState = this.steps[targetIndex].state;

    if (targetState !== CreateFlowStepState.CURRENT && targetState !== CreateFlowStepState.VISITED) {
      throw new Error(`Goto step ${id} is not allowed`);
    }
  }

  next(id: string): void {
    const currentIndex = this.steps.findIndex(step => step.id === id);

    if (currentIndex === this.steps.length - 1) {
      this.steps[currentIndex].state = CreateFlowStepState.VISITED;
      return;
    }
    this.steps[currentIndex].state = CreateFlowStepState.VISITED;
    this.steps[currentIndex + 1].state = CreateFlowStepState.CURRENT;

    for (let i = currentIndex + 2; i < this.steps.length; i += 1) {
      this.steps[i].state = CreateFlowStepState.UNVISITED;
    }
  }

  jumpIsValid(id: string): boolean {
    const targetStep = this.steps.find(step => step.id === id);
    return targetStep.state === CreateFlowStepState.VISITED || targetStep.state === CreateFlowStepState.CURRENT;
  }
}
