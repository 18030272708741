import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { LoaderService } from './loader.service';
import { catchError, filter, tap } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loaderService.addRequest(request.url);

    return next.handle(request).pipe(
      filter(event => event instanceof HttpResponse),
      tap(() => {
        this.loaderService.removeRequest(request.url);
      }),
      catchError(err => {
        this.loaderService.removeRequest(request.url);
        return throwError(err);
      })
    );
  }
}
