import { Inject, Injectable } from '@angular/core';
import { IFileUploadConfig, UploadFile, UploadFileTypeError } from '../model/file-upload';
import { FILE_UPLOAD_CONFIG } from '../file-upload-config.token';
import { convertToBytes } from '../utils/file-upload.util';

@Injectable()
export class FileValidatorService {
  constructor(@Inject(FILE_UPLOAD_CONFIG) private fileUploadConfig: IFileUploadConfig) {}

  isValidFile(file: UploadFile): { type: UploadFileTypeError; message: string } | null {
    if (!this.isValidFileType(file)) {
      return {
        type: UploadFileTypeError.INVALID_FILE_TYPE,
        message: `Unsupported file type, supported file types are:${this.fileUploadConfig.acceptedFileTypes.join(', ')}`
      };
    }

    if (this.isFileSizeExceeded(file)) {
      return {
        type: UploadFileTypeError.FILE_SIZE_EXCEEDED,
        message: `File size exceeded, maximum allowed sized is: ${this.fileUploadConfig.maxSize} MB`
      };
    }

    return null;
  }

  getFileExtension(fileName: string): string {
    const fileExtension = fileName.split('.').slice(-1)[0]?.toLowerCase();
    return fileExtension;
  }

  isValidFileType(file: UploadFile): boolean {
    const fileTypesAlwaysAllowed = this.fileUploadConfig.acceptedFileTypes;
    if (fileTypesAlwaysAllowed.indexOf(file.type) !== -1 || fileTypesAlwaysAllowed.indexOf(this.getFileExtension(file.name)) !== -1) {
      return true;
    }
    return false;
  }

  isFileSizeExceeded(file: UploadFile): boolean {
    const totalFileSize = file.total;
    if (totalFileSize > convertToBytes(this.fileUploadConfig.maxSize)) {
      return true;
    }
    return false;
  }
}
