import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  OnChanges,
  ViewChild,
  ViewContainerRef,
  OnInit,
  OnDestroy,
  TemplateRef,
  ChangeDetectorRef,
  Output,
  SimpleChanges,
  HostListener
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';

import { ISidemenuItem } from '../../models/sidemenu';
import { SidemenuService } from '../../sidemenu.service';
import { SidemenuQuery } from '../../state/sidemenu.query';

@Component({
  selector: 'zet-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidemenuComponent implements OnChanges, OnInit, OnDestroy {
  @Input() items: ISidemenuItem[];

  @Input() closeIcon: TemplateRef<unknown>;

  @Input() headerTemplate: TemplateRef<unknown>;

  @Input() footerTemplate: TemplateRef<unknown>;

  @Input() isOpen = false;

  @Input() trigger = '';

  @Output() toggle: EventEmitter<unknown> = new EventEmitter();

  @Output() logout: EventEmitter<unknown> = new EventEmitter();

  @ViewChild('headerInfoTemplate', { read: ViewContainerRef }) headerInfoTemplate: ViewContainerRef;

  @ViewChild('footerInfoTemplate', { read: ViewContainerRef }) footerInfoTemplate: ViewContainerRef;

  items$: Observable<ISidemenuItem[]> = this.sidemenuQuery.items$;

  isOpen$: Observable<boolean> = this.sidemenuQuery.isOpen$;

  headerInfoTemplate$: Observable<TemplateRef<unknown>> = this.sidemenuQuery.headerInfoTemplate$;

  footerInfoTemplate$: Observable<TemplateRef<unknown>> = this.sidemenuQuery.footerInfoTemplate$;

  _close = this.close.bind(this);

  _logout = this.onLogout.bind(this);

  private subscriptions: Subscription = new Subscription();

  constructor(private sidemenuService: SidemenuService, private sidemenuQuery: SidemenuQuery, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.subscribeHeaderInfoTemplateChanges();
    this.subscribeFooterInfoTemplateChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.items?.currentValue !== changes?.items?.previousValue) {
      this.sidemenuService.setItems(changes?.items?.currentValue);
    }

    if (changes?.isOpen?.currentValue !== changes?.isOpen?.previousValue) {
      this.sidemenuService.setIsOpen(this.isOpen);
    }
  }

  @HostListener('mouseover', ['$event'])
  handleMouseOver(event: MouseEvent): void {
    if (this.trigger !== 'hover') {
      return;
    }
    this.open();
    event.stopPropagation();
  }

  @HostListener('mouseleave', ['$event'])
  handleMouseLeave(event: MouseEvent): void {
    if (this.trigger !== 'hover') {
      return;
    }
    this.close();
    event.stopPropagation();
  }

  close(): void {
    this.sidemenuService.setIsOpen(false);
    this.toggle.emit(false);
  }

  onLogout(): void {
    this.logout.emit();
  }

  open(): void {
    this.sidemenuService.setIsOpen(true);
    this.toggle.emit(true);
  }

  back(): void {}

  subscribeHeaderInfoTemplateChanges(): void {
    const headerItemSubscription = this.headerInfoTemplate$.pipe(skip(1)).subscribe(template => {
      this.handleTemplateRender({ template, container: this.headerInfoTemplate });
    });
    this.subscriptions.add(headerItemSubscription);
  }

  subscribeFooterInfoTemplateChanges(): void {
    const footerItemSubscription = this.footerInfoTemplate$.pipe(skip(1)).subscribe(template => {
      this.handleTemplateRender({ template, container: this.footerInfoTemplate });
    });
    this.subscriptions.add(footerItemSubscription);
  }

  // TODO we can move it to utils
  handleTemplateRender({ template, container }: { template: TemplateRef<unknown>; container: ViewContainerRef }): void {
    if (template) {
      container.detach();
      container.createEmbeddedView(template);
    } else {
      container.detach();
    }
  }

  async onMenuClick(index: number): Promise<void> {
    await this.sidemenuService.handleMenuClick(index);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
