import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { StepperHeaderDirective } from '../../directives/stepper-header.directive';
import { StepperItemDirective } from '../../directives/stepper-item.directive';
import { ICreateFlowStep, CreateFlowStepState } from '../../model/create-flow';
import { StepperStepDirective } from '../../directives/stepper-step.directive';
import { StepperItemService } from '../../services/stepper-item.service';
import { StepperQuery } from '../../state/stepper.query';
import { IStepState } from '../../state/stepper.store';

@Component({
  selector: 'zet-stepper-item',
  templateUrl: './stepper-item.component.html',
  styleUrls: ['./stepper-item.component.scss']
})
export class StepperItemComponent {
  @Input() step: ICreateFlowStep;

  @Input() index: number;

  @Input() currentStep: ICreateFlowStep;

  @Input() length: number;

  @Input() itemContent: StepperItemDirective | StepperStepDirective;

  @Input() headerContent: StepperHeaderDirective;

  @Output() next: EventEmitter<string> = new EventEmitter();

  @Output() goto: EventEmitter<string> = new EventEmitter();

  stepState$: Observable<IStepState>;

  _next = this.onNext.bind(this);

  constructor(private itemService: StepperItemService, private query: StepperQuery) {
    this.stepState$ = this.query.stepState$;
  }

  get createFlowStepState(): typeof CreateFlowStepState {
    return CreateFlowStepState;
  }

  onNext(): void {
    this.next.emit(this.step.id);
    this.itemService.changeAccordianState(this.step.id);
  }

  onToggle(): void {
    this.itemService.toggle(this.step.id);
  }

  onGoto(): void {
    this.goto.emit(this.step.id);
    this.onToggle();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getStepState(state): string {
    /* TODO Write a comment here on how it is working and expect output */
    return CreateFlowStepState[state].toLowerCase();
  }
}
