/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IColumnDef, ListType, TableConfig, ZetListComponent } from '@zetwerk/zet-list';
import { ItemMasterFilterModel } from '../../models/filter';
import { ItemListMode, ItemMasterListModel } from '../../models/list';
import { ItemMasterService } from '../../services/item-master.service';
import { ItemMasterQuery } from '../../services/item-master.query';
import { DeleteItemComponent } from './delete-item/delete-item.component';

const PAGINATION_SIZE = 10;

@Component({
  selector: 'zet-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
  providers: [
    {
      provide: TableConfig,
      useValue: {
        frameworkComponents: {
          deleteButtonRenderer: DeleteItemComponent
        }
      }
    }
  ]
})
export class ItemListComponent implements OnInit {
  displayedColumns: Array<IColumnDef>;

  @Input() mode: ItemListMode;

  @Input() categoryType: string;

  @Input() dataProvider;

  @Input() items;

  @Input() allowSingleSelection = false;

  @Input() canSelectItem: (row: any) => boolean;

  tableOptions;

  @Input() filters: ItemMasterFilterModel | ItemMasterListModel['selectedItems'];

  @ViewChild(ZetListComponent) zetList: ZetListComponent;

  constructor(private item: ItemMasterService, private query: ItemMasterQuery) {
    this.displayedColumns = [
      {
        field: 'itemCode',
        headerName: 'Product Number',
        cellClass: 'cell-center',
        width: 100,
        valueGetter: data => {
          return data.itemCode || '';
        },
        rowSelection: data => {
          if (this.mode === ItemListMode.SELECTED) {
            return false;
          }
          const selectedItems = this.query.selectedItems;
          const index = selectedItems.findIndex(i => i._id === data._id);
          return index > -1;
        },
        checkboxSelection: data => {
          return this.canSelectItem ? this.canSelectItem(data) : true;
        }
      },
      {
        field: 'name',
        headerName: 'Product Description',
        cellClass: 'cell-center',
        width: 100,
        valueGetter: data => {
          return data.name || '';
        }
      },
      {
        field: 'hsnSacCode',
        headerName: 'HSN/SAC Code',
        cellClass: 'cell-center',
        width: 100,
        valueGetter: data => {
          return data.tax.code || '';
        }
      },
      {
        field: 'itemGroup',
        headerName: 'Item Group',
        cellClass: 'cell-center',
        width: 100,
        valueGetter: data => {
          return data.itemGroup || '';
        }
      },
      ...[
        this.categoryType === 'PROCUREMENT'
          ? {
              field: 'purchaseUnitSymbol',
              headerName: 'UOM',
              cellClass: 'cell-center',
              width: 100,
              valueGetter: data => {
                return data.purchaseUnitSymbol || '';
              }
            }
          : {
              field: 'salesUnitSymbol',
              headerName: 'UOM',
              cellClass: 'cell-center',
              width: 100,
              valueGetter: data => {
                return data.salesUnitSymbol || '';
              }
            }
      ],
      {
        field: 'productType',
        headerName: 'Product Type',
        cellClass: 'cell-center',
        width: 100,
        valueGetter: data => {
          return data.productType || '';
        }
      }
    ];
  }

  ngOnInit(): void {
    if (this.mode === ItemListMode.SELECTED) {
      this.displayedColumns = [
        ...this.displayedColumns,
        {
          field: 'deleteIcon',
          headerName: '',
          cellClass: 'cell-center cell-delete-icon',
          cellRenderer: 'deleteButtonRenderer',
          width: 50,
          valueGetter: data => {
            return {
              data
            };
          }
        }
      ];
      this.tableOptions = {
        type: ListType.TABLE,
        columnDefs: this.displayedColumns,
        rowHeight: 44,
        rowModelType: 'infinite',
        paginationSize: 100000,
        clearSelection: true,
        syncURL: false,
        isRowSelectable: true,
        enableFilterBtn: true,
        paginationControlType: 'dropdown',
        context: {
          componentParent: this
        }
      };
    } else {
      this.tableOptions = {
        type: ListType.TABLE,
        columnDefs: this.displayedColumns,
        rowHeight: 44,
        rowModelType: 'infinite',
        paginationSize: 100000,
        clearSelection: true,
        syncURL: false,
        isRowSelectable: true,
        enableFilterBtn: true,
        paginationControlType: 'dropdown'
      };
    }
  }

  handleRowSelect(eventData: any): void {
    if (this.mode === ItemListMode.LIST) {
      let selectedItems = this.query.selectedItems;

      if (this.allowSingleSelection && selectedItems.length >= 1) {
        this.item.removeItemsFromSelectedList(selectedItems);
        selectedItems = [];
      }

      if (eventData.action === 'select') {
        this.item.update('selectedItems', [eventData?.rowDetails, ...selectedItems]);
      } else {
        selectedItems = selectedItems.filter(item => item._id !== eventData?.rowDetails?._id);

        this.item.update('selectedItems', [...selectedItems]);
      }
    } else {
      this.item.update('unselectSelectedItems', [...eventData?.selectedRows]);
    }
  }

  clearSelection(items: any[]): void {
    this.zetList.clearSelection$.next(items as unknown as boolean);
  }
}
