import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  EventEmitter,
  ComponentFactoryResolver,
  Injector
} from '@angular/core';
import { AuthService } from '../auth';
import { EmailOtpLoginComponent } from './email-otp-login.component';
import { GoogleLoginComponent } from './google-login.component';
import { LoginType, IAuthApiData } from './login.model';
import { LoginService } from './login.service';

@Component({
  selector: 'zet-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @Output() public login: EventEmitter<IAuthApiData> = new EventEmitter();

  @Output() public error: EventEmitter<any> = new EventEmitter();

  @Output() public logout: EventEmitter<null> = new EventEmitter();

  private componentRef!: ComponentRef<any>;

  public backgroundImg!: string | null;

  @ViewChild('loginContainer', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;

  constructor(
    private loginService: LoginService,
    private auth: AuthService,
    private componentResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  ngOnInit() {
    const { background } = this.loginService.getUI();
    this.backgroundImg = background?.backgroundImg || null;

    this.loginService.logout$.subscribe(() => {
      this.logout.emit(this.auth.getUserFromStore());
      this.auth.clearAuthData();
    });

    this.renderComponent();
  }

  renderComponent() {
    const activeLoginProvider = this.loginService.getActivLoginProvider();
    const component = activeLoginProvider?.component || this.getDefaultComponent(activeLoginProvider.id);

    if (!component) {
      throw new Error(`Component not found for login type ${activeLoginProvider.id}`);
      return;
    }

    this.container?.clear();

    const injector = Injector.create({
      providers: [],
      parent: this.injector
    });

    const cf = this.componentResolver.resolveComponentFactory(component);

    this.componentRef = this.container?.createComponent(cf, 0, injector);

    const loginEventInstance = this.componentRef?.instance?.onLogin;
    const errorEventInstance = this.componentRef?.instance?.onError;

    loginEventInstance?.subscribe(() => {
      this.login.emit(this.auth.getUserFromStore());
    });

    errorEventInstance?.subscribe(err => {
      this.error.emit(err);
    });
  }

  getDefaultComponent(type: LoginType) {
    if (type === LoginType.EMAIL_OTP) {
      return EmailOtpLoginComponent;
    }
    return GoogleLoginComponent;
  }

  ngOnDestroy(): void {
    this.componentRef.destroy();
  }
}
