import { EventEmitter, Injectable } from '@angular/core';
import { IPage, IPageInfo, IPaginationInstance } from '../model/pagination';
import { PaginationQuery } from '../states/pagination.query';
import { PaginationStore } from '../states/pagination.store';

@Injectable()
export class PaginationService {
  public change: EventEmitter<null> = new EventEmitter<null>();

  constructor(private store: PaginationStore, private query: PaginationQuery) {}

  public register(instance: IPaginationInstance): boolean {
    let pageInstance = this.query.pageInstance;

    let isIdentical = this.shallowEqual(instance, pageInstance);
    if (isIdentical) {
      return false;
    }
    this.store.update({ pageInstance: instance });
    return true;
  }

  // TODO move to util
  private shallowEqual<T>(object1: T, object2: T): boolean {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let i = 0; i < keys1.length; i += 1) {
      if (object1[keys1[i]] !== object2[keys1[i]]) {
        return false;
      }
    }
    return true;
  }

  public getCurrentPage(): number {
    let pageInstance = this.query.pageInstance;
    return pageInstance.currentPage;
  }

  public setCurrentPage(page: number): void {
    let pageInstance = this.query.pageInstance;
    let maxPage = Math.ceil(pageInstance.totalItems / pageInstance.itemsPerPage);
    if (page <= maxPage && page >= 1) {
      pageInstance.currentPage = page;

      this.store.update({ pageInstance });

      this.change.emit();
    }
  }

  public setTotalItems(totalItems: number): void {
    let pageInstance = this.query.pageInstance;
    if (totalItems >= 0) {
      pageInstance.totalItems = totalItems;

      this.store.update({ pageInstance });

      this.change.emit();
    }
  }

  public setItemsPerPage(itemsPerPage: number): void {
    let pageInstance = this.query.pageInstance;
    pageInstance.itemsPerPage = itemsPerPage;

    this.store.update({ pageInstance });

    this.change.emit();
  }

  public getInstance(): IPaginationInstance {
    let pageInstance = this.query.pageInstance;
    return pageInstance;
  }

  public setPageInfo(pageInfo: IPageInfo): void {
    this.store.update({ pageInfo });
  }

  public setPages(pages: IPage[]): void {
    this.store.update({ pages });
  }
}
