/* eslint-disable max-classes-per-file */
import { Inject, Injectable, InjectionToken, Injector, isDevMode, Optional } from '@angular/core';

declare const window: Window;

export interface ServerConfig {
  baseUrl: string;
}

export const BrowserEnvToken = new InjectionToken<ServerConfig>('BrowserEnvToken');

export const BROWSER_ENV = new InjectionToken<Env>('Env');

type IBrowserEnvConfig = { [app in App]: { [env in Env]: any } };

export enum App {
  COMMON_MASTER = 'COMMON_MASTER',
  ZETAUTH = 'ZETAUTH'
}

export enum Env {
  development = 'development',
  staging = 'staging',
  production = 'production',
  local = 'local',
  uat = 'uat'
}

export abstract class BrowserEnvConfig implements ServerConfig {
  static configs: IBrowserEnvConfig = {
    COMMON_MASTER: {
      staging: {
        baseUrl: 'https://api.staging.zetwerk.com/common-masters/api/v1/'
      },

      uat: {
        baseUrl: 'https://api.uat.zetwerk.com/common-masters/api/v1/'
      },

      development: {
        baseUrl: 'https://api.dev.zetwerk.com/common-masters/api/v1/'
      },

      production: {
        baseUrl: 'https://api.zetwerk.com/common-masters/api/v1/'
      },

      local: {
        baseUrl: 'http://localhost:9001/api/v1/'
      }
    },
    ZETAUTH: {
      staging: {
        baseUrl: 'https://api.staging.zetwerk.com/user/v1/'
      },

      uat: {
        baseUrl: 'https://api.uat.zetwerk.com/user/v1/'
      },

      development: {
        baseUrl: 'https://api.dev.zetwerk.com/user/v1/'
      },

      production: {
        baseUrl: 'https://api.zetwerk.com/user/v1/'
      },

      local: {
        baseUrl: 'http://localhost:3001/v1/'
      }
    }
  };

  app: App | string;

  get config() {
    return this.resolveConfig()[this.app];
  }

  get baseUrl() {
    return this.config.baseUrl;
  }

  constructor(@Optional() @Inject(BROWSER_ENV) private env?: Env) {}

  private getByEnv(env: Env): { [app in App]: any } {
    return Object.keys(BrowserEnvConfig.configs)
      .map((app: App) => {
        const config = BrowserEnvConfig.configs[app][env];
        return { app, config };
      })
      .reduce((acc, appConfig) => {
        acc[appConfig.app] = appConfig.config;
        return acc;
      }, {}) as any;
  }

  private getEnv() {
    const url = window.location.href;
    const { hostname } = new URL(url);
    if (hostname.includes('staging.zetwerk.com')) {
      return Env.staging;
    }

    if (hostname.includes('uat.zetwerk.com')) {
      return Env.uat;
    }

    if (hostname.includes('dev.zetwerk.com')) {
      return Env.development;
    }

    if (hostname.includes('localhost')) {
      return Env.local;
    }

    return Env.production;
  }

  resolveConfig() {
    if (isDevMode()) {
      if (this.env) {
        return this.getByEnv(this.env);
      }
      return this.getByEnv(this.getEnv());
    }

    return this.getByEnv(Env.production);
  }
}

export class ZetAuthConfig extends BrowserEnvConfig {
  app = App.ZETAUTH;
}

export class CommonMasterConfig extends BrowserEnvConfig {
  app = App.COMMON_MASTER;
}

export const browserEnvProviders = [
  {
    provide: BrowserEnvToken,
    useClass: CommonMasterConfig,
    multi: true,
    deps: [[new Optional(), BROWSER_ENV]]
  },

  {
    provide: BrowserEnvToken,
    useClass: ZetAuthConfig,
    multi: true,
    deps: [[new Optional(), BROWSER_ENV]]
  }
];
