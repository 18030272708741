import { Component, Input } from '@angular/core';
import { Widget } from '../../../widget';
import { DimensionConfig, FinancialDimensionField, IFieldToDisable, IFinancialDimensionWidgetData } from '../financial-dimension/model';

@Component({
  selector: 'zet-financial-dimension-widget',
  templateUrl: './financial-dimension-widget.component.html',
  styleUrls: ['./financial-dimension-widget.component.scss']
})
export class FinancialDimensionWidgetComponent extends Widget<Partial<FinancialDimensionWidgetComponent>, IFinancialDimensionWidgetData> {
  @Input() config: DimensionConfig;

  @Input() companyId: string;

  @Input() financialDimension: FinancialDimensionField = {};

  @Input() fieldToDisable: IFieldToDisable;
}
