<nav class="pagination">
  <ul class="pagination--list">
    <li class="pagination--item pagination--previous">
      <a tabindex="0" (keyup.enter)="previous()" (click)="previous()" class="pagination--item-link" [class.page-is-disable]="isFirstPage()">
        Previous
      </a>
    </li>
    <li *ngFor="let page of pages$ | async; trackBy: trackByIndex" class="pagination--item">
      <a
        tabindex="0"
        (keyup.enter)="setCurrent(page.value)"
        (click)="setCurrent(page.value)"
        class="pagination--item-link"
        [class.page-is-active]="getCurrent() === page.value"
        [class.page-is-ellipsis]="getCurrent() === '...'"
      >
        {{ (page.label === '...') ? page.label : (page.label | number:'') }}
      </a>
    </li>
    <li class="pagination--item pagination--next">
      <a tabindex="0" (keyup.enter)="next()" (click)="next()" class="pagination--item-link" [class.page-is-disable]="isLastPage()">Next</a>
    </li>
  </ul>
</nav>
