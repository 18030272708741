import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgendaJobStatusComponent } from './components/agenda-job-status/agenda-job-status.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [CommonModule, TooltipModule],
  declarations: [AgendaJobStatusComponent],
  exports: [AgendaJobStatusComponent]
})
export class AgendaJobStatusModule {}
