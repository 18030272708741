import { Inject, Injectable } from '@angular/core';
import { RestClientService } from '../../item-master/services/rest-client.service';
import { map } from 'rxjs/operators';
import { App, BrowserEnvConfig, BrowserEnvToken, ServerConfig } from '../../browserEnvConfig';
import { UploadMasterQuery } from './upload-master.query';
import { UploadMasterState, UploadMasterStore } from './upload-master.store';
import { Observable } from 'rxjs';

type S = string | number | string[] | boolean | any;

type Assignable<Obj, Item> = {
  [Key in keyof Obj]: Obj[Key] extends Item ? Key : never;
}[keyof Obj];

type UploadMasterKeys = Assignable<UploadMasterState, S>; // "foo" | "baz"

@Injectable()
export class UploadMasterService {
  zetAuth: ServerConfig;

  commonMaster: ServerConfig;

  constructor(
    private http: RestClientService,
    @Inject(BrowserEnvToken) private apis: BrowserEnvConfig[],
    private store: UploadMasterStore,
    private query: UploadMasterQuery
  ) {
    this.zetAuth = apis.find(api => api.app === App.ZETAUTH);
    this.commonMaster = apis.find(api => api.app === App.COMMON_MASTER);
  }

  update(key: UploadMasterKeys, value: any): void {
    this.store.update({ [key as string]: value });
  }

  getAllCompanies() {
    return this.http.get(`${this.zetAuth.baseUrl}company`).pipe(
      map((res: any) => {
        return res?.data;
      })
    );
  }

  getJob(url: string, { taskId, name, sortBy }: { taskId?: string; name?: string; sortBy?: string }): Observable<any> {
    return this.http.get(url, {
      params: { taskId, name, sortBy }
    });
  }

  createJob(url) {
    const company = this.query.company;
    const file = this.query.uploadedFile;
    return this.http.post(`${url}`, { company, file });
  }
}
