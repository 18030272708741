import { BaseLoginProvider } from '../entities/base-login-provider';
import { SocialUser } from '../entities/social-user';
import { EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, skip, take } from 'rxjs/operators';

declare let google: any;

export interface GoogleInitOptions {
  /**
   * enables the One Tap mechanism, and makes auto-login possible
   */
  oneTapEnabled?: boolean;
  /**
   * list of permission scopes to grant in case we request an access token
   */
  scopes?: string | string[];
}

const defaultInitOptions: GoogleInitOptions = {
  oneTapEnabled: true,
  scopes: ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile']
};

export class GoogleLoginProvider extends BaseLoginProvider {
  public static readonly PROVIDER_ID: string = 'GOOGLE';

  public readonly changeUser = new EventEmitter<SocialUser | null>();

  private readonly _socialUser = new BehaviorSubject<SocialUser | null>(null);

  private readonly _accessToken = new BehaviorSubject<string | null>(null);

  private readonly _receivedAccessToken = new EventEmitter<string>();

  private _tokenClient: any;

  constructor(private clientId: string, private readonly initOptions?: GoogleInitOptions) {
    super();

    this.initOptions = { ...defaultInitOptions, ...this.initOptions };

    // emit changeUser events but skip initial value from behaviorSubject
    this._socialUser.pipe(skip(1)).subscribe(this.changeUser);

    // emit receivedAccessToken but skip initial value from behaviorSubject
    this._accessToken.pipe(skip(1)).subscribe(this._receivedAccessToken);
  }

  initialize(autoLogin?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.loadScript(GoogleLoginProvider.PROVIDER_ID, 'https://accounts.google.com/gsi/client', () => {
          if (this.initOptions.scopes) {
            const scope =
              this.initOptions.scopes instanceof Array ? this.initOptions.scopes.filter(s => s).join(' ') : this.initOptions.scopes;

            this._tokenClient = google.accounts.oauth2.initCodeClient({
              client_id: this.clientId,
              scope,
              ux_mode: 'popup',
              callback: tokenResponse => {
                if (tokenResponse.error) {
                  this._accessToken.error({
                    code: tokenResponse.error,
                    description: tokenResponse.error_description,
                    uri: tokenResponse.error_uri
                  });
                } else {
                  const socialUser = this.createSocialUserOAuth2Code(tokenResponse.code);
                  this._socialUser.next(socialUser);
                }
              }
            });
          }

          resolve();
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getLoginStatus(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      if (this._socialUser.value) {
        resolve(this._socialUser.value);
      } else {
        reject(`No user is currently logged in with ${GoogleLoginProvider.PROVIDER_ID}`);
      }
    });
  }

  refreshToken(): Promise<SocialUser | null> {
    return new Promise((resolve, reject) => {
      google.accounts.id.revoke(this._socialUser.value.id, response => {
        if (response.error) reject(response.error);
        else resolve(this._socialUser.value);
      });
    });
  }

  getAccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this._tokenClient) {
        if (this._socialUser.value) {
          reject('No token client was instantiated, you should specify some scopes.');
        } else {
          reject('You should be logged-in first.');
        }
      } else {
        this._tokenClient.requestAccessToken({
          hint: this._socialUser.value?.email
        });
        (this._receivedAccessToken as Subject<any>).pipe(take(1)).subscribe(resolve);
      }
    });
  }

  getOauth2Code(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this._tokenClient) {
        if (this._socialUser.value) {
          reject('No token client was instantiated, you should specify some scopes.');
        } else {
          reject('You should be logged-in first.');
        }
      } else {
        this._tokenClient.requestCode({
          hint: this._socialUser.value?.email
        });
      }
    });
  }

  revokeAccessToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._tokenClient) {
        reject('No token client was instantiated, you should specify some scopes.');
      } else if (!this._accessToken.value) {
        reject('No access token to revoke');
      } else {
        google.accounts.oauth2.revoke(this._accessToken.value, () => {
          this._accessToken.next(null);
          resolve();
        });
      }
    });
  }

  signIn(): Promise<SocialUser> {
    return Promise.reject(
      'You should not call this method directly for Google, use "<asl-google-signin-button>" wrapper ' +
        'or generate the button yourself with "google.accounts.id.renderButton()" ' +
        '(https://developers.google.com/identity/gsi/web/guides/display-button#javascript)'
    );
  }

  async signOut(): Promise<void> {
    google.accounts.id.disableAutoSelect();
    this._socialUser.next(null);
  }

  private createSocialUser(idToken: string) {
    const user = new SocialUser();
    user.idToken = idToken;
    const payload = this.decodeJwt(idToken);
    user.id = payload.sub;
    user.name = payload.name;
    user.email = payload.email;
    user.photoUrl = payload.picture;
    user.firstName = payload.given_name;
    user.lastName = payload.family_name;
    return user;
  }

  private createSocialUserOAuth2Code(code: string) {
    const user = new SocialUser();
    user.idToken = code;
    return user;
  }

  private decodeJwt(idToken: string): Record<string, string | undefined> {
    return JSON.parse(window.atob(idToken.split('.')[1]));
  }
}
