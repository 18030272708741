/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeAll, mergeMap, toArray, filter, takeUntil, tap, delay } from 'rxjs/operators';
import { FILE_UPLOAD_CONFIG } from '../file-upload-config.token';
import {
  UploadFile,
  UploadFileError,
  UploadFileTypeError,
  IFileUploadConfig,
  IS3SignedConfig,
  FileUploadStatus
} from '../model/file-upload';
import { FileUploadQuery } from '../states/file-upload.query';
import { FileUploadStore } from '../states/file-upload.store';
import { FileValidatorService } from './file-validator.service';

@Injectable()
export class FileUploadService {
  invalidFiles: UploadFile[] = [];

  uploadFailedFiles: UploadFile[] = [];

  constructor(
    private store: FileUploadStore,
    private query: FileUploadQuery,
    private validator: FileValidatorService,
    private http: HttpClient,
    @Inject(FILE_UPLOAD_CONFIG) private config: IFileUploadConfig
  ) {}

  /**
   *
   * @param files
   * @returns Observable<UploadFile[]>
   * @todo handle error
   */
  uploadFiles(files: UploadFile[]): Observable<unknown> {
    return of(files).pipe(
      mergeMap((filesAsArray: UploadFile[]) => {
        filesAsArray.forEach((file: UploadFile) => {
          let error: { type: UploadFileTypeError; message: string } = this.validator.isValidFile(file);
          if (error) {
            file.setError(new UploadFileError(error.type, error.message));
          }
        });
        let validFiles = filesAsArray.filter((file: UploadFile) => !file.error);
        return validFiles.length > 0 ? of(validFiles) : throwError('NO_FILES_TO_UPLOAD');
      }),
      mergeMap((filesAsArray: UploadFile[]) => {
        const fileNames = filesAsArray.map((file: UploadFile) => file.webkitRelativePath || file.name);
        return this.getSignedUploadUrls(filesAsArray, fileNames);
      }),
      mergeMap((filesAsArray: UploadFile[]) => {
        return from(
          filesAsArray.map(file => {
            let progressEventTimeline = [];
            progressEventTimeline.push(2000);
            return of(file).pipe(
              mergeMap((fileWithConfig: any) => {
                return this.uploadFileToS3(fileWithConfig).pipe(takeUntil(fileWithConfig.cancel));
              }),
              tap((event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                  file.setLoaded(event.loaded);
                }
              }),
              filter(event => event instanceof HttpResponse),
              map(() => file),
              filter((uploadFile: UploadFile) => !uploadFile.error && uploadFile.s3SignedConfig.signedUrl !== ''),
              mergeMap((uploadFile: UploadFile) => {
                /* FIXME need to move to array */
                return this.createFileObjectsForS3Upload(uploadFile);
              })
            );
          })
        ).pipe(mergeAll(5), toArray());
      }),
      mergeMap((filesAsArray: unknown[]) => {
        if (filesAsArray.length === 0) {
          let finalFileList = [];
          finalFileList.concat(this.invalidFiles);
          finalFileList.concat(this.uploadFailedFiles);
          return of(finalFileList);
        }
        return of(filesAsArray);
      }),
      catchError(err => {
        if (err === 'NO_FILES_TO_UPLOAD') {
          const invalidFiles = [...this.invalidFiles];
          return of(invalidFiles).pipe(delay(2000));
        }
        return throwError(err);
      })
    );
  }

  getSignedUploadUrls(files: UploadFile[], fileNames: string[]): Observable<UploadFile[]> {
    return this.http.post(`${this.config.signedUrlEndpoint}?expiresIn=300000`, { fileNames }).pipe(
      map((res: { data: IS3SignedConfig[] }) => res.data),
      map((s3SignedConfigs: IS3SignedConfig[]) => {
        const filesWithS3Config = files.map((file: UploadFile, index: number) => {
          file.setS3SignedConfig(s3SignedConfigs[index]);
          return file;
        });
        return filesWithS3Config;
      }),
      catchError(err => {
        this.invalidFiles = files.map((file: UploadFile) => {
          file.setError(new UploadFileError(UploadFileTypeError.FAILED_TO_GET_S3URL, err.message));
          return file;
        });
        return throwError('NO_FILE_TO_UPLOAD');
      })
    );
  }

  /**
   *
   * @param file
   * @returns
   * @todo handle upload fail error ( replicate by sending null)
   * @todo add ?
   */
  uploadFileToS3(file: UploadFile): Observable<HttpEvent<UploadFile>> {
    const headers = new HttpHeaders()
      .set('Content-Type', file.type)
      .set('x-amz-acl', 'public-read')
      .set('Content-Disposition', 'attachment');
    const options = {
      reportProgress: true,
      headers
    };
    const req = new HttpRequest('PUT', file.s3SignedConfig.signedUrl, file.file, options);
    return this.http.request(req).pipe(
      filter((event: HttpEvent<UploadFile>) => event.type === HttpEventType.UploadProgress || event.type === HttpEventType.Response),
      mergeMap(event => {
        return of(event);
      }),
      catchError(err => {
        file.setError(new UploadFileError(UploadFileTypeError.UPLOAD_FAIL, err.message));
        // make new copy of file befor adding to array
        this.uploadFailedFiles.push(file);
        return throwError(file);
      })
    );
  }

  /**
   *
   * @param s3SignedConfigs
   * @returns
   * @todo check for queryparams amd params
   */
  createFileObjectsForS3Upload(file: UploadFile): Observable<UploadFile> {
    const context = this.query.context || undefined;
    return this.http
      .post(this.config.registerFileEndpoint, {
        ...file.s3SignedConfig,
        ...context
      })
      .pipe(
        map((res: any) => {
          file.setStatus(FileUploadStatus.COMPLETED);
          file.setFileObj(res.data);
          return file;
        })
      );
  }

  retry(file: UploadFile): void {
    file.cancel.next(true);
    const uploadFiles = [file];
    this.uploadFiles(uploadFiles);
  }

  addFiles(uploadFiles: UploadFile[]): void {
    let files = this.query.files;
    if (files.length > 0) {
      files = files.concat(uploadFiles);
    } else {
      files = uploadFiles;
    }
    this.store.update({ files });
  }

  init(uploadFiles: UploadFile[]): void {
    this.store.update({ files: uploadFiles });
  }

  delete(file: UploadFile): void {
    let files = this.query.files;
    files = files.filter(uploadFile => uploadFile.id !== file.id);
    this.store.update({ files });
  }

  updateStore({ key, value }: { key: string; value: any }): void {
    this.store.update({ [key]: value });
  }
}
